import { apiSlice, TagType } from "@/features/api/apiSlice.ts"
import { type Task, type TaskDraft } from "@/features/tasks"

const TASKS_BASE_URL = "tasks/"

export const tasksApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTasks: builder.query<Task[], void>({
      query: () => ({
        url: TASKS_BASE_URL,
      }),
      transformResponse: (response: Task[]) => {
        return response.sort((a, b) => {
          const dateA = a.due_date ? new Date(a.due_date).getTime() : null
          const dateB = b.due_date ? new Date(b.due_date).getTime() : null

          if (dateA === null && dateB === null) return 0
          if (dateA === null) return 1
          if (dateB === null) return -1
          return dateA - dateB
        })
      },
      providesTags: (result) => {
        if (!result) {
          return [{ type: TagType.Tasks as const, id: "LIST" }]
        }

        const taskTags = result.map(({ id }) => ({
          type: TagType.Tasks as const,
          id: id ?? "UNKNOWN",
        }))

        return [...taskTags, { type: TagType.Tasks, id: "LIST" }]
      },
    }),
    getTaskById: builder.query<Task, string>({
      query: (id) => ({
        url: TASKS_BASE_URL + `${id}/`,
      }),
      providesTags: (_result, _error, id) => [{ type: TagType.Tasks, id }],
    }),
    deleteTask: builder.mutation<void, string>({
      query: (taskId) => ({
        url: TASKS_BASE_URL + `${taskId}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: TagType.Tasks, id: "LIST" }],
    }),
    postTask: builder.mutation<Task, Partial<Task>>({
      query: (task) => ({
        url: TASKS_BASE_URL,
        method: "POST",
        body: task,
      }),
      invalidatesTags: (result, _error, task) => {
        const invalidationTags: [{ type: TagType; id?: string }] = [
          { type: TagType.Tasks, id: "LIST" },
        ]

        if (task.hives && task.hives.length > 0) {
          result?.hives.forEach((hive) => {
            invalidationTags.push({ type: TagType.Hives, id: hive })
          })
        }
        if (task.apiaries && task.apiaries.length > 0) {
          result?.apiaries.forEach((apiary) => {
            invalidationTags.push({ type: TagType.Apiaries, id: apiary })
          })
        }
        if (task.queens && task.queens.length > 0) {
          invalidationTags.push({ type: TagType.Queens })
        }
        if (task.animals && task.animals.length > 0) {
          invalidationTags.push({ type: TagType.Animals, id: "LIST" })
          result?.animals.forEach((animal) => {
            invalidationTags.push({ type: TagType.Animals, id: animal })
          })
        }
        if (task.birds_groups && task.birds_groups.length > 0) {
          invalidationTags.push({ type: TagType.BirdsGroups, id: "LIST" })
          result?.birds_groups.forEach((birdGroup) => {
            invalidationTags.push({ type: TagType.BirdsGroups, id: birdGroup })
          })
        }

        return invalidationTags
      },
    }),
    updateTask: builder.mutation<Task, { id: Task["id"] & Partial<TaskDraft> }>(
      {
        query: (task) => ({
          url: TASKS_BASE_URL + `${task.id}/`,
          method: "PATCH",
          body: task,
        }),
        invalidatesTags: (_result, _error, { id }) => {
          return [{ type: TagType.Tasks, id }]
        },
      },
    ),
    toggleTaskStatus: builder.mutation<Task, Task>({
      query: (task: Task) => ({
        url: TASKS_BASE_URL + `${task.id}/`,
        method: "PATCH",
        body: { is_done: !task.is_done },
      }),
      invalidatesTags: [{ type: TagType.Tasks }],
      onQueryStarted: async (task, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          tasksApi.util.updateQueryData("getTasks", undefined, (draftTasks) => {
            const taskToUpdate = draftTasks.find((t) => t.id === task.id)
            if (taskToUpdate) {
              taskToUpdate.is_done = !task.is_done
            }
          }),
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetTasksQuery,
  useGetTaskByIdQuery,
  useLazyGetTaskByIdQuery,
  useDeleteTaskMutation,
  usePostTaskMutation,
  useUpdateTaskMutation,
  useToggleTaskStatusMutation,
} = tasksApi
