import * as TogglePrimitive from "@radix-ui/react-toggle"
import * as React from "react"
import styles from "./index.module.css"

const Toggle = React.forwardRef<
  React.ElementRef<typeof TogglePrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof TogglePrimitive.Root>
>(({ ...props }, ref) => (
  <TogglePrimitive.Root ref={ref} className={styles.Toggle} {...props} />
))

Toggle.displayName = TogglePrimitive.Root.displayName

export { Toggle }
