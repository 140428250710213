import React, { useEffect, useState, type FormEvent } from "react"
import { useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"
import styles from "./login-view.module.css"
import HidePasswordIcon from "@/assets/icons/account/hide-password-icon"
import ViewPasswordIcon from "@/assets/icons/account/view-password-icon"
import LogoBrown from "@/assets/icons/logos/logo-brown.tsx"
import TikTokIcon from "@/assets/icons/misc/tik-tok-icon.tsx"
import FacebookIcon from "@/assets/icons/settings/facebook-icon.tsx"
import InstagramIcon from "@/assets/icons/settings/instagram-icon.tsx"
import {
  useLazyGetProfileQuery,
  useLoginMutation,
} from "@/features/settings-account/api/account-api.ts"
import { useAppDispatch } from "@/redux/hooks.ts"
import { setToken } from "@/redux/slices/auth-slice.ts"
import {
  HOME_SCREEN_PATH,
  LOST_PASSWORD_PATH,
} from "@/utils/constants/routes.ts"

const LoginView: React.FC = () => {
  const { t } = useTranslation()
  const { i18n } = useTranslation()
  const navigate = useNavigate()
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [login, { isLoading }] = useLoginMutation()
  const [fetchUser] = useLazyGetProfileQuery()
  const [error, setError] = useState(false)
  const errorRef = React.createRef<HTMLDivElement>()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const token = localStorage.getItem("token")
    localStorage.removeItem("token")
    if (!token) return
    dispatch(setToken(token))
    navigate(HOME_SCREEN_PATH)
  }, [dispatch, navigate])

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    login({ username, password })
      .unwrap()
      .then(({ token }) => {
        dispatch(setToken(token))
        return fetchUser()
      })
      .then((response) => {
        console.log(response)
        if (response.data) {
          return i18n.changeLanguage(response.data.language)
        }
      })
      .then(() => {
        navigate(HOME_SCREEN_PATH)
      })
      .catch(() => setError(true))
  }

  useEffect(() => {
    if (error && errorRef.current) {
      errorRef.current.focus()
    }
  }, [error, errorRef])

  return (
    <div className={styles.loginWrapper}>
      <div className={styles.loginCard}>
        <LogoBrown width={150} height={150} />
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.formGroup}>
            <input
              id="email"
              type="email"
              name="email"
              data-testid={"email-input"}
              autoComplete="true"
              autoCapitalize={"none"}
              placeholder={"E-mail"}
              className={styles["input"]}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              disabled={isLoading}
            />
            <div className={styles.passwordWrapper}>
              <input
                id="password"
                data-testid="password-input"
                name={"password"}
                type={passwordVisible ? "text" : "password"}
                placeholder={t("password")}
                className={styles["input"]}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                disabled={isLoading}
              />
              {password && (
                <div
                  role={"button"}
                  data-testid={"show-password-button"}
                  className={styles.viewHidePasswordIcon}
                  onClick={() => setPasswordVisible(!passwordVisible)}
                >
                  {passwordVisible ? (
                    <HidePasswordIcon />
                  ) : (
                    <ViewPasswordIcon />
                  )}
                </div>
              )}
            </div>
            <Link to={LOST_PASSWORD_PATH} className={styles["lost-pass"]}>
              {t("lostPassword")}
            </Link>
          </div>
          <button
            type="submit"
            data-testid={"login-button"}
            className={styles["button"]}
            disabled={isLoading}
          >
            {isLoading ? t("logingIn") : t("login")}
          </button>
        </form>

        {error && (
          <div ref={errorRef} className={styles.error}>
            {t("wrongLoginCredentials")}
          </div>
        )}
      </div>

      <div className={styles.socialWrapper}>
        <p>{t("followUs")}:</p>
        <div className={styles.socialButtons}>
          <a href="https://www.facebook.com/FarmPlannerApp">
            <FacebookIcon size={30} />
          </a>
          <a href="https://www.tiktok.com/@farm.planner">
            <TikTokIcon size={30} />
          </a>
          <a href="https://www.instagram.com/farmplannerapp/">
            <InstagramIcon size={30} />
          </a>
        </div>
      </div>
    </div>
  )
}

export default LoginView
