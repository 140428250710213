import React from "react"
import { useTranslation } from "react-i18next"
import GenericItemsSubGroup from "@/components/grouping/generic-items-sub-group.tsx"
import {
  type ItemsArray,
  type ItemsTimeGroups,
} from "@/utils/helpers/time-grouping.ts"

interface GenericItemsGroupProps {
  items: ItemsTimeGroups
  reverse?: boolean
  ItemComponent: React.ComponentType<{ item: ItemsArray[number] }>
}

const GenericItemsGroup: React.FC<GenericItemsGroupProps> = ({
  items,
  reverse = false,
  ItemComponent,
}) => {
  const { t } = useTranslation()

  const renderTimeGroup = (groupItems: ItemsArray, title: string) => {
    if (groupItems.length === 0) return null

    return (
      <GenericItemsSubGroup
        key={title}
        items={groupItems}
        title={t(title)}
        reverse={reverse}
        ItemComponent={ItemComponent}
      />
    )
  }

  return (
    <div className="flex flex-col gap-4">
      {renderTimeGroup(items.todayItems, "today")}
      {renderTimeGroup(items.thisWeekItems, "thisWeek")}
      {renderTimeGroup(items.thisMonthItems, "thisMonth")}
      {renderTimeGroup(items.pastMonthItems, "lastMonth")}
      {renderTimeGroup(items.thisYearItems, "thisYear")}
      {renderTimeGroup(items.pastYearItems, "lastYear")}
      {renderTimeGroup(items.pastYearsItems, "pastYears")}
    </div>
  )
}

export default GenericItemsGroup
