import { skipToken } from "@reduxjs/toolkit/query"
import { useTranslation } from "react-i18next"
import { useParams, useNavigate } from "react-router-dom"
import EditIcon from "@/assets/icons/misc/edit-icon.tsx"
import genericApiaryImg from "@/assets/images/generic_apiary.png"
import GenericPicture from "@/components/generic-picture/generic-picture.tsx"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import MobileTopBar from "@/components/top-bars/mobile-top-bar/mobile-top-bar.tsx"
import { useGetApiaryByIdQuery } from "@/features/bees/api/apiaries-api.ts"
import { ApiaryDetailsSkeleton } from "@/features/bees/components/apiary-details/apiary-details-skeleton.tsx"
import { ApiaryDetails } from "@/features/bees/components/apiary-details/apiary-details.tsx"
import ApiaryHivesWidget from "@/features/bees/components/hives-widget/apiary-hives-widget.tsx"
import { ApiaryEventsWidget } from "@/features/events/apiary-events/components/apiary-events-widget/apiary-events-widget.tsx"
import TasksWidget from "@/features/home/components/tasks-widget/tasks-widget.tsx"
import NotesWidget from "@/features/notes/components/notes-widget/notes-widget.tsx"
import {
  APIARIES_SCREEN_PATH,
  HOME_SCREEN_PATH,
} from "@/utils/constants/routes.ts"

const ApiaryView = () => {
  const { apiaryId } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data: apiary, isSuccess } = useGetApiaryByIdQuery(
    apiaryId ?? skipToken,
  )
  const title = apiary?.name || t("Loading")
  const editApiaryNavigationString =
    apiary && `${APIARIES_SCREEN_PATH}/${apiary.id}/edit/`

  const handleBackClick = () => {
    navigate(HOME_SCREEN_PATH)
  }

  return (
    <>
      <MobileTopBar
        title={title}
        navigateTo={editApiaryNavigationString}
        icon={<EditIcon />}
        onBackClick={handleBackClick}
      />
      <PageContentWrapperLarge className={"flex flex-col gap-4 p-4"}>
        {apiary ? (
          <>
            <GenericPicture
              src={apiary?.picture || genericApiaryImg}
              alt={t("apiaryImage")}
              borderRadius={12}
              height={200}
            />
            {apiary ? (
              <ApiaryDetails apiary={apiary} />
            ) : (
              <ApiaryDetailsSkeleton />
            )}
            {isSuccess && apiary && <ApiaryHivesWidget apiary={apiary} />}
            <TasksWidget tasks={apiary?.tasks} apiaryId={apiary?.id} />
            <NotesWidget notes={apiary?.notes} apiaryId={apiary?.id} />
            <ApiaryEventsWidget apiaryId={apiary?.id || ""} />
          </>
        ) : (
          <LoadingAnimation />
        )}
      </PageContentWrapperLarge>
    </>
  )
}

export default ApiaryView
