import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

export const AddNoteIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      width="62"
      height="30"
      viewBox="0 0 62 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.75"
        y="0.75"
        width="60.5"
        height="28.5"
        rx="14.25"
        stroke="#B5642D"
        stroke-width="1.5"
      />
      <path
        d="M13 14.6667H26.3333M19.6667 8V21.3333"
        stroke="#B5642D"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M40.5 5V7.7"
        stroke="#B5642D"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M47.7002 5V7.7"
        stroke="#B5642D"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M52.2 10.8501V18.5001C52.2 21.2001 50.85 23.0001 47.7 23.0001H40.5C37.35 23.0001 36 21.2001 36 18.5001V10.8501C36 8.1501 37.35 6.3501 40.5 6.3501H47.7C50.85 6.3501 52.2 8.1501 52.2 10.8501Z"
        stroke="#B5642D"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        opacity="0.4"
        d="M40.5 13.1001H47.7"
        stroke="#B5642D"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        opacity="0.4"
        d="M40.5 17.6001H44.1"
        stroke="#B5642D"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
