import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

export const AddHiveIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      width="62"
      height="30"
      viewBox="0 0 62 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.75"
        y="0.75"
        width="60.5"
        height="28.5"
        rx="14.25"
        stroke="#B5642D"
        stroke-width="1.5"
      />
      <path
        d="M13 14.6667H26.3333M19.6667 8V21.3333"
        stroke="#B5642D"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <g clip-path="url(#clip0_3225_3402)">
        <g clip-path="url(#clip1_3225_3402)">
          <path
            d="M52.25 16.875C52.25 15.705 51.4775 14.7225 50.4125 14.385C50.6225 14.01 50.75 13.5825 50.75 13.125C50.75 11.955 49.9775 10.9725 48.9125 10.635C49.1225 10.26 49.25 9.8325 49.25 9.375C49.25 7.9275 48.0725 6.75 46.625 6.75H41.375C39.9275 6.75 38.75 7.9275 38.75 9.375C38.75 9.8325 38.8775 10.26 39.0875 10.635C38.03 10.9725 37.25 11.955 37.25 13.125C37.25 13.5825 37.3775 14.01 37.5875 14.385C36.53 14.7225 35.75 15.705 35.75 16.875C35.75 17.82 36.2525 18.63 37.0025 19.095C36.6875 19.5225 36.5 20.055 36.5 20.625C36.5 22.0725 37.6775 23.25 39.125 23.25H48.875C50.3225 23.25 51.5 22.0725 51.5 20.625C51.5 20.055 51.3125 19.5225 50.9975 19.095C51.7475 18.63 52.25 17.82 52.25 16.875ZM41.375 8.25H46.625C47.2475 8.25 47.75 8.7525 47.75 9.375C47.75 9.9975 47.2475 10.5 46.625 10.5H41.375C40.7525 10.5 40.25 9.9975 40.25 9.375C40.25 8.7525 40.7525 8.25 41.375 8.25ZM39.875 12H48.125C48.7475 12 49.25 12.5025 49.25 13.125C49.25 13.7475 48.7475 14.25 48.125 14.25H39.875C39.2525 14.25 38.75 13.7475 38.75 13.125C38.75 12.5025 39.2525 12 39.875 12ZM38 20.625C38 20.0025 38.5025 19.5 39.125 19.5H41.75V21.75H39.125C38.5025 21.75 38 21.2475 38 20.625ZM48.875 21.75H46.25V19.5H48.875C49.4975 19.5 50 20.0025 50 20.625C50 21.2475 49.4975 21.75 48.875 21.75ZM49.625 18H46.115C45.8 17.13 44.975 16.5 44 16.5C43.025 16.5 42.2 17.13 41.885 18H38.375C37.7525 18 37.25 17.4975 37.25 16.875C37.25 16.2525 37.7525 15.75 38.375 15.75H49.625C50.2475 15.75 50.75 16.2525 50.75 16.875C50.75 17.4975 50.2475 18 49.625 18Z"
            fill="#B5642D"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_3225_3402">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(35 6)"
          />
        </clipPath>
        <clipPath id="clip1_3225_3402">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(35 6)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
