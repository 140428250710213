export const RO_TRANSLATIONS: Record<string, any> = {
  translation: {
    hello: "Salut!",
    featureNotYetImplemented:
      "Această funcționalitate nu este încă implementată.",
    yes: "Da",
    no: "Nu",
    yesterday: "Ieri",
    today: "Astăzi",
    tomorrow: "Mâine",
    thisWeek: "Săptămâna aceasta",
    thisMonth: "Luna aceasta",
    lastMonth: "Luna trecută",
    thisYear: "Anul acesta",
    lastYear: "Anul trecut",
    pastYears: "Anii trecuți",
    homeTab: "Acasă",
    tasksTab: "Tasks",
    addBottomMenu: "Adaugă",
    farmTab: "Ferma mea",
    notifications: "Notificări",
    notification: "Notificare",
    menu: "Meniu",
    accountTab: "Contul meu",
    oneSecond: "Un moment...",
    weather: "Vremea",
    notes: "Notițe",
    addNote: "Adaugă o notiță",
    newNote: "Notiță nouă",
    myNote: "Notița mea",
    placeholderTitle: "Titlu",
    placeholderText: "Textul tău",
    deleteNote: "Șterge notiță",
    searchNote: "Caută notiță",
    noNotesFound: "Nu au fost gǎsite notițe",
    shareNote: "Distribuie notiță",
    formatNote: "Formatare",
    notesBackConfirmation:
      "Ați făcut modificări. Sigur doriți să părăsiți fără a salva?",
    sureWantToLeave: "Sigur doriți să părăsiți fără a salva?",
    youMadeChanges: "Ați făcut modificări.",
    unsavedChanges: "Modificări nesalvate!",
    continueWhereYouLeftOff: "Continuă de unde ai rămas",
    youHaveUnsavedChanges:
      "Aveți modificări nesalvate din sesiunea anterioară. Doriti sa continuati cu aceste modificari sau sa reununtati la ele?",
    youWillLoseChanges:
      "Veți pierde modificările nesalvate. Doriți să continuați?",
    backConfirmation: "Eşti sigur că vrei să te întorci la Notițe?",
    noteWillBeDeleted: "Notița în curs va fi ştearsă dacă da.",
    continue: "Continuă",
    leave: "Părăsește",
    discard: "Renunță",
    delete: "Șterge",
    stay: "Rămâi",
    save: "Salvează",
    goToNotes: "Mergi la notițe",
    seeAllNotes: "Vezi toate notițele",
    deleteNoteConfirmation: "Eşti sigur că vrei să ştergi Notița?",
    deletingNote: "Se şterge notița ...",
    noteDeleted: "Notița a fost ştearsă!",
    errorDeletingNote: "A apărut o eroare la ştergerea notiței!",
    updatingNote: "Se actualizează notița ...",
    noteUpdated: "Notița a fost actualizată!",
    errorUpdatingNote: "A apărut o eroare la actualizarea notiței!",
    postingNote: "Se creează notița ...",
    notePosted: "Notița a fost creată!",
    notePostError: "A apărut o eroare la crearea notiței!",
    noNoteAssociated: "Nicio notiță asociată",
    oneNoteAssociated: "O notiță asociată",
    notesAssociated: "notițe asociate",
    noteContentIsMandatory: "Continutul notitei este obligatoriu !",
    seeOtherNotesAssociated: "Vezi celelalte notițe asociate",
    hideOtherNotesAssociated: "Ascunde celelalte notițe asociate",
    draft: "schiță",
    unsavedChangesIndicator: "modificări nesalvate",
    notEnoughData: "Nu există suficiente date pentru a afișa graficul",
    notesWillBeSetAsDraft:
      "Notițele vor fi setate ca şi schiță.Puteți revenii ulterior pentru a salva modificările.",
    noteWasLoadedWithPreviousData:
      "Notița a fost incarcată cu datele anterioare. Doriti sa pastrati datele si sa continuati?",

    //Tasks

    taskWasLoadedWithPreviousData:
      "Sarcina a fost incarcată cu datele anterioare. Doriti sa pastrati datele si sa continuati?",
    tasks: "Sarcini",
    dueTasks: "Sarcini Restante",
    currentTasks: "Sarcini Curente",
    futureTasks: "Sarcini Viitoare",
    searchTask: "Caută Sarcină",
    noTasksFound: "Nu au fost gǎsite sarcini",
    newTask: "Sarcină Nouă",
    myTask: "Sarcina Mea",
    title: "Titlu",
    description: "Descriere",
    deleteTask: "Şterge Sarcină",
    disinfestation: "Deparazitare",
    treatments: "Tratamente",
    status: "Status",
    category: "Categorie",
    chooseCategory: "Alege Categoria",
    ALL: "Toate Sarcinile",
    DONE: "Sarcini Finalizate",
    TODO: "Sarcini Curente",
    backConfirmationTasks: "Eşti sigur că vrei să te întorci la Sarcini?",
    backConfirmationSettings: "Eşti sigur că vrei să te întorci la Setări?",
    taskWillBeDeleted: "Sarcina în curs va fi ştearsă.",
    modificationsWillBeLost: "Modificările vor fi pierdute.",
    onTime: "La timp",
    before30: "30 min înainte",
    beforeHour: "1 oră înainte",
    beforeDay: "1 zi înainte",
    beforeWeek: "1 săptămână înainte",
    daily: "Zilnic",
    weekly: "Săptămânal",
    monthly: "Lunar",
    yearly: "Anual",
    Date: "Data",
    Hour: "Ora",
    Reminder: "Reminder",
    Recurrence: "Recurență",
    AssociateNote: "Asociază Notiță",
    AssociateAnimal: "Asociază Animal",
    AssociateTask: "Asociază Sarcină",
    dashboardCurrentTasks: "Curente",
    dashboardFutureTasks: "Viitoare",
    dashboardFinalizedTasks: "Finalizate",
    dashboardOverdueTasks: "Restante",
    seeAllTasks: "Vezi toate sarcinile",
    deleteTaskConfirmation: "Eşti sigur că vrei să ştergi Sarcina?",
    allCategories: "Toate categoriile",
    noNewTasks: "Nu ai sarcini noi",
    updatingTask: "Se actualizează sarcina ...",
    taskUpdated: "Sarcina a fost actualizată!",
    errorUpdatingTask: "A apărut o eroare la actualizarea sarcinii!",
    creatingTask: "Se creează sarcina ...",
    taskCreated: "Sarcina a fost creată!",
    errorCreatingTask: "A apărut o eroare la crearea sarcinii!",
    deletingTask: "Se şterge sarcina ...",
    taskDeleted: "Sarcina a fost ştearsă!",
    errorDeletingTask: "A apărut o eroare la ştergerea sarcinii!",
    oneTaskAssociated: "O sarcină asociată",
    tasksAssociated: "sarcini asociate",
    taskNotFound: "Sarcina nu a fost găsită!",
    titleRequired: "Titlul este obligatoriu pentru a crea o sarcină",
    title5Characters: "Titlul trebuie să aibă cel puțin 5 caractere",
    title100Characters: "Titlul trebuie să aibă cel mult 100 de caractere",
    addCategory: "Adaugă Categorie",
    editCategory: "Editează Categoria",
    selectTaskCategory: "Selectează Categoria Sarcinii",
    taskWillBeSetAsDraft:
      "Sarcina va fi setată ca şi schiță. Puteți revenii ulterior pentru a salva modificările.",
    taskSetAsDraft: "Sarcina a fost setată ca şi schiță.",
    addTask: "Adaugă Sarcină",
    animalWasLoadedWithPreviousData:
      "Animalul a fost incarcat cu datele anterioare.Doriti sa pastrati datele si sa continuati?",
    animalWillBeSetAsDraft:
      "Animalul va fi setat ca şi schiță.Puteți revenii ulterior pentru a salva modificările.",
    ain: "Nr. Crotal",
    myFarm: "Ferma mea",
    searchAnimal: "Caută animal: Nume, nr crotal, status",
    outOfExploitation: "Ieşite din exploatație",
    removeAnimalFromExploitation: "Marchează ieşirea din fermă",
    searchBreed: "Caută Rasă",
    newAnimal: "Adaugă Animal",
    addCow: "Adaugă Bovină",
    myAnimal: "Animalul meu",
    addGoat: "Adaugă Capră",
    addSheep: "Adaugă Oaie",
    addPork: "Adaugă Porc",
    addBird: "Adaugă Pasăre",
    addMammal: "Adaugă Mamifer",
    addBee: "Adaugă Stup",
    statusCows: "Status Bovine",
    statusGoats: "Status Capre",
    statusSheep: "Status Oi",
    statusPork: "Status Porci",
    statusBirds: "Status Păsări",
    statusBee: "Status Stupine",
    statusAnimal: "Status Animale",
    backConfirmationAnimals: "Eşti sigur că vrei să te întorci la Ferma mea?",
    animalWillBeDeleted: "Animalul în curs va fi şters dacă da.",
    name: "Nume",
    surname: "Prenume",
    taskTitleError: "Titlul este obligatoriu pentru a crea o sarcină",
    crotal: "Crotal",
    Male: "Mascul",
    Female: "Femelă",
    allGenders: "Ambele Sexe",
    sex: "Sex",
    registryEntryWay: "Modalitate intrare în Registru",
    Birth: "Fătare",
    Beef: "Carne",
    Meat: "Carne",
    Purchase: "Achiziție",
    Production: "Producție",
    Dairy: "Lapte",
    Sale: "Vânzare",
    Sacrifice: "Sacrificare",
    Transfer: "Transfer",
    Loss: "Pierdere",
    Death: "Moarte",
    details: "Detalii",
    categories: "Categorii",
    deleteAnimal: "Şterge animal",
    birthDate: "Data naşterii",
    entryDate: "Data intrare în exploatare",
    ALL_ANIMALS: "Toate categoriile",
    ALL_GENDERS: "Toate sexele",
    Cows: "Bovine",
    Cow: "Vacă",
    singleSheep: "Ovinǎ",
    Sheep: "Ovine",
    FEMALE_SHEEP: "Oaie",
    YOUNG_GOAT: "Ied",
    MALE_GOAT: "Țap",
    FEMALE_GOAT: "Capră",
    RAM: "Berbec",
    singlePork: "Porcinǎ",
    Pork: "Porcine",
    MALE_PIG: "Porc",
    FEMALE_PIG: "Scroafă",
    PIGLET: "Porcușor",
    singleGoat: "Caprinǎ",
    Goat: "Caprine",
    breed: "Rasa",
    Lamb: "Miel",
    Chicken: "Găină",
    Cock: "Cocoş",
    Bee: "Albină",
    exploitEntryDate: "Dată intrare în exploatare",
    calves_count: "Fătări",
    gestation_count: "Confirmări de gestație",
    medical_treatments_count: "Tratamente",
    insemination_count: "Montă / Înseminări",
    lactation_count: "Lactații",
    nameAnimalError: "* Numele animalului este obligatoriu",
    categoryAnimalError: "* Categoria animalului este obligatorie",
    breedAnimalError: "* Rasa animalului este obligatorie",
    crotalAnimalError: "* Nr crotal animalului este obligatoriu",
    sexAnimalError: "* Sexul animalului este obligatoriu",
    birthDateAnimalError: "* Data nașterii animalului este obligatorie",
    entryDateAnimalError: "* Data intrării în exploatare este obligatorie",
    registryEntryWayAnimalError:
      "* Modalitatea de intrare în registru este obligatorie",
    productionAnimalError: "* Tipul de producție este obligatoriu",
    detailsAnimalError: "* Detaliile animalului sunt obligatorii",
    exploitEntryDateAnimalError:
      "* Data intrării în exploatare este obligatorie",
    fieldError: "* Acest câmp este obligatoriu",
    age: "Vârsta",
    years: "ani",
    year: "an",
    year_uppercase: "An",
    months: "luni",
    month: "lunǎ",
    and: "şi",
    lessThanAMonth: "mai puțin de o lunǎ",
    allAges: "Toate vârstele",
    age02cow: "Vițel",
    age23cow: "Junică",
    age3plusCow: "Matură",
    age510: "5 - 10 ani",
    species: "Specii",
    allSpecies: "Toate Speciile",
    Calf: "Vițel",
    Veal: "Junică",
    mature: "Matură",
    goats: "Capre",
    ALL_SPECIES: "Toate Speciile",
    heads: "Capete",
    addAnimal: "Adaugă animal",
    animalSummary: "Sumar Date Animal",
    animalMother: "Mama Animalului",
    animalFather: "Tatăl Animalului",
    seeFarm: "Vezi ferma mea",
    deleteAnimalConfirmation: "Eşti sigur că vrei să ştergi animalul?",
    deletingAnimal: "Se şterge animalul ...",
    animalDeleted: "Animalul a fost şters!",
    errorDeletingAnimal: "A apărut o eroare la ştergerea animalului!",
    updatingAnimal: "Se actualizează animalul ...",
    animalUpdated: "Animalul a fost actualizat!",
    animalUpdateError: "A apărut o eroare la actualizarea animalului!",
    creatingAnimal: "Se creează animalul ...",
    animalCreated: "Animalul a fost creat!",
    animalCreateError: "A apărut o eroare la crearea animalului!",
    allAnimals: "Toate animalele",
    animals: "Animale",
    noAnimalAssociated: "Niciun animal asociat",
    oneAnimalAssociated: "Un animal asociat",
    oneAnimalSelected: "Un animal selectat",
    animalsAssociated: "animale asociate",
    seeOtherAnimalsAssociated: "Vezi celelalte animale asociate",
    hideOtherAnimalsAssociated: "Ascunde celelalte animale asociate",
    animalsSelected: "animale selectate",
    ainRequired: "Nr. Crotal este obligatoriu pentru a crea un animal",
    genderRequired: "Sexul este obligatoriu pentru a crea un animal",
    birthDateRequired: "Data nașterii este obligatorie pentru a crea un animal",
    entryDateRequired:
      "Data intrării în exploatare este obligatorie pentru a crea un animal",
    entryWayIsRequired: "Modalitatea de intrare în registru este obligatorie",
    intendedPurposeIsRequired: "Producția este obligatorie",
    speciesRequired: "Specia este obligatorie pentru a crea un animal",
    breedIsRequired: "Rasa este obligatorie pentru a crea un animal",
    entryDateMustBeLaterThanBirthDate:
      "Data intrării trebuie să fie ulterioară datei nașterii",
    birthDateCannotBeInTheFuture: "Data nașterii nu poate fi în viitor",
    entryDateCannotBeInTheFuture: "Data intrării nu poate fi în viitor",
    nameFarmRequired: "Numele fermei este obligatoriu!",
    nameMaxLength: "Numele trebuie să aibă cel mult 100 de caractere!",
    selectAnimalMother: "Selectează mama animalului",
    selectAnimalFather: "Selectează tatăl animalului",
    ALL_STATUSES: "Toate Statusurile",
    HEAT: "Călduri",
    GESTATING: "Gestante",
    LACTATING: "Lactație",
    ILL: "De investigat",
    HEALTHY: "Sănătoase",
    DRY: "Repaus",
    gestationPeriodCalendar: "Calendar Gestație",
    jurnal: "Jurnal",
    vocalAssistant: "Asistent Vocal",
    contactTheSpecialist: "Contactează Specialistul",
    contactSpecialist: "Contactează Specialistul",
    voiceAssistant: "Asistent Vocal",
    goodMorning: "Bună dimineața",
    goodAfternoon: "Bună ziua",
    goodEvening: "Bună seara",
    Monday: "Luni",
    Tuesday: "Marți",
    Wednesday: "Miercuri",
    Thursday: "Joi",
    Friday: "Vineri",
    Saturday: "Sâmbătă",
    Sunday: "Duminică",
    january: "Ianuarie",
    february: "Februarie",
    march: "Martie",
    april: "Aprilie",
    may: "Mai",
    june: "Iunie",
    july: "Iulie",
    august: "August",
    september: "Septembrie",
    october: "Octombrie",
    november: "Noiembrie",
    december: "Decembrie",
    dashboard: "Dashboard",
    allNotifications: "Toate notificările",
    ALL_NOTIFICATIONS: "Toate notificările",
    ALERTS: "Alerte",
    REMINDERS: "Remindere",
    INFO: "Informări",
    MANDATORY: "Obligatorii",
    allRead: "Toate",
    read: "Citite",
    unread: "Necitite",
    all: "Toate",
    seeAllNotifications: "Vezi toate notificările",
    noNewNotifications: "Nu ai notificări noi",
    notificationWasRead: "Notificarea a fost citită !",
    notificationsWereRead: "Notificările au fost citite !",
    updateNotificationError: "A apărut o eroare la actualizarea notificării !",
    updateNotificationsError:
      "A apărut o eroare la actualizarea notificărilor !",
    notificationError: "A apărut o eroare la notificare !",
    updatingNotification: "Se actualizează notificarea ...",
    updatingNotifications: "Se actualizează notificările ...",
    followLink: "Urmează link",
    youSureYouWantToReadThemAll:
      "Sunteți sigur că doriți să marcați toate notificările ca fiind citite?",
    markAllAsRead: "Marcați toate ca citite",
    cancel: "Anulează",
    settings: "Setări",
    farmProfile: "Profil Fermă",
    hiveProfile: "Profil Stupină",
    companyInfo: "Datele Firmei",
    farmName: "Numele Fermei",
    farmNameRequired: "Numele Fermei este obligatoriu !",
    address: "Adresa",
    location: "Locație",
    selectLocation: "Locație (e.g., Clădirea A, Zona 4, Padoc 2)",
    setFarmImage: "Setează o nouă fotografie a fermei",
    takePhoto: "Fă o fotografie",
    choosePhoto: "Alege o fotografie",
    changePhoto: "Schimbă fotografia",
    deletePhoto: "Şterge fotografia",
    farmInfo: "Datele Firmei",
    addFarmName: "Numele Firmei",
    addCui: "CUI",
    accountSettings: "Setări Cont",
    myProfile: "Profilul Meu",
    resetPassword: "Resetează Parola",
    notificationPreferences: "Preferințe Notificări",
    email: "Adresa de E-mail",
    pushNotifications: "Notificări Push",
    editPhone: "Editează Telefonul",
    setUserImage: "Setează o nouă fotografie a profilului",
    inputCurrentPassword: "Introdu Parola Actuală",
    inputNewPassword: "Introdu Noua Parolă",
    confirmNewPassword: "Confirmă Noua Parolă",
    information: "Informații",
    tutorials: "Tutoriale",
    faq: "Întrebări frecvente",
    confidentiality: "Politica de Confidențialitate",
    termsAndServices: "Termene şi Servicii",
    appVersion: "Versiunea Aplicației",
    whatIsFarmApp: "Ce este FARM APP?",
    lastUpdate: "Ultima Actualizare",
    yourOpinion: "Părerea ta",
    recommend: "Recomandă",
    likeUs: "Like us on Social Media",
    contactUs: "Contactează-ne",
    followUs: "Urmărește-ne",
    recommendUsing: "Recomandă aplicația folosind:",
    subject: "Subiect",
    message: "Mesaj",
    signOut: "Sign out",
    signOutConfirmation: "Eşti sigur că vrei să părăseşti aplicația?",
    deletePhotoConfirmation: "Eşti sigur că vrei să ştergi fotografia?",
    linkWillBeHere: "Link to our app will be here",
    farmProfileUpdated: "Profilul fermei a fost actualizat!",
    userProfileUpdated: "Profilul a fost actualizat!",
    errorOccurred: "A apărut o eroare!",
    processing: "Se procesează ...",
    contact: "Contact",
    lostPassword: "Am uitat parola",
    password: "Parola",
    lostYourPassword: "Ți-ai uitat parola?",
    dontWorryHappensToAllOfUs: "Nu te îngrijora, ni se întâmplă tuturor!",
    enterEmailResetPassword:
      "Introdu adresa de e-mail mai jos, și îți vom trimite un link de resetare a parolei.",
    wrongLoginCredentials: "Adresa de e-mail sau parola greșită!",
    login: "Autentificare",
    logingIn: "Se autentifică ...",
    back: "Înapoi",
    welcome: "Bine ai venit!",
    nameUserRequired: "Numele este obligatoriu !",
    lastNameRequired: "Prenumele este obligatoriu !",
    lastNameMaxLength: "Prenumele trebuie să aibă cel mult 100 de caractere",
    emailRequired: "Adresa de e-mail este obligatorie !",
    phoneNumberRequired: "Numărul de telefon este obligatoriu !",
    send: "Trimite",
    urlCopiedSuccess: "URL-ul a fost copiat cu succes!",

    // Gestation Period Calendar
    stepByStepGestationEvolution: "Evolutia sarcinii etapa cu etapa",
    computeProbableBirthDate: "Calculeaza data probabila a fatarii",
    testNow: "Testează acum",
    confirm: "Confirmă",
    underDevelopment: "Această funcționalitate este încă în dezvoltare",
    notAvailableInPackage: "Această funcționalitate nu este disponibilă",
    gestationalCalculator: "Calculator Gestațional",
    createNewCalendar: "Creează un nou calendar",
    generateCalendar: "Generează un calendar",
    saveCalendar: "Salvează calendarul",
    calendarSavedSuccessfully: "Calendarul a fost salvat cu succes!",
    animalMustBeFemale:
      "Pentru a crea un calendar gestațional animalul trebuie să fie femelă",
    animalMustBeCow: "Calendarul gestațional este disponibil doar pentru vaci",
    loadingCalendarPreview: "Se încarcă previzualizarea calendarului ...",
    previewLoadedSuccessfully: "Previzualizarea a fost încărcată cu succes!",
    errorLoadingPreview: "A apărut o eroare la încărcarea previzualizării!",
    errorFetchingAnimal: "A apărut o eroare la preluarea animalului!",
    searchCalendar: "Caută calendar",
    selectAnimal: "Selecteaza Animalul",
    gestationalCalendar: "Calendar Gestațional",
    deleteCalendar: "Șterge Calendarul",
    deletingCalendar: "Se șterge calendarul ...",
    calendarDeletedSuccessfully: "Calendarul a fost șters cu succes!",
    calendarDeleteError: "A apărut o eroare la ștergerea calendarului!",
    calendarWillBeDeleted: "Calendarul în curs va fi șters.",
    deleteCalendarConfirmation: "Ești sigur că vrei să ștergi calendarul?",
    deleteAssocAnimalEvents:
      "Doreşti să ştergi şi evenimentele asociate calendarului?",
    toCalving: "până la fătare",
    untilCalvingIntervalEnd: "până la finalul intervalului",
    days: "zile",
    toNextTask: "pâna la următoarea sarcină",
    hours: "ore",
    minutes: "minute",
    nextActivity: "Următoarea Activitate",
    gestationalCalendarText:
      "Calculează data fătării și generează sarcini utile pentru perioada de gestație.",
    calendarPreviewLoadedSuccessfully:
      "Previzualizarea calendarului a fost încărcată cu succes!",

    // Finance Widget
    financialJournal: "Jurnal Financiar",
    searchTransaction: "Caută Tranzacție",
    addTransaction: "Adaugă Tranzacție",
    transaction: "Tranzacție",
    transactions: "Tranzacții",
    EXPENSES: "Cheltuieli",
    Outflow: "Cheltuieli",
    INCOMES: "Venituri",
    Inflow: "Venituri",
    selectTransactionCategory: "Selectează categoria tranzacției",
    EU_FOUNDS: "Fonduri Europene",
    PROFIT: "Profit",
    SELLING_ANIMALS: "Vânzare Animale",
    DONATIONS: "Donații",
    SELLING_GOODS: "Vânzare Produse",
    EQUIPMENT_ACQUISITION: "Achiziție Echipamente",
    MAINTENANCE: "Întreținere",
    ANIMALS_FOOD: "Hrană Animale",
    TREATMENTS: "Tratamente",
    TRANSPORTATION: "Transport",
    VETERINARY: "Servicii Veterinare",
    SALARIES: "Salarii",
    SOFTWARE_ACQUISITION: "Achiziție Software",
    ADD_CATEGORY: "Adaugă Categorie",
    categoryName: "Numele Categoriei",
    loadingCategories: "Se încarcă categoriile ...",
    categorySaved: "Categoria a fost salvată!",
    errorCreatingCategory: "A apărut o eroare la crearea categoriei!",
    savingCategory: "Se salvază categoria ...",
    updatingCategory: "Se actualizează categoria ...",
    categoryUpdated: "Categoria a fost actualizată!",
    errorUpdatingCategory: "A apărut o eroare la actualizarea categoriei!",
    deleteCategory: "Șterge Categoria",
    deletingCategory: "Se șterge categoria ...",
    categoryDeleted: "Categoria a fost ștearsă!",
    errorDeletingCategory: "A apărut o eroare la ștergerea categoriei!",
    transactionHistory: "Istoric Tranzacții",
    savingTransaction: "Se salvează tranzacția ...",
    transactionSaved: "Tranzacția a fost salvată!",
    transactionSaveError: "A apărut o eroare la salvarea tranzacției!",
    deleteTransactionConfirmation: "Ești sigur că vrei să ștergi tranzacția?",
    deleteTransactionCategoryConfirmation:
      "Ești sigur că vrei să ștergi categoria?",
    transactionCategoryWillBeDeleted: "Categoria tranzacției va fi ștearsă.",
    transactionWillBeDeleted: "Tranzacția în curs va fi ștearsă.",
    deleteTransaction: "Șterge Tranzacția",
    transactionDeleted: "Tranzacția a fost ștearsă!",
    deletingTransaction: "Se şterge tranzacția...",
    startDate: "Data de început",
    viewStats: "Vizualizează statisticile",
    endDate: "Data de final",
    PERIOD_LAST_WEEK: "Săptămâna trecută",
    PERIOD_LAST_MONTH: "Luna trecută",
    PERIOD_LAST_YEAR: "Anul trecut",
    PERIOD_CUSTOM: "Perioadă personalizată",
    PERIOD_ALL_TIME: "Toată perioada",
    PERIOD_CURRENT_WEEK: "Sǎptamâna curentǎ",
    PERIOD_CURRENT_MONTH: "Luna curentǎ",
    noTransactionsFound: "Upsss, nu s-au găsit tranzacții ...",
    noAnimalsFound: "Upsss, nu s-au găsit animale ...",
    lastTransactions: "Ultimele Tranzacții",

    amountRequired: "Suma este obligatorie pentru a crea o tranzacție",
    dateRequired: "Data este obligatorie pentru a crea o tranzacție",
    categoryRequired: "Categoria este obligatorie pentru a crea o tranzacție",
    balance: "Balanță",
    financialStats: "Statistici Financiare",
    weAreSorry: "Ne cerem scuze...",
    anErrorOccurred: "A apărut o eroare.",
    reloadApp: "Reîncarcă aplicația",
    events: "Evenimente",
    addEvent: "Adaugă Eveniment",
    addAnimalExit: "Ieșirea Animalului",
    confirmCalving: "Confirmară Fătarea",
    calvingConfirmed: "Fătarea a fost confirmată",
    selectEventType: "Selectează Tipul Evenimentului",
    selectMedication: "Selectează Medicamentul",
    Treatment: "Tratament",
    Measurement: "Măsurare",
    Movement: "Mișcare",
    Insemination: "Inseminare",
    NaturalMating: "Împerechere Naturală",
    PregnancyCheckPositive: "Verificare Sarcină Pozitivă",
    PregnancyCheckNegative: "Verificare Sarcină Negativă",
    HealthCheckPositive: "Control de Sănătate Pozitiv",
    HealthCheckNegative: "Control de Sănătate Negativ",
    HoofHealthCheck: "Control Sănătate Copită",
    HeatDetection: "Detectare Călduri",
    Abortion: "Avort",
    DryOff: "Oprire Lactație",
    Weaning: "Înțărcare",
    LactationStart: "Începere Lactație",
    LactationEnd: "Sfârșit Lactație",
    DietChange: "Schimbare Dietă",
    Shearing: "Tuns",
    HoofTrimming: "Tundere Copită",
    EarTagging: "Marcaj Ureche",
    AnimalTransferExitEventType: "Evenimente Ieşire",
    AnimalSacrifice: "Sacrificare",
    AnimalSale: "Vânzare Animal",
    AnimalDeath: "Moartea Animalului",
    AnimalLoss: "Pierderea Animalului",
    AnimalTransferExit: "Transfer Ieşire",
    Castration: "Castrare",
    Dehorning: "Decornare",
    VeterinaryVisit: "Vizită Veterinar",
    BehaviorObservation: "Observare Comportament",
    EntryIntoQuarantine: "Intrare în carantină",
    ExitFromQuarantine: "Ieșire din carantină",
    Isolation: "Izolare",
    Vaccination: "Vaccinare",
    IllnessTreatment: "Tratament Boală",
    Deworming: "Deparazitare",
    InjuryTreatment: "Tratament Rănire",
    Surgery: "Chirurgie",
    PostSurgeryFollowUp: "Urmărire Post-Operatorie",
    SupplementAdministration: "Administrare Supliment",
    WeightCheck: "Verificare Greutate",
    BodyConditionScoring: "Evaluare Condiție Corporală",
    Other: "Altele",
    addEventType: "Adaugă un nou tip de eveniment",
    addMedicationType: "Adaugă un nou tratament",
    eventName: "Numele Evenimentului",
    medicationName: "Numele Tratamentului",
    deleteEventType: "Şterge tipul evenimentului",
    fromLocation: "De la Locația",
    fromPosition: "De la Poziția",
    toPosition: "La Poziția",
    toLocation: "Către Locația",
    departureDate: "Data Plecării",
    arrivalDate: "Data Sosirii",
    HeightCheck: "Verificare Înălțime",
    weight: "Greutate",
    otherObservations: "Alte Observații",
    generalEventDetails: "Detalii Generale Eveniment",
    specificEventDetails: "Detalii Specifice Eveniment",
    transactionAmount: "Suma Tranzacției",
    value: "Valoare",
    savingEvent: "Se salvează evenimentul ...",
    eventSaved: "Evenimentul a fost salvat!",
    viewEvent: "Vizualizează evenimentul",
    errorSavingEvent: "A apărut o eroare la salvarea evenimentului!",
    dosage: "Dozaj",
    deleteAnimalEvent: "Șterge Eveniment",
    animalEvent: "Evenimentul Animalului",
    deletingAnimalEvent: "Se șterge evenimentul ...",
    animalEventDeleted: "Evenimentul a fost șters!",
    animalEventDeleteError: "A apărut o eroare la ștergerea evenimentului!",
    deleteAnimalEventConfirmation: "Ești sigur că vrei să ștergi Evenimentul?",
    eventWillBeDeleted: "Evenimentul în curs va fi șters dacă da.",
    updatingAnimalEvent: "Se actualizează evenimentul ...",
    animalEventUpdated: "Evenimentul a fost actualizat!",
    animalEventUpdateError: "A apărut o eroare la actualizarea evenimentului!",
    typeRequired: "Tipul este obligatoriu pentru a crea un eveniment",
    dateRequiredEvent: "Data este obligatorie pentru a crea un eveniment",
    animalRequired: "Animalul este obligatoriu pentru a crea un eveniment",
    fromLocationRequired:
      "Locația de plecare este obligatorie pentru a crea acest eveniment",
    toLocationRequired:
      "Locația de sosire este obligatorie pentru a crea acest eveniment",
    valueRequired: "Valoarea este obligatorie pentru a crea acest eveniment",
    dosageRequired: "Dozajul este obligatoriu pentru a crea acest eveniment",
    medicationRequired:
      "Medicamentul este obligatoriu pentru a crea acest eveniment",
    quantityRequired:
      "Cantitatea este obligatorie pentru a crea acest eveniment",
    positionRequired: "Poziția este obligatorie pentru a crea acest eveniment",
    toApiaryRequired:
      "Stupina destinație este obligatorie pentru a crea acest eveniment",
    TreatmentEventType: "Evenimente de Tratament",
    MeasurementEventType: "Evenimente de Măsurare",
    MovementEventType: "Evenimente de Mișcare",
    ReproductiveEventType: "Evenimente de Reproducere",
    OtherEventType: "Alte Evenimente",
    searchAnimalEvent: "Caută Evenimentul Animalului",

    animalEvents: "Evenimente Animale",
    savingEventType: "Se salvază tipul evenimentului ...",
    savingMedicationType: "Se salvează tipul de tratament ...",
    eventTypeSaved: "Tipul evenimentului a fost salvat!",
    medicationTypeSaved: "Tipul de tratament a fost salvat!",
    errorCreatingEventType:
      "A apărut o eroare la crearea tipului evenimentului",
    errorCreatingMedicationType:
      "A apărut o eroare la crearea tipului de tratament",
    updatingEventType: "Se actualizează tipul evenimentului",
    updatingMedicationType: "Se actualizează tipul de tratament",
    eventTypeUpdated: "Actualizare cu success!",
    medicationTypeUpdated: "Actualizare cu success!",
    errorUpdatingEventType:
      "A apărut o eroare la actualizarea tipului evenimentului!",
    errorUpdatingMedicationType:
      "A apărut o eroare la actualizarea tipului de tratament!",
    deleteEventTypeConfirmation:
      "Ești sigur că vrei să ștergi tipul evenimentului?",
    deleteMedicationTypeConfirmation:
      "Ești sigur că vrei să ștergi tipul de tratament?",
    eventTypeWillBeDeleted: "Tipul evenimentului va fi ștears.",
    medicationTypeWillBeDeleted: "Tipul de tratament va fi șters.",
    deletingEventType: "Se șterge tipul evenimentului ...",
    deletingMedicationType: "Se șterge tipul de tratament ...",
    eventTypeDeleted: "Tipul evenimentului a fost şters!",
    medicationTypeDeleted: "Tipul de tratament a fost şters!",
    errorDeletingEventType:
      "A apărut o eroare la ștergerea tipului evenimentului",
    errorDeletingMedicationType:
      "A apărut o eroare la ștergerea tipului de tratament",
    chooseEventTypeParent: "Selectează categoria evenimentului",
    intelligentAssistant: "Asistent Inteligent",
    yourMessage: "Mesajul tău",
    generalHealthStatus:
      "Care este starea generală de sănătate a efectivului de bovine?",
    immediateMedicalAttention:
      "Care sunt vacile care necesită atenție medicală imediată?",
    optimizeFeeding:
      "Cum pot optimiza hrănirea pentru a crește producția de lapte?",
    closeToCalving: "Care sunt vacile care sunt aproape de fătare?",
    generalHealthStatusPigs:
      "Care este starea generală de sănătate a efectivului de porci?",
    growthRate: "Care este rata de creștere a porcilor în ultima lună?",
    pregnantSows:
      "Care sunt scroafele gestante care necesită atenție specială?",
    weaningStatus: "Care sunt purceii care urmează să fie înțărcați?",
    diseasePreventionStatus:
      "Care este statusul măsurilor de prevenire a bolilor în efectiv?",
    generalHealthStatusGoat:
      "Care este starea generală de sănătate a efectivului de caprine?",
    milkProduction: "Cum se prezintă producția de lapte în ultima perioadă?",
    pregnantGoats: "Care sunt caprele gestante care necesită monitorizare?",
    kidsHealth: "Care este starea de sănătate a iezilor?",
    generalHealthStatusSheep:
      "Care este starea generală de sănătate a efectivului de ovine?",
    lambingStatus: "Care sunt oile care urmează să fete în perioada următoare?",
    lambGrowth: "Care este rata de creștere a mieilor?",
    generalHealthStatusBees:
      "Care este starea generală de sănătate a familiilor de albine?",
    honeyProduction:
      "Cum se prezintă producția de miere față de perioada anterioară?",
    queenStatus: "Care este starea mătcilor în colonii?",
    welcomeToAssistant: "Bun venit la Asistentul Inteligent!",
    startConversation:
      "Începe conversația cu cateva dintre sugestiile noastre.",
    loading: "Se încarcă ...",
    UNDER_TREATMENT: "Tratament",
    noResultsForAnimal:
      "Nu au fost găsite rezultate pentru animalul specificat.",
    askAssistant: "Întreabă Asistentul",
    virtualAssistant: "Asistent Virtual",
    virtualFarmAssistant: "Asistentul Virtual al Fermei",
    farmAdviceWithAI:
      "Sfaturi și informații personalizate pentru ferma cu noul chat AI integrat.",
    noAnimalsFoundForFilter: "Nu s-au găsit animale pentru filtrul selectat",
    gestationCalendars: "Calendare gestaționale",
    activityCalendar: "Calendar Activități",
    inseminationDate: "Data înseminării",
    possibleCalvingInterval: "Intervalul probabil fătării",
    calvingDate: "Data fătării",
    openCalendar: "Deschide Calendarul",
    seeEvents: "Arată evenimentele",
    hideEvents: "Ascunde evenimentele",
    thisFeatureIsNotEnabled: "Acest modul nu este activ",
    buyFeature: "Achiziționează modulul",
    noGestationCalendarsPresent: "Nu ai nici un calendar gestațional",
    filterByAge: "Filtrare în funcție de vârstǎ",
    currentInterval: "Intervalul curent",
    resetFilter: "Reseteazǎ filtrarea",
    quickSelection: "Selecție rapidă",
    myApiaries: "Stupinele mele",
    myApiary: "Stupina mea",
    myHives: "Stupii mei",
    myQueens: "Reginele mele",
    addApiary: "Adaugǎ Stupinǎ",
    addHive: "Adaugǎ Stup",
    isLoading: "Se încarcǎ ...",
    hiveDetails: "Detalii Stup",
    apiaryDetails: "Detalii Stupinǎ",
    hiveImage: "Imaginea Stupului",
    hiveCode: "Cod/Nume unic",
    hiveType: "Tipul stupului",
    queenBirthYear: "Anul de naștere al mătcii",
    queenOrigin: "Originea mătcii",
    queen: "Matcǎ",
    framesNumber: "Număr de rame",
    familyPower: "Puterea familiei",
    beeCount: "Număr de albine",
    broodFramesNumber: "Număr de rame cu puiet",
    honeyFramesNumber: "Număr de rame cu miere",
    emptyFramesNumber: "Număr de rame goale",
    apiaryName: "Denumirea stupinei",
    apiaryAddress: "Adresă",
    dsvsa: "Nr. înregistrare/ autorizare DSVSA",
    nameRequired: "Numele este obligatoriu !",
    nameMinLength: "Lungimea minima a numelui este de 2 caractere!",
    addressRequired: "Adresa este obligatorie!",
    addressMinLength: "Lungimea minima a adresei este de 5 caractere!",
    addressMaxLength: "Lungimea maxima a adresei este de 200 caractere!",
    registrationCodeRequired: "Codul de înregistrare este obligatoriu!",
    registrationCodeFormat:
      "Codul de înregistrare poate conține doar litere, cifre și cratime!",
    descriptionMaxLength:
      "Lungimea maxima a descrierii este de 1000 caractere!",
    creatingApiary: "Se creeazǎ stupina...",
    apiaryCreated: "Stupina a fost creeatǎ!",
    apiaryCreateError: "A apǎrut o eroare la creearea stupinei",
    updatingApiary: "Se actualizeazǎ stupina...",
    deletingApiary: "Se şterge stupina...",
    apiaryDeleted: "Stupina a fost ştearsǎ",
    apiaryUpdated: "Stupina a fost actualizatǎ!",
    apiaryUpdateError: "A apǎrut o eroare la actualizarea stupinei",
    apiaryDeleteError: "A apǎrut o eroare la ştergerea stupinei",
    registrationCode: "Codul de înregistrare",
    hivesNumber: "Numǎrul de stupi",
    deleteApiary: "Şterge stupina",
    apiaries: "Stupine",
    hives: "Stupi",
    deleteApiaryConfirmation: "Eşti sigur că vrei să ştergi stupina?",
    apiaryWillBeDeleted:
      "Stupina, împreuna cu toate datele asociate, precum stupii,reginele si evenimentele acestora vor fi şterse",
    hiveManufacturer: "Producatorul stupului",
    creatingHive: "Se creeazǎ stupul...",
    hiveCreatedSuccessfully: "Stupul a fost creeat cu succes!",
    errorCreatingHive: "A apǎrut o eroare la creearea stupului!",
    apiary: "Stupina",
    apiaryRequired: "Stupina este obligatorie",
    apiaryFormat: "Codul stupinei poate conține doar litere, cifre și cratimă",
    codeRequired: "Codul stupului este obligatoriu",
    codeFormat: "Codul stupului poate conține doar litere, cifre și cratimă",
    codeMinLength: "Codul stupului trebuie să aibă minim 2 caractere",
    codeMaxLength: "Codul stupului nu poate depăși 50 de caractere",
    hiveTypeRequired: "Tipul stupului este obligatoriu",
    invalidHiveType: "Tipul stupului selectat nu este valid",
    manufacturerRequired: "Producătorul stupului este obligatoriu",
    manufacturerMinLength:
      "Numele producătorului trebuie să aibă minim 2 caractere",
    manufacturerMaxLength:
      "Numele producătorului nu poate depăși 100 de caractere",
    broodFramesRequired: "Numărul ramelor cu puiet este obligatoriu",
    broodFramesFormat:
      "Numărul ramelor cu puiet trebuie să fie un număr întreg",
    broodFramesRange: "Numărul ramelor cu puiet trebuie să fie între 0 și 100",
    honeyFramesRequired: "Numărul ramelor cu miere este obligatoriu",
    honeyFramesFormat:
      "Numărul ramelor cu miere trebuie să fie un număr întreg",
    honeyFramesRange: "Numărul ramelor cu miere trebuie să fie între 0 și 100",
    emptyFramesRequired: "Numărul ramelor goale este obligatoriu",
    emptyFramesFormat: "Numărul ramelor goale trebuie să fie un număr întreg",
    emptyFramesRange: "Numărul ramelor goale trebuie să fie între 0 și 100",
    beeCountRequired: "Numărul de albine este obligatoriu",
    beeCountFormat: "Numărul de albine trebuie să fie un număr întreg",
    beeCountRange: "Numărul de albine trebuie să fie între 0 și 100000",
    addQueen: "Adaugǎ Matcǎ",
    deleteHive: "Şterge stupul",
    hiveUpdating: "Se actualizeazǎ stupul...",
    hiveUpdatedSuccessfully: "Stupul a fost actualizat!",
    errorUpdatingHive: "A apǎrut o eroare la actualizarea stupului",
    deleteHiveConfirmation: "Eşti sigur că vrei să ştergi stupul?",
    hiveWillBeDeleted:
      "Stupul, împreună cu toate datele asociate, precum regina și evenimentele acestora vor fi șterse",
    deletingHive: "Se şterge stupul...",
    deletingQueen: "Se şterge matca...",
    queenDeleted: "Matca a fost ştearsǎ!",
    hiveDeleted: "Stupul a fost şters!",
    queenDeleteError: "A apărut o eroare la ştergerea mǎtcii",
    hiveDeleteError: "A apărut o eroare la ştergerea stupului",
    yearOfBirth: "Anul nașterii",
    queenColor: "Culoarea mǎtcii",
    "Own Production": "Producție proprie",
    Purchased: "Achiziționare",
    Blue: "Albastru",
    Green: "Verde",
    Red: "Roșu",
    Yellow: "Galben",
    Black: "Negru",
    White: "Alb",
    nameFormat:
      "Numele reginei poate conține doar litere, cifre, spații și cratimă",
    yearRequired: "Anul este obligatoriu",
    yearFormat: "Anul trebuie să fie în format YYYY (ex: 2024)",
    yearRange: "Anul trebuie să fie între ultimii 5 ani și anul curent",
    colorRequired: "Culoarea reginei este obligatorie",
    invalidColor: "Culoarea selectată nu este validă",
    originRequired: "Originea reginei este obligatorie",
    invalidOrigin: "Originea selectată nu este validă",
    creatingQueen: "Se creeazǎ regina...",
    queenCreated: "Regina a fost creeatǎ!",
    queenCreateError: "A apǎrut o eroare la creearea reginei",
    hive: "Stup",
    deleteQueen: "Şterge matca",
    queenUpdating: "Se actualizeazǎ matca",
    queenUpdatedSuccessfully: "Matca a fost actualizatǎ!",
    errorUpdatingQueen: "A apǎrut o eroare la actualizarea reginei",
    deleteQueenConfirmation: "Eşti sigur că vrei să ştergi matca?",
    queenWillBeDeleted:
      "Matca, împreună cu toate datele asociate, notitele, sarciniile si evenimentele vor fi șterse",
    bees: "Albine",
    allApiaries: "Toate Stupinele",
    searchHive: "Cautǎ stup",
    allHiveTypes: "Toate tipurile de stup",
    apiaryWasSuccessfullyAdded: "Stupina a fost adaugatǎ cu succes!",
    hiveStep:
      "Urmǎtorul pas este adaugarea stupilor în noua dumneavoastrǎ stupinǎ, doriți sǎ continuați ?",
    hiveWasSuccessfullyAdded: "Stupul a fost adǎugat cu succes!",
    queenStep:
      "Urmǎtorul pas este adaugarea reginei în noul dumneavoastrǎ stup, doriți sǎ continuați ?",
    noHivesFoundForGivenQuery:
      "Nu au fost gǎsiți stupi pentru cǎutarea dumneavoastrǎ",
    noQueensFoundForGivenQuery:
      "Nu au fost gǎsite mǎtci pentru cǎutarea dumneavoastrǎ",
    allQueens: "Toate Mǎtcile",
    assocApiary: "Asociazǎ Stupinǎ",
    assocHive: "Asociazǎ Stup",
    noApiariesAssociated: "Nu exista stupine asociate",
    noHivesAssociated: "Nu exista stupi asociati",
    oneApiaryAssociated: "O stupina asociata",
    oneHiveAssociated: "Un stup asociat",
    apiariesAssociated: "stupine asociate",
    hivesAssociated: "stupi asociati",
    generalAnimalWelfare:
      "Care este starea generală de bunăstare a animalelor?",
    diseasePreventionPlan:
      "Care este planul de prevenție a bolilor pentru toate animalele?",
    feedingStrategyGeneral:
      "Este optimă strategia de hrănire pentru toate animalele?",
    reproductiveHealthGeneral:
      "Care este situația sănătății reproductive în efectiv?",
    udderHealth: "Care este starea de sănătate a ugerului la vaci?",
    heatDetection: "Care vaci prezintă semne de călduri?",
    pigletMortality: "Care este rata mortalității la purcei?",
    boarPerformance: "Care este performanța vierilor de reproducție?",
    generalHealthStatusGoats:
      "Care este starea generală de sănătate a efectivului de caprine?",
    hoofHealth: "Care este starea copitelor la caprine?",
    pasturePlanning: "Este eficient planul de pășunat?",
    flockBehavior: "Sunt modificări în comportamentul turmei?",
    sheepSorting: "Care oi necesită sortare pentru diferite grupuri?",
    hiveStrength: "Care este puterea familiilor de albine?",
    varoaStatus: "Care este nivelul de infestare cu varroa?",
    nectarSources: "Sunt suficiente surse de nectar în zonă?",
    biosecurityMeasures: "Care sunt măsurile de biosecuritate implementate?",
    selectApiaries: "Selectează stupinele",
    selectApiary: "Selectează stupina",
    selectHive: "Selectează stupul",
    selectHives: "Selectează stupii",
    noResultsFound: "Nu au fost găsite rezultate",
    hideOtherApiariesAssociated: "Ascunde celelalte stupine asociate",
    hideOtherHivesAssociated: "Ascunde ceilalți stupi asociati",
    seeOtherApiariesAssociated: "Vezi celelalte stupine asociate",
    seeOtherHivesAssociated: "Vezi ceilați stupi asociati",
    noApiaryFound: "Nu a fost găsită nicio stupină",
    noQueensAssociated: "Nu existǎ mǎtci ascociate",
    oneQueenAssociated: "O matcă asociată",
    queensAssociated: "mǎtci asociate",
    selectQueens: "Selectează mǎtcile",
    assocQueen: "Asociazǎ Matcă",
    theHive: "Stupul",
    addApiaryEvent: "Adaugă Eveniment Stupină",
    noApiarySelected: "Nu a fost selectată nicio stupină",
    noHiveSelected: "Nu a fost selectat niciun stup",
    PestControl: "Control Dăunători",
    VegetationManagement: "Managementul Vegetației",
    EquipmentMaintenance: "Întreținere Echipament",
    InfrastructureRepair: "Reparații Infrastructură",
    ApiaryMaintenance: "Întreținere Stupină",
    SpringAssessment: "Evaluare de Primăvară",
    WinterPreparationCheck: "Verificare Pregătire pentru Iarnă",
    AuthorityInspection: "Inspecție Autorități",
    OfficialVeterinaryInspection: "Inspecție Veterinară Oficială",
    ApiaryInspection: "Inspecție Stupină",
    PastoralMovement: "Deplasare Pastorală",
    PermanentLocationChange: "Schimbare Permanentă Locație",
    ApiaryMovement: "Deplasare Stupină",
    personName: "Numele Persoanei",
    eventTypeRequired: "Tipul evenimentului este obligatoriu",
    eventTypeFormat:
      "Tipul evenimentului trebuie să conțină doar litere, numere și cratime",
    personNameMaxLength: "Numele persoanei nu poate depăși 255 caractere",
    dateFormat: "Format dată invalid",
    notesMaxLength: "Notele nu pot depăși 1000 caractere",
    creatingApiaryEvent: "Se creează evenimentul stupinei...",
    apiaryEventCreatedSuccessfully:
      "Evenimentul stupinei a fost creat cu succes!",
    errorCreatingApiaryEvent:
      "A apărut o eroare la crearea evenimentului stupinei",
    apiariesEvents: "Evenimente Stupine",
    apiaryEvent: "Eveniment Stupină",
    deletingApiaryEvent: "Se șterge evenimentul stupinei...",
    apiaryEventDeleted: "Evenimentul stupinei a fost șters!",
    apiaryEventDeleteError:
      "A apărut o eroare la ștergerea evenimentului stupinei",
    ApiaryMovementEventType: "Evenimente de Deplasare a Stupinei",
    ApiaryInspectionEventType: "Evenimente de Inspecție Stupinei",
    ApiaryMaintenanceEventType: "Evenimente de Întreținere Stupinei",
    Acacia: "Salcâm",
    Linden: "Tei",
    Sunflower: "Floarea Soarelui",
    Rapeseed: "Rapiță",
    "Poly Floral": "Poliflor",
    selectFloraType: "Selectează Tipul de Floră",
    apiaryEvents: "Evenimente Stupină",
    apiaryEventsDescription: "Monitorizeaza activitatea stupinelor tale",
    hiveEventsDescription: "Monitorizeaza activitatea stupilor tăi",
    noEventsFound: "Nu au fost găsite evenimente",
    departureDateRequired: "Data plecării este obligatorie",
    floraTypeRequired: "Tipul de floră este obligatoriu",
    partners: "Parteneri",
    viewOffer: "Vezi Oferta",
    allTimes: "Toate perioadele",
    selectTimeFrame: "Selectează perioada",
    dateTo: "Data de final",
    dateFrom: "Data de început",
    present: "Prezent",
    selectDateTo: "Selectează data de final",
    selectDateFrom: "Selectează data de început",
    showResults: "Arată Rezultatele",
    hiveEvents: "Evenimente Stupi",
    queenEvents: "Evenimente Mătci",
    queenEventsDescription: "Monitorizeaza activitatea reginelor tale",
    allHives: "Toți Stupii",
    creatingHiveEvent: "Se creează evenimentul stupului...",
    hiveEventCreatedSuccessfully:
      "Evenimentul stupului a fost creat cu succes!",
    errorCreatingHiveEvent:
      "A apărut o eroare la crearea evenimentului stupului",
    "Hive must have a queen for this operation":
      "Pentru această operațiune stupul trebuie să aibă o matcă",
    ColonyDivision: "Divizarea Coloniei",
    SwarmPrevention: "Prevenirea Roirii",
    RemovingSuper: "Îndepărtarea Magaziei",
    AddingSuper: "Adăugarea Magaziei",
    AddingFrames: "Adăugarea Ramelor",
    QueenReplacement: "Înlocuirea Reginei",
    Feeding: "Hrănire",
    HiveManagement: "Gestionarea Stupului",
    WeightMeasurement: "Măsurarea Greutății",
    BroodFramesCount: "Actualizarea Nr. Ramelor cu Puiet",
    HoneyFramesCount: "Actualizarea Nr. Ramelor cu Miere",
    ColonyStrengthAssessment: "Evaluarea Puterii Coloniei",
    HiveMeasurement: "Măsurarea Stupului",
    PreventiveTreatment: "Tratament Preventiv",
    DiseaseTreatment: "Tratament Boli",
    NosemaTreatment: "Tratament Nosema",
    VarroaTreatment: "Tratament Varroa",
    HiveTreatment: "Tratament Stup",
    WinterCheck: "Verificare de Iarnă",
    BroodInspection: "Inspecția Puietului",
    QueenInspection: "Inspecția Reginei",
    DiseaseCheck: "Verificare Boli",
    RoutineCheck: "Verificare de Rutină",
    HiveInspection: "Inspecția Stupului",
    TransferToOtherApiary: "Transfer la Altă Stupină",
    TransferWithinApiary: "Transfer în Stupină",
    HiveMovement: "Mutarea Stupului",
    hiveEvent: "Eveniment Stup",
    HiveEquipment: "Echipament Stup",
    AddFrames: "Adaugă Rame",
    RemoveFrames: "Îndepărtează Rame",
    AddSuper: "Adaugă Magazie",
    RemoveSuper: "Îndepărtează Magazie",
    AddBroodBox: "Adaugă Cutie Puiet",
    RemoveBroodBox: "Îndepărtează Cutie Puiet",
    deletingHiveEvent: "Se șterge evenimentul stupului...",
    hiveEventDeleted: "Evenimentul stupului a fost șters!",
    hiveEventDeleteError:
      "A apărut o eroare la ștergerea evenimentului stupului",
    deleteHiveEvent: "Șterge Eveniment",
    deleteHiveEventConfirmation: "Ești sigur că vrei să ștergi evenimentul?",
    hiveEventWillBeDeleted: "Evenimentul în curs va fi șters dacă da.",
    updatingHiveEvent: "Se actualizează evenimentul stupului...",
    hiveEventUpdated: "Evenimentul stupului a fost actualizat!",
    hiveEventUpdateError:
      "A apărut o eroare la actualizarea evenimentului stupului",
    HiveMovementEventType: "Evenimente de Mutare a Stupului",
    HiveInspectionEventType: "Evenimente de Inspecție a Stupului",
    HiveTreatmentEventType: "Evenimente de Tratament al Stupului",
    HiveMeasurementEventType: "Evenimente de Măsurare a Stupului",
    HiveEquipmentEventType: "Evenimente de Echipare a Stupului",
    hiveRequired: "Stupul este obligatoriu!",
    beeKeepingEvents: "Evenimente Apicultură",
    pieces: "bucăți",
    grams: "grame",
    inHivePosition: "Poziția în stup",
    addNewLocation: "Adaugă o nouă locație",
    previousLocations: "Locații anterioare",
    noPreviousLocations: "Nu există locații anterioare",
    addLocation: "Adaugă Locația",
    currentLocation: "Locația curentă",
    noCurrentLocation: "Nu există locație curentă",
    currentPosition: "Poziția curentǎ",
    currentWeight: "Greutatea curentǎ",
    broodBoxCount: "Numărul de cutii de puiet",
    superCount: "Numărul de magazii",
    positionMaxLength: "Lungimea maximă a poziției este de 255 de caractere",
    fromAndToPositionMustBeDifferent:
      "Poziția de plecare și cea de sosire trebuie să fie diferite",
    "More empty frames are needed":
      "Pentru efectuarea operațiunii aveți nevoie de mai multe rame goale.",
    "No supers available to remove":
      "Nu există magazii disponibile pentru îndepărtare",
    "You can't delete more suppers than available":
      "Nu puteți șterge mai multe magazii decât sunt disponibile",
    "No brood boxes available to remove":
      "Nu există cutii de puiet disponibile pentru îndepărtare",
    "You can't remove more brood boxes than available":
      "Nu puteți îndepărta mai multe cutii de puiet decât sunt disponibile",
    HiveQueenEventsEventType: "Evenimentele Mătci",
    HiveQueenEvents: "Eveniment Matcǎ",
    QueenMarking: "Marcaj Matcă",
    QueenClipping: "Tăiere Aripioare Matcă",
    QueenCaging: "Izolarea Reginei (în cușcă)",
    eventInfoDescription: "Informații despre tipul evenimentului",
    "Events related to relocating the entire apiary, including permanent location changes and seasonal movements for better foraging opportunities.":
      "Evenimente legate de relocarea întregii stupine, inclusiv schimbări permanente de locație și mutări sezoniere pentru oportunități mai bune de cules.",
    "Official and routine inspections of the apiary as a whole, including regulatory checks and seasonal assessments.":
      "Inspecții oficiale și de rutină ale întregii stupine, inclusiv verificări obligatorii și evaluări sezoniere.",
    "Maintenance activities for the apiary infrastructure, equipment, and surrounding environment.":
      "Activități de întreținere a infrastructurii stupinei, a echipamentelor și a mediului înconjurător.",
    "Events related to moving individual hives within or between apiaries.":
      "Evenimente legate de mutarea stupilor individuali în cadrul aceleiași stupine sau între stupine diferite.",
    "Regular and specific inspections of individual hives to assess health, strength, and conditions.":
      "Inspecții regulate și specifice ale stupilor individuali pentru evaluarea sănătății, puterii și condițiilor generale.",
    "Medical and preventive treatments applied to hives for various health conditions.":
      "Tratamente medicale și preventive aplicate stupilor pentru diverse afecțiuni.",
    "Measurements and assessments of various hive parameters and colony strength indicators.":
      "Măsurători și evaluări ale diferiților parametri ai stupului și indicatori ai puterii coloniei.",
    "Addition or removal of hive equipment and components.":
      "Adăugarea sau îndepărtarea echipamentelor și componentelor stupului.",
    "Events related to queen bee management and maintenance.":
      "Evenimente legate de gestionarea și întreținerea mătcii.",
    "Permanent relocation of the entire apiary to a new location, requiring updates to official registration.":
      "Relocarea permanentă a întregii stupine într-o nouă locație, necesitând actualizarea înregistrărilor oficiale.",
    "Temporary relocation of hives to take advantage of specific nectar flows or pollination opportunities.":
      "Relocarea temporară a stupilor pentru a beneficia de culesuri specifice sau oportunități de polenizare.",
    "Mandatory veterinary inspection of the apiary for health certification and disease monitoring.":
      "Inspecție veterinară obligatorie a stupinei pentru certificarea sănătății și monitorizarea bolilor.",
    "Official inspection by beekeeping authorities for compliance with regulations and standards.":
      "Inspecție oficială de către autoritățile apicole pentru verificarea conformității cu reglementările și standardele.",
    "Assessment of apiary conditions and preparations before winter season.":
      "Evaluarea condițiilor stupinei și pregătirile înainte de sezonul de iarnă.",
    "Evaluation of apiary condition and colonies after winter, planning for the new season.":
      "Evaluarea stării stupinei și a coloniilor după iarnă, planificarea pentru noul sezon.",
    "Repairs and maintenance of apiary infrastructure like stands, fencing, or windbreaks.":
      "Reparații și întreținere a infrastructurii stupinei precum suporturi, garduri sau paravânturi.",
    "Maintenance of shared apiary equipment such as extractors, tools, or storage facilities.":
      "Întreținerea echipamentelor comune ale stupinei precum extractoare, unelte sau spații de depozitare.",
    "Management of vegetation around hives, including mowing, planting, or removing problematic plants.":
      "Gestionarea vegetației din jurul stupilor, inclusiv cosirea, plantarea sau îndepărtarea plantelor problematice.",
    "Control of apiary-level pests such as mice, ants, or wasps.":
      "Controlul dăunătorilor la nivelul stupinei precum șoareci, furnici sau viespi.",
    "Moving a hive to a different location within the same apiary.":
      "Mutarea unui stup într-o altă locație în cadrul aceleiași stupine.",
    "Transferring a hive to a different apiary location.":
      "Transferul unui stup într-o altă stupină.",
    "Regular inspection of hive conditions, including population, stores, and overall health.":
      "Inspecția regulată a condițiilor stupului, inclusiv populație, rezerve și starea generală de sănătate.",
    "Specific inspection focusing on signs of diseases or parasites.":
      "Inspecție specifică concentrată pe semnele de boli sau paraziți.",
    "Inspection to verify queen presence, performance, and quality.":
      "Inspecție pentru verificarea prezenței mătcii, a performanței și calității acesteia.",
    "Detailed inspection of brood patterns, health, and development.":
      "Inspecție detaliată a modelelor de puiet, sănătății și dezvoltării acestuia.",
    "Quick inspection during winter to assess colony survival and stores.":
      "Inspecție rapidă în timpul iernii pentru evaluarea supraviețuirii coloniei și a rezervelor.",
    "Application of treatments specifically targeting Varroa mite infestations.":
      "Aplicarea tratamentelor specifice împotriva infestărilor cu acarianul Varroa.",
    "Treatment for Nosema disease in affected colonies.":
      "Tratament pentru boala Nosema în coloniile afectate.",
    "Treatment for various bee diseases other than Varroa or Nosema.":
      "Tratament pentru diverse boli ale albinelor, altele decât Varroa sau Nosema.",
    "Preventive treatments applied to maintain colony health.":
      "Tratamente preventive aplicate pentru menținerea sănătății coloniei.",
    "Assessment of overall colony strength, including bee population and vitality.":
      "Evaluarea puterii generale a coloniei, inclusiv populația de albine și vitalitatea.",
    "Counting and assessment of frames containing honey stores.":
      "Numărarea și evaluarea ramelor care conțin rezerve de miere.",
    "Measurement of frames containing brood in different stages.":
      "Măsurarea ramelor care conțin puiet în diferite stadii.",
    "Recording the weight of the hive to track honey stores and colony development.":
      "Înregistrarea greutății stupului pentru urmărirea rezervelor de miere și dezvoltarea coloniei.",
    "Adding a honey super to provide more space for honey storage.":
      "Adăugarea unui magazin pentru a oferi mai mult spațiu pentru depozitarea mierii.",
    "Removing a honey super, typically for honey harvesting.":
      "Îndepărtarea unui magazin, de obicei pentru recoltarea mierii.",
    "Adding new frames to the hive for expansion or replacement.":
      "Adăugarea de rame noi în stup pentru extindere sau înlocuire.",
    "Removing frames for maintenance, replacement, or honey extraction.":
      "Îndepărtarea ramelor pentru întreținere, înlocuire sau extragerea mierii.",
    "Adding a new brood box to accommodate colony growth.":
      "Adăugarea unui nou corp de cuib pentru a acomoda creșterea coloniei.",
    "Removing a brood box for colony size management or maintenance.":
      "Îndepărtarea unui corp de cuib pentru gestionarea dimensiunii coloniei sau întreținere.",
    "Marking the queen with a color code for easy identification and age tracking.":
      "Marcarea mătcii cu un cod de culoare pentru identificare ușoară și urmărirea vârstei.",
    "Clipping the queen's wings to prevent swarming and aid in swarm management.":
      "Tăierea aripilor mătcii pentru prevenirea roirii și ajutor în gestionarea roiurilor.",
    "Temporarily confining the queen in a cage for various management purposes.":
      "Izolarea temporară a mătcii într-o cușcă pentru diverse scopuri de management.",
    beekeeping: "Apicultură",
    currentChats: "Conversații curente",
    whatCanIHelpWith: "Cu ce vă pot ajuta?",
    recentChats: "Conversații recente",
    suggestedQuestions: "Întrebări sugerate",
    startChat: "Începe conversația",
    viewAllChats: "Vezi toate conversațiile",
    noHivesAvailable: "Nu există stupi disponibili",
    noHive: "Fǎrǎ stup",
    noQueen: "Fǎrǎ matcǎ",
    queenHasNoHiveAssigned: "Matca nu are nici un stup asociat",
    queenHasNoHiveAssignedAndNoAvailableHives:
      "Matca nu are nici un stup asociat și nu există stupi disponibili",
    removeQueenFromHive: "Îndepărtează Matca din Stup",
    removeQueenFromHiveConfirmation:
      "Ești sigur că vrei să îndepărtezi matca din stup?",
    removingQueen: "Se îndepărtează matca...",
    queenRemovedFromHive: "Matca a fost îndepărtată din stup!",
    errorRemovingQueenFromHive:
      "A apărut o eroare la îndepărtarea mǎtcii din stup",
    hiveHasNoQueenAndQueensAvailable:
      "Stupul nu are nici o matcă asociată și există mătci disponibile",
    hiveHasNoQueenAndNoQueensAvailable:
      "Stupul nu are nici o matcă asociată și nu există mătci disponibile",
    queenHasNoHiveAndHivesAvailable:
      "Matca nu are nici un stup asociat și există stupi disponibili",
    removingThread: "Se șterge conversația...",
    threadRemoved: "Conversația a fost ștearsă!",
    errorRemovingThread: "A apărut o eroare la ștergerea conversației",
    removeThread: "Șterge Conversația",
    deleteThreadConfirmation: "Ești sigur că vrei să ștergi conversația?",
    allThreads: "Toate Conversațiile",
    messageRateLimitExceeded: "Limita de mesaje a fost depășită.",
    limitWillBeRenewedIn: "Limita va fi reînnoită în",
    messageSendError: "A apărut o eroare la trimiterea mesajului",
    youStillHave: "Mai aveți",
    messagesLeftThisMonth: "mesaje disponibile în aceastǎ lună",
    messageLeftThisMonth: "mesaj disponibil în aceastǎ lună",
    "Medical and preventive treatments applied to animals for various health conditions and maintenance.":
      "Tratamente medicale și preventive aplicate animalelor pentru diverse afecțiuni și întreținere.",
    "Recording and tracking of various animal metrics and physical parameters.":
      "Înregistrarea și urmărirea diverselor metrici și parametri fizici ai animalelor.",
    "Events related to animal movement between locations, including quarantine and isolation.":
      "Evenimente legate de mișcarea animalelor între locații, inclusiv carantină și izolare.",
    "Events related to breeding, pregnancy, birth, and reproductive health management.":
      "Evenimente legate de reproducere, gestație, fătare și gestionarea sănătății reproductive.",
    "Events marking the permanent departure of animals from the facility.":
      "Evenimente care marchează plecarea permanentă a animalelor din unitate.",
    "Miscellaneous animal management events including routine care and observations.":
      "Evenimente diverse de gestionare a animalelor, inclusiv îngrijirea de rutină și observațiile.",
    "Planned end of life for research or other approved purposes.":
      "Sfârșit planificat al vieții în scopuri de cercetare sau alte scopuri aprobate.",
    "Transfer of animal ownership through commercial transaction.":
      "Transferul proprietății animalului prin tranzacție comercială.",
    "Recording of natural or unexpected death of an animal.":
      "Înregistrarea morții naturale sau neașteptate a unui animal.",
    "Documentation of missing or unaccounted for animals.":
      "Documentarea animalelor dispărute sau necontabilizate.",
    "Administration of vaccines for disease prevention.":
      "Administrarea vaccinurilor pentru prevenirea bolilor.",
    "Medical intervention for diagnosed diseases or conditions.":
      "Intervenție medicală pentru boli sau afecțiuni diagnosticate.",
    "Administration of antiparasitic medications for internal parasite control.":
      "Administrarea medicamentelor antiparazitare pentru controlul paraziților interni.",
    "Medical care for physical injuries or trauma.":
      "Îngrijire medicală pentru leziuni fizice sau traume.",
    "Surgical procedures performed on animals.":
      "Proceduri chirurgicale efectuate pe animale.",
    "Monitoring and care following surgical procedures.":
      "Monitorizarea și îngrijirea după procedurile chirurgicale.",
    "Provision of additional nutrients or dietary supplements.":
      "Administrarea de nutrienți suplimentari sau suplimente alimentare.",
    "Recording of animal body weight for monitoring growth and health.":
      "Înregistrarea greutății corporale a animalului pentru monitorizarea creșterii și sănătății.",
    "Assessment of animal's physical condition and fat reserves.":
      "Evaluarea condiției fizice și a rezervelor de grăsime ale animalului.",
    "Movement of animals between different locations or facilities.":
      "Mișcarea animalelor între diferite locații sau unități.",
    "Placement of animals in quarantine for health monitoring.":
      "Plasarea animalelor în carantină pentru monitorizarea sănătății.",
    "Release of animals from quarantine after health clearance.":
      "Eliberarea animalelor din carantină după verificarea sănătății.",
    "Separation of animals for health or management purposes.":
      "Separarea animalelor din motive de sănătate sau gestionare.",
    "Artificial insemination procedure for breeding.":
      "Procedura de însămânțare artificială pentru reproducere.",
    "Recording of natural breeding events.":
      "Înregistrarea evenimentelor de împerechere naturală.",
    "Confirmation of pregnancy through testing.":
      "Confirmarea gestației prin testare.",
    "Documentation of negative pregnancy test results.":
      "Documentarea rezultatelor negative ale testului de gestație.",
    "Observation and recording of estrus signs.":
      "Observarea și înregistrarea semnelor de călduri.",
    "Documentation of birthing events and outcomes.":
      "Documentarea evenimentelor de fătare și a rezultatelor.",
    "Recording of pregnancy loss events.":
      "Înregistrarea evenimentelor de pierdere a gestației.",
    "Beginning of milk production period.":
      "Începutul perioadei de producție de lapte.",
    "End of milk production period.":
      "Sfârșitul perioadei de producție de lapte.",
    "Routine health screening with positive findings.":
      "Screening de sănătate de rutină cu constatări pozitive.",
    "Routine health screening with no concerning findings.":
      "Screening de sănătate de rutină fără constatări îngrijorătoare.",
    "Assessment of hoof condition and health.":
      "Evaluarea stării și sănătății copitelor.",
    "Planned end of lactation in dairy animals.":
      "Încheierea planificată a lactației la animalele de lapte.",
    "Separation of young animals from maternal feeding.":
      "Separarea animalelor tinere de la alăptarea maternă.",
    "Modification of feed type or feeding regime.":
      "Modificarea tipului de hrană sau a regimului de hrănire.",
    "Removal of wool or hair coat.": "Îndepărtarea lânii sau a blănii.",
    "Maintenance trimming of hooves.": "Tăierea de întreținere a copitelor.",
    "Application of identification tags.":
      "Aplicarea crotaliilor de identificare.",
    "Surgical or chemical sterilization of male animals.":
      "Sterilizarea chirurgicală sau chimică a animalelor mascule.",
    "Removal or prevention of horn growth.":
      "Îndepărtarea sau prevenirea creșterii coarnelor.",
    "Scheduled or emergency veterinary examination.":
      "Examinare veterinară programată sau de urgență.",
    "Documentation of animal behavior patterns or changes.":
      "Documentarea tiparelor de comportament sau a schimbărilor comportamentale ale animalelor.",
    tutorialsIntro:
      "Suntem încântați că ai ales să folosești aplicația noastră. Pentru a\n" +
      "            te ajuta să te familiarizezi cu funcționalitățile acesteia, am creat\n" +
      "            tutoriale video detaliate care explică pas cu pas cum să utilizezi\n" +
      "            aplicația noastră.",
    NotFoundMessage: "Pagina pe care o căutați nu există",
    goMainScreen: "Mergi la pagina principală",
    birdsGroups: "Grupuri de Păsări",
    newBirdGroup: "Grup Nou de Păsări",
    groupName: "Numele Grupului",
    Adult: "Adult",
    Juvenile: "Juvenil",
    Hatching: "Pui",
    Unknown: "Necunoscut",
    birdGroupAdultDescription:
      "Păsări complet mature care au finalizat dezvoltarea fizică și au ajuns la vârsta reproductivă. De obicei, prezintă penaj adult și modele de colorare specifice speciei lor.",
    birdGroupJuvenileDescription:
      "Păsări tinere care au părăsit cuibul și pot zbura, dar nu au ajuns la maturitatea adultă. Pot prezenta modele de penaj intermediare și continuă să crească. De obicei între 2-12 luni, în funcție de specie.",
    birdGroupHatchingDescription:
      "Păsări nou eclozate până la stadiul de pui. În general, încă în cuib, necesitând îngrijire și hrănire parentală. Pot fi parțial sau complet dependente de părinți pentru supraviețuire.",
    birdGroupUnknownDescription:
      "Vârsta nu poate fi determinată din cauza markerilor vizuali insuficienți, documentației sau constrângerilor de observație.",
    birdGroupMixtureDescription:
      "Grup de păsări cu categorii de vârstă mixte, inclusiv adulți, juvenili și pui.",
    ageGroup: "Grup de Vârstă",
    birdsCount: "Numărul de Păsări",
    birdGroups: "Grupuri de Păsări",
    creatingBirdsGroup: "Se creează grupul de păsări...",
    birdsGroupCreated: "Grupul de păsări a fost creat!",
    errorCreatingBirdsGroup: "A apărut o eroare la crearea grupului de păsări",
    name100Characters: "Numele nu poate depăși 100 de caractere",
    description500Characters: "Descrierea nu poate depăși 500 de caractere",
    countMin1: "Numărul de păsări trebuie să fie minim 1",
    birdsSummary: "Sumar Date Păsări",
    count: "Număr",
    deletingBirdsGroup: "Se șterge grupul de păsări...",
    birdsGroupDeleted: "Grupul de păsări a fost șters!",
    birdsGroupDeleteError: "A apărut o eroare la ștergerea grupului de păsări",
    deleteBirdsGroupConfirmation:
      "Ești sigur că vrei să ștergi grupul de păsări?",
    birdsGroupWillBeDeleted: "Grupul de păsări va fi șters dacă da.",
    updatingBirdsGroup: "Se actualizează grupul de păsări...",
    birdsGroupUpdated: "Grupul de păsări a fost actualizat!",
    errorUpdatingBirdsGroup:
      "A apărut o eroare la actualizarea grupului de păsări",
    deleteBirdsGroup: "Șterge Grupul de Păsări",
    Duck: "Rață",
    Turkey: "Curcan",
    Goose: "Gâscă",
    Quail: "Prepeliță",
    Ostrich: "Struț",
    "Rhode Island Red": "Rhode Island Roșu",
    "Plymouth Rock": "Plymouth Rock",
    Leghorn: "Leghorn",
    Australorp: "Australorp",
    Orpington: "Orpington",
    Sussex: "Sussex",
    Wyandotte: "Wyandotte",
    Brahma: "Brahma",
    Cochin: "Cochin",
    Silkie: "Mătase",
    "New Hampshire": "New Hampshire",
    Ameraucana: "Ameraucana",
    Marans: "Marans",
    Pekin: "Pekin",
    Muscovy: "Muscovy",
    Runner: "Alergătoare",
    "Khaki Campbell": "Khaki Campbell",
    Cayuga: "Cayuga",
    "Swedish Blue": "Suedeză Albastră",
    Rouen: "Rouen",
    "Welsh Harlequin": "Arlechin Galez",
    "Broad Breasted White": "Piept Lat Alb",
    "Broad Breasted Bronze": "Piept Lat Bronz",
    "Bourbon Red": "Bourbon Roșu",
    Narragansett: "Narragansett",
    "Royal Palm": "Palmier Regal",
    "Black Spanish": "Spaniol Negru",
    "Beltsville Small White": "Beltsville Alb Mic",
    Toulouse: "Toulouse",
    Embden: "Embden",
    African: "Africană",
    Chinese: "Chineză",
    Pilgrim: "Pilgrim",
    "American Buff": "Americană Bej",
    Sebastopol: "Sebastopol",
    "African Black": "African Negru",
    "Blue Neck": "Gât Albastru",
    "Red Neck": "Gât Roșu",
    "Kenyan Red": "Kenian Roșu",
    "Zimbabwe Blue": "Zimbabwe Albastru",
    "South African Black": "Sud-African Negru",
    "Israeli Blue": "Israelian Albastru",
    "Australian Black": "Australian Negru",
    selectBirdGroups: "Selectează Grupuri de Păsări",
    noBirdGroupsAssociated: "Nu sunt grupuri de păsări asociate",
    oneBirdGroupAssociated: "1 grup de păsări asociat",
    birdGroupsAssociated: "grupuri de păsări asociate",
    assocBirdGroups: "Asociază Grupuri de Păsări",
    birds: "Păsări",
  },
}
