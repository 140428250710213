import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import NoResultsFound from "@/components/not-found/no-results-found.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import MobileTopBar from "@/components/top-bars/mobile-top-bar/mobile-top-bar.tsx"
import { HOME_SCREEN_PATH } from "@/utils/constants/routes.ts"

const NotFoundPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleBackClick = () => {
    navigate(HOME_SCREEN_PATH, { replace: true })
  }

  return (
    <>
      <MobileTopBar title={t("anErrorOccurred")} />
      <PageContentWrapperLarge className={"grid place-content-center"}>
        <NoResultsFound />
        <h1
          className={"text-center text-body-xl font-semibold text-neutral-700"}
        >
          404 - Not Found
        </h1>
        <p className={"text-center"}>{t("NotFoundMessage")}</p>
        <button
          className={
            "mx-auto mt-10 w-fit rounded-[12px] border border-neutral-600 bg-white p-4 text-base"
          }
          onClick={handleBackClick}
        >
          {t("goMainScreen")}
        </button>
      </PageContentWrapperLarge>
    </>
  )
}

export default NotFoundPage
