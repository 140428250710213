import style from "./loading-animation.module.css"
import { cn } from "@/utils/styles.ts"

interface Props {
  className?: string
}

const LoadingCircle: React.FC<Props> = ({ className }) => {
  return <span className={cn(style["loader"], className)}></span>
}

export default LoadingCircle
