import { useEffect } from "react"
import ReactGA from "react-ga4"
import { RouterProvider } from "react-router-dom"
import { useRegisterSW } from "virtual:pwa-register/react"
import { router } from "./routing/general-routes"

const App = () => {
  useRegisterSW({ immediate: true })
  const GA_ID = import.meta.env.VITE_GA_ID || "GA-XXXXX"

  useEffect(() => {
    ReactGA.initialize(GA_ID)
    ReactGA.send({ hitType: "pageview", page: window.location.pathname })
  }, [GA_ID])

  return <RouterProvider router={router} />
}

export default App
