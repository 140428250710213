import { createSlice, type PayloadAction } from "@reduxjs/toolkit"
import { type NoteDraft } from "@/features/notes"

type NoteDraftSlice = {
  drafts: Record<string, NoteDraft>
}

export const initialNoteState: NoteDraft = {
  title: "",
  content: "",
  animals: [],
  tasks: [],
  hives: [],
  queens: [],
  apiaries: [],
  birds_groups: [],
}

const initialState: NoteDraftSlice = {
  drafts: {},
}

const notesDraftSlice = createSlice({
  name: "notesDraft",
  initialState,
  reducers: {
    setNotesDraft: (
      state,
      action: PayloadAction<{ id: string; draft: NoteDraft }>,
    ) => {
      state.drafts[action.payload.id] = action.payload.draft
    },
    setDraftNoteTitle: (
      state,
      action: PayloadAction<{ id: string; title: string }>,
    ) => {
      state.drafts[action.payload.id].title = action.payload.title
    },
    setDraftNoteContent: (
      state,
      action: PayloadAction<{ id: string; content: string }>,
    ) => {
      state.drafts[action.payload.id].content = action.payload.content
    },
    setDraftAnimals: (
      state,
      action: PayloadAction<{ id: string; animals: string[] }>,
    ) => {
      state.drafts[action.payload.id].animals = action.payload.animals
    },
    setDraftTasks: (
      state,
      action: PayloadAction<{ id: string; tasks: string[] }>,
    ) => {
      state.drafts[action.payload.id].tasks = action.payload.tasks
    },
    setDraftHives: (
      state,
      action: PayloadAction<{ id: string; hives: string[] }>,
    ) => {
      state.drafts[action.payload.id].hives = action.payload.hives
    },
    setDraftApiaries: (
      state,
      action: PayloadAction<{ id: string; apiaries: string[] }>,
    ) => {
      state.drafts[action.payload.id].apiaries = action.payload.apiaries
    },
    setDraftQueens: (
      state,
      action: PayloadAction<{ id: string; queens: string[] }>,
    ) => {
      state.drafts[action.payload.id].queens = action.payload.queens
    },
    setDraftBirdsGroups: (
      state,
      action: PayloadAction<{ id: string; birds_groups: string[] }>,
    ) => {
      state.drafts[action.payload.id].birds_groups = action.payload.birds_groups
    },
    resetNotesDraft: (
      state,
      action: PayloadAction<{ id: string; draft?: Partial<NoteDraft> }>,
    ) => {
      state.drafts[action.payload.id] = {
        ...initialNoteState,
        ...action.payload.draft,
      }
    },
    deleteNotesDraft: (state, action: PayloadAction<string>) => {
      delete state.drafts[action.payload]
    },
  },
})

export const {
  setNotesDraft,
  setDraftNoteTitle,
  setDraftNoteContent,
  setDraftAnimals,
  setDraftTasks,
  setDraftApiaries,
  setDraftHives,
  setDraftQueens,
  resetNotesDraft,
  deleteNotesDraft,
  setDraftBirdsGroups,
} = notesDraftSlice.actions

export default notesDraftSlice.reducer
