import { type AnimalLifeSpan } from "@/features/farm"

/**
 * This is used to prevent the user from selecting an age range that is too large and no used in real life.
 * Used only for displaying the age range in the UI.
 * The animals can live as long as God wants.
 */
export const MAX_FILTER_AGE = 120

/**
 * Filtering is done by this even tho the max option si 120 // MAX_FILTER_AGE
 */
export const MAX_AGE = 360

export const ONE_YEAR = 12
export const THREE_YEARS = 36
export const FIVE_YEARS = 60

export const FILTER_AGE: AnimalLifeSpan = {
  from: 0,
  to: MAX_AGE,
}

export const LAMB_AGE: AnimalLifeSpan = {
  from: 0,
  to: ONE_YEAR,
}

export const MATURE_SHEEP_AGE: AnimalLifeSpan = {
  from: ONE_YEAR,
  to: MAX_AGE,
}

export const PIGLET_AGE: AnimalLifeSpan = { from: 0, to: 12 }

export const MATURE_PORK_AGE: AnimalLifeSpan = {
  from: ONE_YEAR,
  to: MAX_AGE,
}

export const CHICKEN_AGE: AnimalLifeSpan = { from: 0, to: 6 }

export const MATURE_CHICKEN_AGE: AnimalLifeSpan = {
  from: 6,
  to: MAX_AGE,
}

export const YOUNG_GOAT_AGE: AnimalLifeSpan = { from: 0, to: ONE_YEAR }

export const MATURE_GOAT_AGE: AnimalLifeSpan = {
  from: ONE_YEAR,
  to: MAX_AGE,
}

export const CALF_AGE: AnimalLifeSpan = { from: 0, to: ONE_YEAR }

export const HEIFER_AGE: AnimalLifeSpan = { from: ONE_YEAR, to: THREE_YEARS }

export const MATURE_COW_AGE: AnimalLifeSpan = {
  from: THREE_YEARS,
  to: MAX_AGE,
}
