import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { Drawer } from "vaul"
import { HamburgerIcon } from "@/assets/icons/misc/hamburger-icon.tsx"
import {
  useDeleteThreadMutation,
  useGetAllAssistantThreadsForUserQuery,
} from "@/features/assistant/api/assistant-api.ts"
import DrawerItemsGroup from "@/features/assistant/components/assistant-drawer/assistant-drawer-items-group.tsx"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"
import { AI_ASSISTANT_SCREEN_PATH } from "@/utils/constants/routes.ts"
import { groupItemsByTimeStampWithField } from "@/utils/helpers/time-grouping.ts"

interface Props {
  threadId?: string
}

export const AssistantDrawer: React.FC<Props> = ({ threadId }) => {
  const { t } = useTranslation()
  const { openModal } = useModal()
  const { data, isLoading } = useGetAllAssistantThreadsForUserQuery()
  const [deleteThread] = useDeleteThreadMutation()
  const navigate = useNavigate()

  const groupedThreads = useMemo(
    () =>
      data?.threads?.length
        ? groupItemsByTimeStampWithField(data.threads, "updated_at")
        : null,
    [data],
  )

  const handleNavigate = (path: string) => {
    navigate(path)
  }

  const handleDeleteThread = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()

    if (threadId) {
      openModal(MODALS.BASE_MODAL, {
        title: t("removeThread"),
        content: t("deleteThreadConfirmation"),
        onActionClick: async () => {
          navigate(AI_ASSISTANT_SCREEN_PATH)

          const promise = deleteThread(threadId).unwrap()
          toast.promise(promise, {
            pending: t("removingThread"),
            success: t("threadRemoved"),
            error: t("errorRemovingThread"),
          })
        },
      })
    }
  }

  return (
    <Drawer.Root direction="right">
      <Drawer.Trigger>
        <HamburgerIcon className="scale-[2] text-neutral-700" />
      </Drawer.Trigger>
      <Drawer.Portal>
        <Drawer.Overlay className="fixed inset-0 bg-black/40" />
        <Drawer.Content className="fixed bottom-0 right-0 top-0 z-50 flex w-[310px] flex-col overflow-x-hidden overflow-y-scroll bg-background outline-none">
          <Drawer.Title className="h-[60px] p-4 pb-0 text-lg font-semibold text-zinc-900">
            {t("currentChats")}
          </Drawer.Title>
          {!isLoading && groupedThreads && (
            <DrawerItemsGroup
              items={groupedThreads}
              currentThreadId={threadId}
              onNavigate={handleNavigate}
              onDelete={handleDeleteThread}
            />
          )}
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  )
}
