import { toast } from "react-toastify"
import * as Yup from "yup"
import { type HiveEventInput } from "@/features/events/hive-events/api/hive-events-api.ts"
import { type HiveEventDataTypeObject } from "@/features/events/hive-events/hooks/use-get-hive-event-data-type-object.ts"
import CustomErrorToast from "@/utils/toast-config/error-toast.tsx"

const baseEventSchema = {
  hive: Yup.string().required("hiveRequired"),
  type: Yup.string().required("eventTypeRequired"),
  person_name: Yup.string()
    .max(255, "personNameMaxLength")
    .nullable()
    .transform((value) => (value === "" ? null : value)),
  date: Yup.date()
    .nullable()
    .transform((_value, originalValue) =>
      originalValue === null ||
      originalValue === "" ||
      isNaN(new Date(originalValue).getTime())
        ? null
        : new Date(originalValue),
    )
    .required("dateRequiredEvent"),
  description: Yup.string()
    .max(1000, "descriptionMaxLength")
    .nullable()
    .transform((value) => (value === "" ? null : value)),
  notes: Yup.string()
    .max(1000, "notesMaxLength")
    .nullable()
    .transform((value) => (value === "" ? null : value)),
}

const movementDataSchema = Yup.object().shape({
  from_position: Yup.string().required("fromLocationRequired"),
  to_position: Yup.string()
    .required("toLocationRequired")
    .test(
      "different-locations",
      "fromAndToPositionMustBeDifferent",
      function (value) {
        const { from_position } = this.parent
        return from_position !== value
      },
    ),
})

const measurementDataSchema = Yup.object().shape({
  value: Yup.string().required("valueRequired"),
})

const treatmentDataSchema = Yup.object().shape({
  medication: Yup.string().required("medicationRequired"),
  dosage: Yup.string().required("dosageRequired"),
})

const equipmentDataSchema = Yup.object().shape({
  quantity: Yup.string().required("quantityRequired"),
})

const apiaryMovementDataSchema = Yup.object().shape({
  to_apiary: Yup.string().required("toApiaryRequired"),
})

const getHiveEventSchema = (eventDataType: HiveEventDataTypeObject) => {
  const selectedEventDataSchema = Yup.lazy(() => {
    if (eventDataType.isApiaryMovementEvent) {
      return apiaryMovementDataSchema
    }
    if (eventDataType.isMovementEvent) {
      return movementDataSchema
    }
    if (eventDataType.isMeasurementEvent) {
      return measurementDataSchema
    }
    if (eventDataType.isTreatmentEvent) {
      return treatmentDataSchema
    }
    if (eventDataType.isEquipmentEvent) {
      return equipmentDataSchema
    }
    return Yup.mixed().notRequired()
  })

  return Yup.object().shape({
    ...baseEventSchema,
    event_data: selectedEventDataSchema,
  })
}

export const validateHiveEvent = async (
  hiveEvent: Partial<HiveEventInput> = {},
  hiveEventDataType: HiveEventDataTypeObject,
) => {
  const schema = getHiveEventSchema(hiveEventDataType)

  try {
    await schema.validate(hiveEvent, { abortEarly: false })
    return true
  } catch (error) {
    if (error instanceof Yup.ValidationError) {
      toast.error(<CustomErrorToast errors={error.errors} />)
    }
    return false
  }
}
