import React, { useEffect, type ChangeEvent } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useParams } from "react-router-dom"
import { ColorPalleteIcon } from "@/assets/icons/bees/color-pallete-icon.tsx"
import DetailsIcon from "@/assets/icons/bees/details-icon.tsx"
import HiveIcon from "@/assets/icons/bees/hive-icon.tsx"
import { MonthCalendarIcon } from "@/assets/icons/bees/month-calendar-icon.tsx"
import QueenIcon from "@/assets/icons/bees/queen-icon.tsx"
import { TagIcon } from "@/assets/icons/bees/tag-icon.tsx"
import { HamburgerIcon } from "@/assets/icons/misc/hamburger-icon.tsx"
import { AttachImageFieldBase64 } from "@/components/attach-image-field/attach-image-field-base-64.tsx"
import { SelectionRowDrawer } from "@/components/selection-row-drawer/selection-row-drawer.tsx"
import { DrawerTrigger } from "@/components/shadcn/drawer.tsx"
import Skeleton from "@/components/skeleton/skeleton.tsx"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item.tsx"
import InputFieldWrapperWithIcon from "@/components/text-card-wrapper-with-icon/input-field-wrapper-with-icon.tsx"
import TextAreaFieldWrapperWithIcon from "@/components/text-card-wrapper-with-icon/text-area-field-wrapper-with-icon.tsx"
import { useGetAllHivesQuery } from "@/features/bees/api/hives-api.ts"
import {
  QueenColors,
  queenOriginOptions,
  type QueenInput,
} from "@/features/bees/types/queens.ts"
import { queenColorBackground } from "@/features/bees/utils/queen-utils.ts"
import RequiredIndicator from "@/features/farm/components/required-indicator/required-indicator.tsx"
import { useAppDispatch } from "@/redux/hooks.ts"
import {
  setDraftQueenColor,
  setDraftQueenDescription,
  setDraftQueenName,
  setDraftQueenOrigin,
  setDraftQueenPicture,
  setDraftQueenYear,
  setQueenDraftHive,
} from "@/redux/slices/queen-draft-slice.ts"
import { cn } from "@/utils/styles.ts"

interface Props {
  queenId: string
  draftQueen: QueenInput
}

export const QueenEditor: React.FC<Props> = ({ queenId, draftQueen }) => {
  const { t } = useTranslation()
  const { hiveId } = useParams()
  const dispatch = useAppDispatch()
  const {
    data: hives,
    isLoading: isLoadingHives,
    isFetching: isFetchingHives,
  } = useGetAllHivesQuery()
  const hiveOptions =
    hives
      ?.filter((hive) => !hive.queen)
      .map((hive) => ({
        label: hive.code,
        value: hive.id,
      })) || []

  const location = useLocation()
  const { assocHive } =
    (location.state as {
      assocHive?: boolean
    }) || {}

  const [hiveDrawerOpen, setHiveDrawerOpen] = React.useState(false)

  useEffect(() => {
    if (assocHive) {
      setHiveDrawerOpen(true)
    }
  }, [assocHive])

  const currentHive = hives?.find(
    (hive) => hive.id === hiveId || hive.id === draftQueen.hive,
  )

  useEffect(() => {
    if (hiveId) {
      dispatch(
        setQueenDraftHive({
          id: queenId,
          hive: hiveId,
        }),
      )
    }
  }, [dispatch, hiveId, queenId])

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setDraftQueenName({
        id: queenId,
        name: event.target.value,
      }),
    )
  }

  const handleYearChange = (event: ChangeEvent<HTMLInputElement>) => {
    const year = event.target.value
    let color: QueenColors

    switch (Number(year) % 10) {
      case 0:
      case 5:
        color = QueenColors.Blue
        break
      case 1:
      case 6:
        color = QueenColors.White
        break
      case 2:
      case 7:
        color = QueenColors.Yellow
        break
      case 3:
      case 8:
        color = QueenColors.Red
        break
      case 4:
      case 9:
        color = QueenColors.Green
        break
      default:
        color = "" as QueenColors
        break
    }

    if (!year) {
      color = "" as QueenColors
    }

    dispatch(
      setDraftQueenYear({
        id: queenId,
        year,
      }),
    )
    dispatch(
      setDraftQueenColor({
        id: queenId,
        color,
      }),
    )
  }

  const handleOriginChange = (origin: string) => {
    dispatch(
      setDraftQueenOrigin({
        id: queenId,
        origin,
      }),
    )
  }

  const handleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(
      setDraftQueenDescription({
        id: queenId,
        description: event.target.value,
      }),
    )
  }

  const handlePictureChange = (picture: string | null) => {
    dispatch(
      setDraftQueenPicture({
        id: queenId,
        picture,
      }),
    )
  }

  const handleHiveChange = (hive: string) => {
    dispatch(
      setQueenDraftHive({
        id: queenId,
        hive,
      }),
    )
  }

  return (
    <div className={"flex flex-col gap-[10px] pb-10"}>
      <InputFieldWrapperWithIcon
        icon={
          <div
            className={
              "grid h-[30px] w-[30px] place-content-center rounded-[4px] bg-yellow-300 text-transparent"
            }
          >
            <TagIcon />
          </div>
        }
        align={"row"}
        isDisabled={false}
        name={t("name")}
        isRequired={!draftQueen.name}
        onChange={handleNameChange}
        value={draftQueen.name}
      />
      {!isFetchingHives && !isLoadingHives && hiveOptions.length === 0 && (
        <div className={"mt-2 text-[14px] text-neutral-400"}>
          {t("noHivesAvailable")}
        </div>
      )}
      {!isLoadingHives && !isFetchingHives ? (
        <OverflowMenuItem isDisabled={!!hiveId || hiveOptions.length === 0}>
          <SelectionRowDrawer
            multiple={false}
            renderTrigger={
              <DrawerTrigger>
                <div
                  className={"grid grid-cols-[30px_auto] items-center gap-2"}
                >
                  <div
                    className={
                      "grid h-[30px] w-[30px] place-content-center rounded-[4px] bg-blue-300 text-white"
                    }
                  >
                    <HiveIcon className={"scale-150"} />
                  </div>
                  <div className={"flex w-full justify-between"}>
                    {currentHive ? (
                      <span className={"text-[14px]"}>{currentHive.code}</span>
                    ) : (
                      <span className={"text-[14px] text-neutral-700"}>
                        {t("hive")}
                      </span>
                    )}
                    <HamburgerIcon className={"scale-150"} />
                  </div>
                </div>
              </DrawerTrigger>
            }
            open={hiveDrawerOpen}
            setOpen={setHiveDrawerOpen}
            title={t("hive")}
            options={hiveOptions}
            selectedValues={draftQueen.hive || ""}
            onSelectionChange={handleHiveChange}
          />
        </OverflowMenuItem>
      ) : (
        <Skeleton className="relative flex flex-col gap-2.5 rounded-[15px] border border-neutral-200" />
      )}

      <OverflowMenuItem>
        <SelectionRowDrawer
          multiple={false}
          renderTrigger={
            <DrawerTrigger>
              <div className={"grid grid-cols-[30px_auto] items-center gap-2"}>
                <div
                  className={
                    "grid h-[30px] w-[30px] place-content-center rounded-[4px] bg-red-300 text-white"
                  }
                >
                  <QueenIcon />
                  {!draftQueen.origin && (
                    <RequiredIndicator className={"top-0"} />
                  )}
                </div>
                <div className={"flex w-full justify-between"}>
                  {draftQueen.origin ? (
                    <span className={"text-[14px]"}>
                      {t(draftQueen.origin)}
                    </span>
                  ) : (
                    <span className={"text-[14px] text-[var(--grey)]"}>
                      {t("queenOrigin")}
                    </span>
                  )}
                  <HamburgerIcon className={"scale-150"} />
                </div>
              </div>
            </DrawerTrigger>
          }
          options={queenOriginOptions}
          selectedValues={draftQueen.origin}
          onSelectionChange={handleOriginChange}
          title={t("queenOrigin")}
        />
      </OverflowMenuItem>
      <InputFieldWrapperWithIcon
        icon={
          <div
            className={
              "grid h-[30px] w-[30px] place-content-center rounded-[4px] bg-green-500 text-transparent"
            }
          >
            <MonthCalendarIcon />
          </div>
        }
        align={"row"}
        inputMode={"numeric"}
        isDisabled={false}
        name={t("yearOfBirth")}
        onChange={handleYearChange}
        value={draftQueen.year}
      />
      <OverflowMenuItem>
        <div className={"flex items-center gap-2"}>
          <div
            className={cn(
              "grid h-[30px] w-[30px] place-content-center rounded-[4px] bg-emerald-600 text-transparent",
              queenColorBackground(draftQueen.color || ""),
            )}
          >
            <ColorPalleteIcon />
          </div>
          {draftQueen.color ? (
            <span className={"text-[14px]"}>{t(draftQueen.color)}</span>
          ) : (
            <span className={"text-[14px] text-[var(--grey)]"}>
              {t("queenColor")}
            </span>
          )}
        </div>
      </OverflowMenuItem>
      <TextAreaFieldWrapperWithIcon
        icon={
          <div
            className={
              "grid h-[30px] w-[30px] place-content-center rounded-[4px] bg-[var(--menu-financial-journal-color)] text-white"
            }
          >
            <DetailsIcon className={"scale-150"} />
          </div>
        }
        name={t("details")}
        onChange={handleDescriptionChange}
        value={draftQueen.description}
        isReadOnly={false}
      />
      <AttachImageFieldBase64
        picture={draftQueen.picture}
        onChoosePicture={handlePictureChange}
      />
    </div>
  )
}
