import React from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { RemoveQueenIcon } from "@/assets/icons/bees/remove-queen-icon.tsx"
import { useUpdateQueenMutation } from "@/features/bees/api/queens-api.ts"
import { type QueenResponse } from "@/features/bees/types/queens.ts"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"

interface Props {
  queen: QueenResponse
}

export const RemoveQueenButton: React.FC<Props> = ({ queen }) => {
  const { t } = useTranslation()
  const { openModal } = useModal()
  const [updateQueenMutation] = useUpdateQueenMutation()

  const handleQueenRemoval = () => {
    openModal(MODALS.BASE_MODAL, {
      title: t("removeQueenFromHive"),
      content: t("removeQueenFromHiveConfirmation"),
      onActionClick: removeQueenFromHive,
    })
  }

  const removeQueenFromHive = () => {
    if (queen) {
      const promise = updateQueenMutation({
        hiveId: queen.hive,
        queenId: queen.id,
        // @ts-ignore
        queen: { ...queen, hive: null },
      }).unwrap()
      toast.promise(promise, {
        pending: t("removingQueen"),
        success: t("queenRemovedFromHive"),
        error: t("errorRemovingQueenFromHive"),
      })
    }
  }

  return (
    <div
      onClick={handleQueenRemoval}
      className={
        "fp-border flex h-[60px] items-center justify-center gap-2 rounded-[15px] bg-white"
      }
    >
      <RemoveQueenIcon className={"h-5 w-5 text-neutral-400"} />
      <p className={"text-sm text-neutral-700"}>{t("removeQueenFromHive")}</p>
    </div>
  )
}
