import isEqual from "lodash.isequal"
import { useTranslation } from "react-i18next"
import { useParams, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import DeleteButton from "@/components/delete-button/delete-button.tsx"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import EditorTopBar from "@/components/top-bars/editor-top-bar/editor-top-bar.tsx"
import {
  useDeleteApiaryEventMutation,
  useUpdateApiaryEventMutation,
} from "@/features/events/apiary-events/api/apiary-events-api.ts"
import { ApiaryEventEditor } from "@/features/events/apiary-events/components/apiary-event-editor/apiary-event-editor.tsx"
import useCheckApiaryEventType from "@/features/events/apiary-events/hooks/use-check-apiary-event-type.ts"
import useGetDraftApiaryEvent from "@/features/events/apiary-events/hooks/use-get-draft-apiary-event.ts"
import { validateApiaryEvent } from "@/features/events/apiary-events/types/apiary-event-schema.tsx"
import { APIARY_EVENT_TYPES_ENUM } from "@/features/events/apiary-events/types/apiary-events-constants.ts"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"
import { useAppDispatch } from "@/redux/hooks.ts"
import { resetApiaryEventDraft } from "@/redux/slices/apiary-events-slice.ts"

export const ApiaryEventView = () => {
  const { apiaryEventId } = useParams()
  const dispatch = useAppDispatch()
  const { openModal } = useModal()
  const { t } = useTranslation()
  const [deleteApiaryEventMutation] = useDeleteApiaryEventMutation()
  const [updateApiaryEventMutation] = useUpdateApiaryEventMutation()

  const navigate = useNavigate()
  const { draftEvent, data, isLoading } = useGetDraftApiaryEvent(apiaryEventId)
  const hasChanges = !!data && !isEqual(draftEvent, data)
  const checkEventType = useCheckApiaryEventType()

  const isMovementEvent = checkEventType(
    draftEvent.type,
    APIARY_EVENT_TYPES_ENUM.ApiaryMovement,
  )

  const isPastoralEvent = checkEventType(
    draftEvent.type,
    APIARY_EVENT_TYPES_ENUM.PastoralMovement,
  )

  const handleUpdate = async () => {
    if (
      (await validateApiaryEvent(
        draftEvent,
        isMovementEvent,
        isPastoralEvent,
      )) &&
      apiaryEventId
    ) {
      toast
        .promise(
          updateApiaryEventMutation({
            id: apiaryEventId,
            ...draftEvent,
          }).unwrap(),
          {
            pending: t("updatingAnimalEvent"),
            success: t("animalEventUpdated"),
            error: t("animalEventUpdateError"),
          },
        )
        .then(() => navigate(-1))
    }
  }

  const deleteApiaryEvent = () => {
    if (apiaryEventId) {
      toast
        .promise(deleteApiaryEventMutation(apiaryEventId).unwrap(), {
          pending: t("deletingApiaryEvent"),
          success: t("apiaryEventDeleted"),
          error: t("apiaryEventDeleteError"),
        })
        .then(() => {
          navigate(-1)

          dispatch(resetApiaryEventDraft({}))
        })
    }
  }

  const handleDelete = () => {
    openModal(MODALS.BASE_MODAL, {
      title: t("deleteAnimalEventConfirmation"),
      content: t("eventWillBeDeleted"),
      onActionClick: deleteApiaryEvent,
    })
  }

  return (
    <>
      <EditorTopBar
        text={"apiaryEvent"}
        isModified={hasChanges}
        onSave={handleUpdate}
      />
      <PageContentWrapperLarge>
        {isLoading || !draftEvent ? (
          <LoadingAnimation />
        ) : (
          <>
            <ApiaryEventEditor
              eventId={apiaryEventId}
              draftApiaryEvent={draftEvent}
            />
            <div className={"mt-10"}>
              <DeleteButton
                text={t("deleteAnimalEvent")}
                onClick={handleDelete}
              />
            </div>
          </>
        )}
      </PageContentWrapperLarge>
    </>
  )
}
