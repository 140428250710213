import { skipToken } from "@reduxjs/toolkit/query"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import EditIcon from "@/assets/icons/misc/edit-icon.tsx"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import NoResultsFound from "@/components/not-found/no-results-found.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import MobileTopBar from "@/components/top-bars/mobile-top-bar/mobile-top-bar.tsx"
import { useGetBirdsGroupByIdQuery } from "@/features/birds-groups/api/birds-groups-api.ts"
import { BirdsGroupDetails } from "@/features/birds-groups/components/birds-group-details/birds-group-details.tsx"
import TasksWidget from "@/features/home/components/tasks-widget/tasks-widget.tsx"
import NotesWidget from "@/features/notes/components/notes-widget/notes-widget.tsx"
import { BIRDS_GROUPS_SCREEN_PATH } from "@/utils/constants/routes.ts"

const BirdsGroupView = () => {
  const { t } = useTranslation()
  const { birdGroupId } = useParams()
  const {
    data: birdGroup,
    isLoading,
    isError,
  } = useGetBirdsGroupByIdQuery(birdGroupId ?? skipToken)
  const title = birdGroup?.name || t("Loading")
  const editBirdGroupViewNavigationString =
    birdGroup && `${BIRDS_GROUPS_SCREEN_PATH}/${birdGroup.id}/edit`

  return (
    <>
      <MobileTopBar
        title={title}
        icon={<EditIcon />}
        navigateTo={editBirdGroupViewNavigationString}
      />
      <PageContentWrapperLarge className={"flex flex-col gap-2.5"}>
        {!isError && !isLoading && birdGroup && (
          <>
            <BirdsGroupDetails birdsGroup={birdGroup} />
            <NotesWidget notes={birdGroup.notes} birdGroupId={birdGroupId} />
            <TasksWidget tasks={birdGroup.tasks} birdGroupId={birdGroupId} />
          </>
        )}
        {isLoading && <LoadingAnimation />}
        {isError && (
          <NoResultsFound className={"h-full"} message={t("anErrorOccurred")} />
        )}
      </PageContentWrapperLarge>
    </>
  )
}

export default BirdsGroupView
