interface Props {
  entities: string[]
  children?: React.ReactNode
}

export const AssocEntityPill: React.FC<Props> = ({ entities, children }) => {
  return (
    <div
      className={
        "box-border grid h-[30px] w-fit max-w-full grid-cols-[1fr_25px] items-center rounded-[15px] bg-[var(--background-color)] pl-2.5 pr-[5px]"
      }
    >
      <div className={"mx-1 min-w-4 truncate text-[12px]"}>
        {entities.length}
      </div>
      {children}
    </div>
  )
}
