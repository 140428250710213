import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import Skeleton from "@/components/skeleton/skeleton.tsx"
import { WidgetButton } from "@/components/widget-button/widget-button.tsx"
import { useGetAllHivesQuery } from "@/features/bees/api/hives-api.ts"
import { HiveCardLong } from "@/features/bees/components/hive-card/hive-card-long.tsx"
import { RemoveQueenButton } from "@/features/bees/components/remove-queen-button/remove-queen-button.tsx"
import { type QueenResponse } from "@/features/bees/types/queens.ts"
import WidgetCard from "@/features/home/components/widget-card/widget-card.tsx"
import { ALL_QUEENS_ROUTE, NEW_HIVE_ROUTE } from "@/utils/constants/routes.ts"

interface Props {
  queen: QueenResponse
}

export const HiveWidget: React.FC<Props> = ({ queen }) => {
  const {
    data: hives,
    isLoading: isLoadingHives,
    isFetching: isFetchingHives,
  } = useGetAllHivesQuery()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const hive = hives?.find((hive) => hive.id === queen.hive)
  const noQueenHives = hives?.filter((hive) => !hive.queen)

  const isLoading = isLoadingHives || isFetchingHives
  const isHiveReady = !isLoading && hive
  const noHiveAndNoAvailableHives =
    !hive && noQueenHives && noQueenHives.length === 0
  const noHiveAndHivesAvailable =
    !hive && noQueenHives && noQueenHives.length > 0

  return (
    <WidgetCard title={"hive"} navigateTo={undefined} addItemGoTo={undefined}>
      {isLoading && <Skeleton className={"widget-border h-24 w-full"} />}
      {isHiveReady && (
        <>
          <HiveCardLong hive={hive} />
          <RemoveQueenButton queen={queen} />
        </>
      )}
      {noHiveAndNoAvailableHives && (
        <>
          <div className={"mt-2 text-[14px] text-neutral-700"}>
            {t("queenHasNoHiveAssignedAndNoAvailableHives")}
          </div>
          <WidgetButton
            title={t("addHive")}
            onClick={() => navigate(`${NEW_HIVE_ROUTE}?queenId=${queen.id}`)}
          />
        </>
      )}
      {noHiveAndHivesAvailable && (
        <>
          <div className="mt-2 text-[14px] text-neutral-700">
            {t("queenHasNoHiveAndHivesAvailable")}
          </div>
          <div className={"flex flex-col gap-2"}>
            <WidgetButton
              title={t("addHive")}
              onClick={() => navigate(`${NEW_HIVE_ROUTE}?queenId=${queen.id}`)}
            />
            <WidgetButton
              title={t("assocHive")}
              onClick={() =>
                navigate(`${ALL_QUEENS_ROUTE}/${queen.id}/edit`, {
                  state: {
                    assocHive: true,
                  },
                })
              }
            />
          </div>
        </>
      )}
    </WidgetCard>
  )
}
