import { skipToken } from "@reduxjs/toolkit/query"
import { useTranslation } from "react-i18next"
import { useLocation, useParams, useNavigate } from "react-router-dom"
import EditIcon from "@/assets/icons/misc/edit-icon.tsx"
import genericHiveImg from "@/assets/images/generic_hive.webp"
import GenericPicture from "@/components/generic-picture/generic-picture.tsx"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import MobileTopBar from "@/components/top-bars/mobile-top-bar/mobile-top-bar.tsx"
import { useGetHiveByIdQuery } from "@/features/bees/api/hives-api.ts"
import { useGetQueenByIdQuery } from "@/features/bees/api/queens-api.ts"
import HiveDetails from "@/features/bees/components/hive-details/hive-details.tsx"
import { QueenWidget } from "@/features/bees/components/queen-widget/queen-widget.tsx"
import { HiveEventsWidget } from "@/features/events/hive-events/components/hive-events-widget/hive-events-widget.tsx"
import TasksWidget from "@/features/home/components/tasks-widget/tasks-widget.tsx"
import NotesWidget from "@/features/notes/components/notes-widget/notes-widget.tsx"
import {
  ALL_HIVES_PATH,
  APIARIES_SCREEN_PATH,
} from "@/utils/constants/routes.ts"

const HiveView = () => {
  const { t } = useTranslation()
  const { apiaryId, hiveId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { data: hive } = useGetHiveByIdQuery(hiveId ? hiveId : skipToken)
  const queenId = hive?.queen ? hive?.queen.id : ""
  const { data: queen } = useGetQueenByIdQuery(queenId || skipToken)

  const title = hive?.code || t("isLoading")
  const editHiveNavigationString = `${APIARIES_SCREEN_PATH}/${apiaryId}${ALL_HIVES_PATH}/${hiveId}/edit`

  const handleBackClick = () => {
    const isPrevANewRoute = location.state?.previousPath?.includes("new")
    const isPrevQueenView = location.state?.previousPath?.includes("queen")

    if (isPrevANewRoute || isPrevQueenView) {
      navigate(`${APIARIES_SCREEN_PATH}/${apiaryId}`, {
        state: { previousPath: location.pathname },
      })
    } else {
      navigate(-1)
    }
  }

  return (
    <>
      <MobileTopBar
        title={title}
        navigateTo={editHiveNavigationString}
        icon={<EditIcon />}
        onBackClick={handleBackClick}
      />
      <PageContentWrapperLarge className={"flex flex-col gap-4 p-4"}>
        {hive ? (
          <>
            <GenericPicture
              src={hive?.picture || genericHiveImg}
              alt={t("hiveImage")}
              borderRadius={12}
              height={200}
            />
            {hive && <HiveDetails hive={hive} queen={queen} />}
            <QueenWidget queen={queen} />
            <TasksWidget tasks={hive?.tasks} hiveId={hive?.id} />
            <NotesWidget notes={hive?.notes} hiveId={hive?.id} />
            <HiveEventsWidget hiveId={hive.id} />
          </>
        ) : (
          <LoadingAnimation />
        )}
      </PageContentWrapperLarge>
    </>
  )
}

export default HiveView
