import {
  differenceInCalendarDays,
  format,
  isFuture,
  isPast,
  isToday,
} from "date-fns"
import { t } from "i18next"
import { convertMonthsToYearsAndMonths } from "@/features/farm"

export function getAgeFromDateOfBirth(dob: string) {
  const monthsSinceBirth = getMonthsFromDateOfBirth(dob)
  if (monthsSinceBirth === 0) return t("lessThanAMonth")
  return convertMonthsToYearsAndMonths(monthsSinceBirth)
}

export const getMonthsFromDateOfBirth = (dob: string) => {
  const birthDate = new Date(dob)
  const currentDate = new Date()

  let years = currentDate.getFullYear() - birthDate.getFullYear()
  let months = currentDate.getMonth() - birthDate.getMonth()
  let totalMonths = years * 12 + months

  if (currentDate.getDate() < birthDate.getDate()) {
    totalMonths--
  }

  return Number(totalMonths)
}

// changed to ro-Ro since Tudor said that at least for demo period all should be in ro
export function formatDateTimeToRo(dateString: string | undefined): string {
  const date = new Date(dateString || "")
  if (isNaN(date.getTime())) {
    return ""
  }

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  }

  return date.toLocaleDateString("ro-RO", options)
}

export function elapsedTime(date: string): string {
  const now = new Date()
  const past = new Date(date)
  const diff = Math.floor((now.getTime() - past.getTime()) / 1000)

  const seconds = diff % 60
  const minutes = Math.floor(diff / 60) % 60
  const hours = Math.floor(diff / 3600) % 24
  const days = Math.floor(diff / 86400)

  if (days > 0) {
    return `${days}d`
  } else if (hours > 0) {
    return `${hours}h`
  } else if (minutes > 0) {
    return `${minutes}m`
  } else {
    return `${seconds}s`
  }
}

export function getAppVersionDateFormat(date: string) {
  const localDate: Date = new Date(date)
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  }
  return localDate.toLocaleString("en-GB", options)
}

export const stringifyCustomTimePeriod = (
  startDate: Date | undefined,
  endDate: Date | undefined,
) => {
  if (startDate && endDate) {
    return `${format(startDate, "dd-MM-yyyy")} - ${format(endDate, "dd-MM-yyyy")}`
  }
}

export const getDayElapsedPercentageBetweenPastDateAndFutureDate = (
  startDate: string,
  endDate: string,
) => {
  if (isPast(endDate) && isPast(startDate)) {
    return
  }

  if (isFuture(endDate) && isFuture(startDate)) {
    return
  }

  if (isToday(endDate) && isToday(startDate)) {
    return
  }

  const timeInDaysBetweenDates = differenceInCalendarDays(
    new Date(endDate),
    new Date(startDate),
  )

  if (timeInDaysBetweenDates === 0) {
    return 100
  }

  const timeElapsed = differenceInCalendarDays(new Date(), new Date(startDate))

  return Math.round(
    ((timeInDaysBetweenDates - timeElapsed) / timeInDaysBetweenDates) * 100,
  )
}
