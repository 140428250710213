import React from "react"
import WidgetHeader from "@/features/home/components/widget-header/widget-header.tsx"

interface WidgetCardProps {
  title: string
  children: React.ReactNode
  navigateTo?: string
  addItemGoTo?: () => void
  customStyles?: React.CSSProperties
  icon?: React.ReactNode
  testId?: string
}

const WidgetCard: React.FC<WidgetCardProps> = ({
  title,
  children,
  navigateTo,
  addItemGoTo,
  customStyles,
  icon,
  testId,
}) => {
  return (
    <div
      className={"widget-border box-border flex flex-col gap-5 bg-white p-4"}
      style={customStyles}
      data-testid={testId}
    >
      <WidgetHeader
        title={title}
        navigateTo={navigateTo}
        addItemGoTo={addItemGoTo}
        icon={icon}
      />
      {children}
    </div>
  )
}

export default WidgetCard
