import { toast } from "react-toastify"
import * as Yup from "yup"
import { HiveType, type HiveInput } from "@/features/bees/types/hives.ts"
import CustomErrorToast from "@/utils/toast-config/error-toast.tsx"

export const hiveSchema = Yup.object().shape({
  apiary: Yup.string()
    .required("apiaryRequired")
    .matches(/^[A-Za-z0-9-]+$/, "apiaryFormat"),

  code: Yup.string()
    .required("codeRequired")
    .matches(/^[A-Za-z0-9-]+$/, "codeFormat")
    .min(2, "codeMinLength")
    .max(50, "codeMaxLength"),

  hive_type: Yup.string()
    .required("hiveTypeRequired")
    .oneOf(Object.values(HiveType), "invalidHiveType"),

  number_of_brood_frames: Yup.string()
    .required("broodFramesRequired")
    .matches(/^\d+$/, "broodFramesFormat")
    .test("broodFramesRange", "broodFramesRange", (value) => {
      const num = parseInt(value || "0")
      return num >= 0 && num <= 100
    }),

  number_of_honey_frames: Yup.string()
    .required("honeyFramesRequired")
    .matches(/^\d+$/, "honeyFramesFormat")
    .test("honeyFramesRange", "honeyFramesRange", (value) => {
      const num = parseInt(value || "0")
      return num >= 0 && num <= 100
    }),

  number_of_empty_frames: Yup.string()
    .required("emptyFramesRequired")
    .matches(/^\d+$/, "emptyFramesFormat")
    .test("emptyFramesRange", "emptyFramesRange", (value) => {
      const num = parseInt(value || "0")
      return num >= 0 && num <= 100
    }),

  bee_count: Yup.string()
    .required("beeCountRequired")
    .matches(/^\d+$/, "beeCountFormat")
    .test("beeCountRange", "beeCountRange", (value) => {
      const num = parseInt(value || "0")
      return num >= 0 && num <= 100000
    }),

  description: Yup.string().max(1000, "descriptionMaxLength").nullable(),
  current_position: Yup.string()
    .required()
    .max(255, "positionMaxLength")
    .nullable(),
  picture: Yup.string()
    .nullable()
    .transform((value) => (value === "" ? null : value)),
})

export const validateHive = async (hive: Partial<HiveInput> = {}) => {
  try {
    await hiveSchema.validate(hive, { abortEarly: false })
    return true
  } catch (error) {
    if (error instanceof Yup.ValidationError) {
      toast.error(<CustomErrorToast errors={error.errors} />)
    }
    return false
  }
}
