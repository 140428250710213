import React from "react"
import DeleteIcon from "@/assets/icons/misc/delete-icon.tsx"
import GenericItemsGroup from "@/components/grouping/generic-items-group.tsx"
import { DrawerClose } from "@/components/shadcn/drawer.tsx"
import { AI_ASSISTANT_SCREEN_PATH } from "@/utils/constants/routes.ts"
import {
  type ItemsArray,
  type ItemsTimeGroups,
} from "@/utils/helpers/time-grouping.ts"
import { cn } from "@/utils/styles.ts"

interface DrawerItemProps {
  item: ItemsArray[number]
  currentThreadId?: string
  onNavigate: (threadId: string) => void
  onDelete: (event: React.MouseEvent<HTMLDivElement>) => void
}

const DrawerItem: React.FC<DrawerItemProps> = ({
  item,
  currentThreadId,
  onNavigate,
  onDelete,
}) => {
  const baseClassName = "w-full text-left rounded-sm border"
  const isCurrentThread = currentThreadId === item.id
  const className = cn(
    baseClassName,
    isCurrentThread &&
      "grid grid-cols-[auto,45px] items-center bg-zinc-100 text-zinc-900",
  )

  return (
    <DrawerClose
      className={className}
      onClick={() => onNavigate(`${AI_ASSISTANT_SCREEN_PATH}/${item.id}`)}
    >
      <p className="truncate p-3">{"title" in item ? item.title : null}</p>
      {isCurrentThread && (
        <div
          onClick={onDelete}
          className="flex h-[40px] w-[40px] place-content-center items-center justify-end pr-2"
        >
          <DeleteIcon className="scale-75 text-red-500" />
        </div>
      )}
    </DrawerClose>
  )
}

interface DrawerItemsGroupProps {
  items: ItemsTimeGroups
  currentThreadId?: string
  onNavigate: (path: string) => void
  onDelete: (event: React.MouseEvent<HTMLDivElement>) => void
}

const DrawerItemsGroup: React.FC<DrawerItemsGroupProps> = ({
  items,
  currentThreadId,
  onNavigate,
  onDelete,
}) => {
  const ItemComponent: React.FC<{ item: ItemsArray[number] }> = ({ item }) => (
    <DrawerItem
      item={item}
      currentThreadId={currentThreadId}
      onNavigate={onNavigate}
      onDelete={onDelete}
    />
  )

  return (
    <div className="h-[calc(100% - 60px)] mb-2 flex flex-col p-4 pt-0 text-zinc-600">
      <GenericItemsGroup items={items} ItemComponent={ItemComponent} />
    </div>
  )
}

export default DrawerItemsGroup
