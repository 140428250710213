import React from "react"
import { useTranslation } from "react-i18next"
import TreatmentsCountIcon from "@/assets/icons/ruminant-details/treatments-count-icon.tsx"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item.tsx"
import InputFieldWrapperWithIcon from "@/components/text-card-wrapper-with-icon/input-field-wrapper-with-icon.tsx"
import { useGetAllMedicationTypesQuery } from "@/features/events/common/api/animal-medication-types-api.ts"
import { HiveMedicationTypeDrawer } from "@/features/events/hive-events/components/hive-medication-drawer/hive-medication-drawer.tsx"
import { useAppDispatch } from "@/redux/hooks.ts"
import {
  setHiveDraftEventSubDataDosage,
  setHiveDraftEventSubDataMedication,
  type HiveEventDraft,
} from "@/redux/slices/hive-events-draft-slice.ts"

interface Props {
  draftEvent: HiveEventDraft
}

const HiveEventTreatmentEditor: React.FC<Props> = ({ draftEvent }) => {
  const { data: medications } = useGetAllMedicationTypesQuery()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const setMedication = (medication: string | undefined) => {
    dispatch(setHiveDraftEventSubDataMedication(medication))
  }

  const setDosage = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setHiveDraftEventSubDataDosage(event.target.value))
  }

  return (
    <>
      <div className={"mt-2 text-[14px] text-neutral-400"}>
        {t("specificEventDetails")}
      </div>
      <OverflowMenuItem>
        {medications && (
          <HiveMedicationTypeDrawer
            selectedValue={draftEvent.event_data?.medication}
            onEventSelect={setMedication}
            medications={medications}
            fallbackText={"selectMedication"}
          />
        )}
      </OverflowMenuItem>
      <InputFieldWrapperWithIcon
        icon={<TreatmentsCountIcon />}
        align={"row"}
        name={t("dosage")}
        maxLength={14}
        inputMode={"numeric"}
        isDisabled={!draftEvent.event_data?.medication}
        value={draftEvent.event_data?.dosage}
        isRequired={true}
        onChange={setDosage}
      />
    </>
  )
}

export default HiveEventTreatmentEditor
