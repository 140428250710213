import { useCallback, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useParams, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import EditorTopBar from "@/components/top-bars/editor-top-bar/editor-top-bar.tsx"
import { useCreateQueenMutation } from "@/features/bees/api/queens-api.ts"
import { QueenEditor } from "@/features/bees/components/queen-editor/queen-editor.tsx"
import { useInitializeQueenDraft } from "@/features/bees/hooks/use-initialize-queen-draft.ts"
import { validateQueen } from "@/features/bees/types/queen-schema.tsx"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"
import { deleteQueenDraft } from "@/redux/slices/queen-draft-slice.ts"
import {
  ALL_HIVES_PATH,
  ALL_QUEENS_ROUTE,
  APIARIES_SCREEN_PATH,
} from "@/utils/constants/routes.ts"
import { DELETE_ENTRY_TIMEOUT } from "@/utils/constants/time-constants.ts"
import { NEW_ENTITY } from "@/utils/types/misc-types.ts"

export const NewQueenView = () => {
  const { t } = useTranslation()
  const { queenDraft, hasChanges } = useInitializeQueenDraft()
  const { apiaryId, hiveId } = useParams()
  const { openModal } = useModal()
  const [createQueenMutation] = useCreateQueenMutation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const deleteDraft = useCallback(() => {
    setTimeout(() => {
      dispatch(deleteQueenDraft(NEW_ENTITY))
    }, DELETE_ENTRY_TIMEOUT)
  }, [dispatch])

  const handleQueenCreation = async () => {
    if (!(await validateQueen(queenDraft))) {
      return
    }
    const promise = createQueenMutation(queenDraft).unwrap()
    await toast
      .promise(promise, {
        pending: t("creatingQueen"),
        success: t("queenCreated"),
        error: t("queenCreateError"),
      })
      .then((response) => {
        setTimeout(() => {
          const routeWithHive = `${APIARIES_SCREEN_PATH}/${apiaryId}${ALL_HIVES_PATH}/${hiveId}/queen/${response.id}/`
          const routeWithoutHive = `${ALL_QUEENS_ROUTE}/${response.id}/`
          const routeToNavigate = hiveId ? routeWithHive : routeWithoutHive

          navigate(routeToNavigate, {
            state: { previousPath: location.pathname },
            replace: true,
          })
        }, 100)
        deleteDraft()
      })
  }

  useEffect(() => {
    if (hasChanges) {
      openModal(MODALS.CONFIRM_NAVIGATION_MODAL, {
        title: t("continueWhereYouLeftOff"),
        content: t("youHaveUnsavedChanges"),
        onBackText: t("discard"),
        onActionText: t("continue"),
        onBackClick: () => {
          deleteDraft()
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteDraft, openModal, t])

  const handleBackClick = () => {
    if (hasChanges) {
      openModal(MODALS.CONFIRM_NAVIGATION_MODAL, {
        title: t("unsavedChanges"),
        content: t("youWillLoseChanges"),
        onBackText: t("leave"),
        onActionText: t("stay"),
        onBackClick: () => {
          navigate(-1)
          deleteDraft()
        },
      })
    } else {
      navigate(-1)
      deleteDraft()
    }
  }

  return (
    <>
      <EditorTopBar
        onBackClick={handleBackClick}
        onSave={handleQueenCreation}
        text={t("addQueen")}
        isModified={hasChanges}
      />
      <PageContentWrapperLarge>
        {!queenDraft ? (
          <LoadingAnimation />
        ) : (
          <QueenEditor queenId={NEW_ENTITY} draftQueen={queenDraft} />
        )}
      </PageContentWrapperLarge>
    </>
  )
}
