import { type QueenSummary } from "@/features/bees/types/queens.ts"

export enum HiveType {
  Langstroth = "Langstroth",
  Warre = "Warre",
  TopBar = "Top Bar",
  Other = "Other",
}

export const hiveTypeOptions = Object.entries(HiveType).map(([_, value]) => ({
  value: value,
  label: value,
}))

export interface Hive {
  id: string
  apiary: string
  code: string
  hive_type: string
  hive_manufacturer: string
  number_of_brood_frames: string
  number_of_honey_frames: string
  number_of_empty_frames: string
  brood_box_count: string
  current_position: string
  super_count: string
  bee_count: string
  description: string
  last_weight: string
  picture: string | null
  created_at: string
  updated_at: string
}

export type HiveInput = Omit<Hive, "id" | "created_at" | "updated_at"> & {
  queenId?: string
}

export const hiveInputInitialState: HiveInput = {
  apiary: "",
  code: "",
  hive_type: "" as HiveType,
  hive_manufacturer: "",
  brood_box_count: "",
  super_count: "",
  current_position: "",
  last_weight: "",
  number_of_empty_frames: "",
  number_of_honey_frames: "",
  number_of_brood_frames: "",
  description: "",
  picture: "",
  bee_count: "",
}

export interface HiveResponse extends Hive {
  queen: QueenSummary
  tasks: string[]
  notes: string[]
}

export type HiveSummary = Pick<Hive, "hive_type" | "id" | "code">
