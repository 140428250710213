import { apiSlice, TagType } from "@/features/api/apiSlice.ts"
import {
  type BirdsGroupInput,
  type BirdsGroups,
} from "@/features/birds-groups/types/birds-groups.ts"

const BIRDS_GROUPS_API_BASE_URL = "/birds-groups/"

export const birdsGroupsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBirdsGroups: builder.query<BirdsGroups[], void>({
      query: () => BIRDS_GROUPS_API_BASE_URL,
      providesTags: [{ type: TagType.BirdsGroups, id: "LIST" }],
    }),
    getBirdsGroupById: builder.query<BirdsGroups, string>({
      query: (id) => BIRDS_GROUPS_API_BASE_URL + `${id}/`,
      providesTags: (_result, _error, id) => [
        { type: TagType.BirdsGroups, id },
      ],
    }),
    createBirdsGroup: builder.mutation<BirdsGroups, BirdsGroupInput>({
      query: (birdsGroup) => ({
        url: BIRDS_GROUPS_API_BASE_URL,
        method: "POST",
        body: birdsGroup,
      }),
      invalidatesTags: [{ type: TagType.BirdsGroups, id: "LIST" }],
    }),
    updateBirdsGroup: builder.mutation<
      BirdsGroups,
      { birdsGroup: Partial<BirdsGroups>; id: string }
    >({
      query: ({ birdsGroup, id }) => ({
        url: BIRDS_GROUPS_API_BASE_URL + `${id}/`,
        method: "PATCH",
        body: birdsGroup,
      }),
      invalidatesTags: (_queryCache, _error, { birdsGroup }) => [
        { type: TagType.BirdsGroups, id: "LIST" },
        { type: TagType.BirdsGroups, id: birdsGroup.id },
      ],
    }),
    deleteBirdsGroup: builder.mutation<void, string>({
      query: (id) => ({
        url: BIRDS_GROUPS_API_BASE_URL + `${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: (_queryCache, _error, birdsGroupId) => [
        { type: TagType.BirdsGroups, id: "LIST" },
        { type: TagType.BirdsGroups, id: birdsGroupId },
      ],
    }),
  }),
})

export const {
  useGetBirdsGroupsQuery,
  useGetBirdsGroupByIdQuery,
  useCreateBirdsGroupMutation,
  useUpdateBirdsGroupMutation,
  useDeleteBirdsGroupMutation,
} = birdsGroupsApi
