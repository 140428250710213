import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import GenericItemsGroup from "@/components/grouping/generic-items-group.tsx"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import NoResultsFound from "@/components/not-found/no-results-found.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import MobileTopBar from "@/components/top-bars/mobile-top-bar/mobile-top-bar.tsx"
import { useGetAllAssistantThreadsForUserQuery } from "@/features/assistant/api/assistant-api.ts"
import { AssistantDrawer } from "@/features/assistant/components/assistant-drawer/assistant-drawer.tsx"
import { ThreadItemWrapper } from "@/features/assistant/components/assistant-page-components/recent-threads.tsx"
import {
  groupItemsByTimeStampWithField,
  type ItemsArray,
} from "@/utils/helpers/time-grouping.ts"

export const AllThreadsPage = () => {
  const { t } = useTranslation()
  const { data, isLoading } = useGetAllAssistantThreadsForUserQuery()
  const threads = data?.threads
  const groupedThreads = useMemo(
    () =>
      threads?.length
        ? groupItemsByTimeStampWithField(threads, "updated_at")
        : null,
    [threads],
  )

  const ThreadItemWithDelete = (props: { item: ItemsArray[number] }) => (
    <ThreadItemWrapper {...props} showDelete={true} />
  )

  return (
    <>
      <MobileTopBar title={t("allThreads")} icon={<AssistantDrawer />} />

      <PageContentWrapperLarge className={"flex flex-col gap-4 p-4"}>
        {isLoading && <LoadingAnimation customStyle={{ height: "inherit" }} />}
        {!isLoading && threads?.length === 0 && (
          <NoResultsFound
            image={"hive"}
            message={t("noThreadsFoundForGivenQuery")}
          />
        )}
        {groupedThreads && (
          <GenericItemsGroup
            items={groupedThreads}
            ItemComponent={ThreadItemWithDelete}
          />
        )}
      </PageContentWrapperLarge>
    </>
  )
}
