import React from "react"
import { useTranslation } from "react-i18next"
import { useParams, useNavigate } from "react-router-dom"
import Skeleton from "@/components/skeleton/skeleton.tsx"
import { WidgetButton } from "@/components/widget-button/widget-button.tsx"
import { useGetAllQueensQuery } from "@/features/bees/api/queens-api.ts"
import { QueenCard } from "@/features/bees/components/queen-card/queen-card.tsx"
import { RemoveQueenButton } from "@/features/bees/components/remove-queen-button/remove-queen-button.tsx"
import { type QueenResponse } from "@/features/bees/types/queens.ts"
import WidgetHeader from "@/features/home/components/widget-header/widget-header.tsx"
import {
  ALL_HIVES_PATH,
  ALL_QUEENS_ROUTE,
  APIARIES_SCREEN_PATH,
} from "@/utils/constants/routes.ts"

interface Props {
  queen: QueenResponse | undefined
}

export const QueenWidget: React.FC<Props> = ({ queen }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { apiaryId, hiveId } = useParams()
  const { data: queens, isLoading, isFetching } = useGetAllQueensQuery()
  const queensWithoutHive = queens?.filter((queen) => !queen.hive)
  const addNewQueenNavigationString = `${APIARIES_SCREEN_PATH}/${apiaryId}${ALL_HIVES_PATH}/${hiveId}/queen/new`
  const queenViewNavigationString =
    queen &&
    `${APIARIES_SCREEN_PATH}/${apiaryId}${ALL_HIVES_PATH}/${hiveId}/queen/${queen.id}/`

  const isQueenReady = !(isLoading || isFetching)
  const noQueenAndNoQueensAvailable =
    !queen && isQueenReady && queensWithoutHive?.length === 0
  const noQueenAndQueensAvailable =
    !queen && isQueenReady && queensWithoutHive && queensWithoutHive?.length > 0

  return (
    <div
      className={"widget-border box-border flex flex-col gap-5 bg-white p-4"}
    >
      <WidgetHeader title={t("queen")} navigateTo={queenViewNavigationString} />
      {!isQueenReady && <Skeleton className={"widget-border h-24 w-full"} />}
      {queen && isQueenReady && (
        <>
          <QueenCard queen={queen} />
          <RemoveQueenButton queen={queen} />
        </>
      )}
      {noQueenAndNoQueensAvailable && (
        <>
          <div className={"mt-2 text-[14px] text-neutral-700"}>
            {t("hiveHasNoQueenAndNoQueensAvailable")}
          </div>
          <WidgetButton
            title={t("addQueen")}
            onClick={() => navigate(addNewQueenNavigationString)}
          />
        </>
      )}
      {noQueenAndQueensAvailable && (
        <>
          <div className={"mt-2 text-[14px] text-neutral-700"}>
            {t("hiveHasNoQueenAndQueensAvailable")}
          </div>
          <div className={"flex flex-col gap-2"}>
            <WidgetButton
              title={t("addQueen")}
              onClick={() => navigate(addNewQueenNavigationString)}
            />
            <WidgetButton
              title={t("assocQueen")}
              onClick={() => navigate(ALL_QUEENS_ROUTE)}
            />
          </div>
        </>
      )}
    </div>
  )
}
