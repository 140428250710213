import { isToday, toDate } from "date-fns"
import { ro } from "date-fns/locale"
import {
  DayPicker,
  type DateRange,
  type DayPickerProps,
} from "react-day-picker"
import "react-day-picker/dist/style.css"
import {
  BROWN,
  TRANSPARENT_BROWN,
  WHITE,
} from "@/utils/constants/colors-constants.ts"

function isValidDateInput(obj: unknown): obj is string | number | Date {
  return (
    typeof obj === "string" || typeof obj === "number" || obj instanceof Date
  )
}

function Calendar({ showOutsideDays = true, ...props }: DayPickerProps) {
  const isTodaySelected =
    isValidDateInput(props.selected) && isToday(toDate(props.selected))

  const rangeModifiers = {
    range_middle: {
      backgroundColor: TRANSPARENT_BROWN,
      borderRadius: 0,
    },
    range_start: {
      color: WHITE,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    range_end: {
      color: WHITE,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    selected: {
      backgroundColor: BROWN,
      color: WHITE,
    },
  }

  const singleModifiers = {
    selected: {
      backgroundColor: BROWN,
      color: "white",
      borderRadius: "50%",
    },
  }

  const isRangeDifferent = (dateRange: DateRange) => {
    if (!dateRange.from || !dateRange.to) {
      return false
    }
    const date1 = new Date(dateRange.from)
    const date2 = new Date(dateRange.to)
    return date1.getTime() !== date2.getTime()
  }

  return (
    <DayPicker
      locale={ro}
      showOutsideDays={showOutsideDays}
      weekStartsOn={1}
      modifiersStyles={{
        ...(props.mode === "range" &&
        props.selected &&
        props.selected.to &&
        isRangeDifferent(props.selected)
          ? rangeModifiers
          : singleModifiers),
        today: {
          color: isTodaySelected ? "white" : BROWN,
        },
      }}
      styles={{
        root: {
          width: "max-content",
          margin: 0,
          color: "hsl(var(--foreground))",
          background: "hsl(var(--background))",
          border: `1px solid hsl(var(--border))`,
          borderRadius: "var(--radius)",
          padding: "0.5rem",
          position: "relative",
        },
        table: {
          paddingTop: "1rem",
        },
        head_cell: {
          color: "hsl(var(--muted-foreground))",
          fontSize: "0.8rem",
          fontWeight: 400,
        },
        day: {
          backgroundColor: "hsl(var(--background))",
          display: "flex",
          justifyContent: "center",
          border: "none",
          padding: "0.5rem",
          margin: "auto",
          fontSize: "0.8rem",
        },
      }}
      {...props}
    />
  )
}

Calendar.displayName = "Calendar"

export default Calendar
