import { useTranslation } from "react-i18next"
import CircleTickIcon from "@/assets/icons/task/circle-tick-icon.tsx"
import { type RenderFunctionDrawerItemComponentProps } from "@/components/render-function-drawer/render-function-drawer.tsx"
import { DrawerClose } from "@/components/shadcn/drawer.tsx"
import {
  type AGE_GROUPS,
  AGE_GROUPS_DESCRIPTIONS,
} from "@/features/birds-groups/types/birds-groups.ts"
import { cn } from "@/utils/styles.ts"

export const AgeGroupCard: React.FC<RenderFunctionDrawerItemComponentProps> = ({
  id,
  isSelected,
  onSelect,
}) => {
  const handleCardClick = () => {
    onSelect && onSelect()
  }
  const { t } = useTranslation()
  const description = AGE_GROUPS_DESCRIPTIONS[id as AGE_GROUPS]
  const Wrapper = onSelect ? DrawerClose : "div"

  return (
    <Wrapper
      onClick={handleCardClick}
      className={cn(
        "flex items-start justify-between gap-2 rounded-[10px] border border-gray-200 bg-[var(--light-grey)] p-2 text-neutral-700",
        isSelected && "border border-brown-500 bg-white text-brown-500",
      )}
    >
      <div
        className={cn(
          "grid w-full items-start gap-2",
          onSelect && "grid-cols-[30px_auto]",
        )}
      >
        {onSelect && (
          <>
            {isSelected ? (
              <CircleTickIcon />
            ) : (
              <div className="border-dark-grey box-border h-[24px] w-[24px] rounded-full border bg-[var(--background-color)] pr-3" />
            )}
          </>
        )}
        <div className="flex min-w-0 flex-col text-left">
          {" "}
          <p className="font-semibold">{t(id)}</p>
          <div
            className={cn(
              "truncate text-neutral-700",
              onSelect && "whitespace-normal break-words",
            )}
          >
            {t(description)}
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
