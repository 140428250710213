import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { TopBarContainer } from "../top-bar-container"
import BackIcon from "@/assets/icons/misc/back-icon.tsx"
import CircleTickIcon from "@/assets/icons/task/circle-tick-icon.tsx"
import styles from "@/components/top-bars/top-bars-common.module.css"

interface EditorTopBarProps {
  text: string
  onSave?: () => void
  isModified: boolean
  onBackClick?: () => void
}

const EditorTopBar: React.FC<EditorTopBarProps> = ({
  text,
  onSave,
  isModified = true,
  onBackClick,
}) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const handleBackClick = () => {
    if (onBackClick) {
      onBackClick()
    } else {
      navigate(-1)
    }
  }

  return (
    <TopBarContainer>
      <div className={"flex items-center"}>
        <div className={styles.actionIcon} onClick={handleBackClick}>
          <BackIcon />
        </div>
        <div className={styles.title}>{t(text)}</div>
      </div>
      <div className={styles.floatRight}>
        {isModified && (
          <div
            role="button"
            className={styles.actionIcon}
            onClick={() => {
              onSave?.()
            }}
          >
            <CircleTickIcon alt="save" size={30} />
            <span className={"sr-only"}>Save animal</span>
          </div>
        )}
      </div>
    </TopBarContainer>
  )
}

export default EditorTopBar
