import { t } from "i18next"
import { type Animal } from "@/features/farm"
import { FILTER_AGE } from "@/features/farm/types/animal-ages-constants.ts"
import { getMonthsFromDateOfBirth } from "@/utils/helpers/time-utils.ts"

export interface AnimalLifeSpan {
  from: number
  to: number
}

export const convertMonthsToYearsAndMonths = (months: number) => {
  const years = Math.floor(months / 12)
  const remainingMonths = months % 12

  if (years === 0 && remainingMonths !== 0) {
    if (remainingMonths === 0) return `0 ${t("months")}`
    if (remainingMonths === 1) return `1 ${t("month")}`

    return `${remainingMonths} ${t("months")}`
  }

  if (years === 1) {
    if (remainingMonths === 0) return `${years} ${t("year")}`
    if (remainingMonths === 1)
      return `${years} ${t("year")} ${t("and")} 1 ${t("month")}`

    return `${years} ${t("year")} ${t("and")} ${remainingMonths} ${t("months")}`
  }

  if (remainingMonths === 0) return `${years} ${t("years")}`
  if (remainingMonths === 1)
    return `${years} ${t("years")} ${t("and")} 1 ${t("month")}`
  return `${years} ${t("years")} ${t("and")} ${remainingMonths} ${t("months")}`
}

export const formatAgeRange = (range: { from: number; to: number }) => {
  if (range.from === FILTER_AGE.from && range.to >= FILTER_AGE.to) {
    return t("allAges")
  }
  if (range.to >= FILTER_AGE.to) {
    return `${convertMonthsToYearsAndMonths(range.from)} +`
  }
  return `${convertMonthsToYearsAndMonths(range.from)} - ${convertMonthsToYearsAndMonths(
    range.to,
  )}`
}

export const matchesAnimalAge = (
  animalAge: number,
  selectedAnimalAge: {
    from: number
    to: number
  },
) => {
  const lowerBoundary = selectedAnimalAge.from
  const higherBoundary = selectedAnimalAge.to

  if (animalAge === 0 && lowerBoundary === 0) return true
  return animalAge > lowerBoundary && animalAge <= higherBoundary
}

export const getAnimalsByAgeRange = (
  animals: Animal[],
  ageObj: AnimalLifeSpan,
) => {
  return animals.filter((animal) => {
    const animalAge = getMonthsFromDateOfBirth(animal.birth_date)
    if (matchesAnimalAge(animalAge, ageObj)) {
      return animal
    }
  })
}

export const getNumberOfAnimalsByAgeRange = (
  animals: Animal[],
  ageObj: AnimalLifeSpan,
) => {
  let total = 0
  for (let i = 0; i < animals.length; i++) {
    const animalAge = getMonthsFromDateOfBirth(animals[i].birth_date)
    if (matchesAnimalAge(animalAge, ageObj)) {
      total++
    }
  }
  return total
}
