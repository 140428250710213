import queryString from "query-string"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useGetAllHivesQuery } from "@/features/bees/api/hives-api.ts"
import {
  filterHives,
  HIVES_FILTER_ENUM,
  type HiveFilterSelection,
} from "@/features/bees/utils/hive-filter.ts"

export const useHivesFilterURL = () => {
  const navigate = useNavigate()
  const { data = [], isLoading } = useGetAllHivesQuery()

  const query = new URLSearchParams(useLocation().search)
  const searchQuery = query.get(HIVES_FILTER_ENUM.SEARCH_QUERY) || ""
  const apiary =
    query.get(HIVES_FILTER_ENUM.APIARY) || HIVES_FILTER_ENUM.ALL_APIARIES
  const type =
    query.get(HIVES_FILTER_ENUM.TYPE) || HIVES_FILTER_ENUM.ALL_HIVE_TYPES

  const initialFilter: HiveFilterSelection = {
    searchQuery,
    apiary,
    type,
  }

  const [selections, setSelections] =
    useState<HiveFilterSelection>(initialFilter)

  const updateURL = useCallback(() => {
    const searchString = queryString.stringify(selections)
    navigate({ search: searchString }, { replace: true })
  }, [navigate, selections])

  useEffect(() => {
    updateURL()
  }, [selections, updateURL])

  const updateSelection = useCallback(
    (key: keyof HiveFilterSelection) => (value: string) =>
      setSelections((prev) => ({ ...prev, [key]: value })),
    [],
  )

  const filteredHives = useMemo(
    () => filterHives(data, selections),
    [data, selections],
  )

  return {
    selections,
    hives: filteredHives,
    isLoading,
    handleApiaryChange: updateSelection("apiary"),
    handleTypeChange: updateSelection("type"),
    handleSearchQueryChange: updateSelection("searchQuery"),
  }
}
