import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

export const ChatsIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      fill="currentColor"
      viewBox="0 0 256 256"
      id="Flat"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <path d="M166.75684,76.30786A75.9872,75.9872,0,0,0,20,103.99219v66a10.01146,10.01146,0,0,0,10,10H89.32764a76.1425,76.1425,0,0,0,70.67236,48h66a10.01146,10.01146,0,0,0,10-10v-66A75.962,75.962,0,0,0,166.75684,76.30786ZM28,169.99219v-66a68,68,0,1,1,68,68H30A2.0023,2.0023,0,0,1,28,169.99219Zm200,48a2.0023,2.0023,0,0,1-2,2H160a68.13741,68.13741,0,0,1-62.00586-40.0506A75.97979,75.97979,0,0,0,169.502,84.66486,67.97326,67.97326,0,0,1,228,151.99219Z"></path>
      </g>
    </svg>
  )
}
