import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

export const RemoveQueenIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3095_3406)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 17.5C0 7.83501 7.83501 0 17.5 0C27.1649 0 35 7.83501 35 17.5C35 27.1649 27.1649 35 17.5 35C7.83501 35 0 27.1649 0 17.5ZM5.98218 7.63208C3.70755 10.2845 2.33333 13.7318 2.33333 17.5C2.33333 25.8764 9.12368 32.6667 17.5 32.6667C21.2682 32.6667 24.7154 31.2923 27.3679 29.0178L5.98218 7.63208ZM7.63208 5.98218L29.0178 27.3679C31.2923 24.7154 32.6667 21.2682 32.6667 17.5C32.6667 9.12368 25.8764 2.33333 17.5 2.33333C13.7318 2.33333 10.2845 3.70755 7.63208 5.98218Z"
          fill="currentColor"
        />
        <path
          d="M12.9419 7L14.8219 8.88M19.0619 8.88L20.9419 7M13.9419 12V11C13.9419 10.2044 14.2579 9.44129 14.8206 8.87868C15.3832 8.31607 16.1462 8 16.9419 8C17.7375 8 18.5006 8.31607 19.0632 8.87868C19.6258 9.44129 19.9419 10.2044 19.9419 11V12M12.5019 17H21.3719M12.4419 22H21.4419M9.94188 12C9.44984 12.0095 8.9677 12.14 8.53797 12.3798C8.10824 12.6196 7.74411 12.9615 7.47767 13.3753C7.21124 13.7891 7.05069 14.262 7.01018 14.7525C6.96967 15.2429 7.05044 15.7358 7.24537 16.1877C7.4403 16.6396 7.7434 17.0366 8.12795 17.3437C8.51251 17.6508 8.96671 17.8585 9.45051 17.9487C9.93432 18.0388 10.4329 18.0086 10.9022 17.8606C11.3716 17.7126 11.7973 17.4514 12.1419 17.1C14.0419 15 16.9419 12 16.9419 12C16.9419 12 19.8419 15 21.7419 17.1C22.0865 17.4514 22.5122 17.7126 22.9815 17.8606C23.4509 18.0086 23.9494 18.0388 24.4332 17.9487C24.917 17.8585 25.3712 17.6508 25.7558 17.3437C26.1404 17.0366 26.4435 16.6396 26.6384 16.1877C26.8333 15.7358 26.9141 15.2429 26.8736 14.7525C26.8331 14.262 26.6725 13.7891 26.4061 13.3753C26.1396 12.9615 25.7755 12.6196 25.3458 12.3798C24.9161 12.14 24.4339 12.0095 23.9419 12H9.94188Z"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.4419 15.7C21.3419 16.6 21.8419 17.8 21.9419 19C21.9419 24.8 16.9419 27 16.9419 27C16.9419 27 11.9419 24.8 11.9419 19C12.0419 17.8 12.5419 16.6 13.4419 15.7"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3095_3406">
          <rect width="35" height="35" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  )
}
