import { memo } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { ChatsIcon } from "@/assets/ai-assistent/chats-icon.tsx"
import ArrowRight from "@/assets/icons/misc/arrow-right.tsx"
import DeleteIcon from "@/assets/icons/misc/delete-icon.tsx"
import {
  useDeleteThreadMutation,
  useGetAllAssistantThreadsForUserQuery,
} from "@/features/assistant/api/assistant-api.ts"
import { type AssistantThreadSummary } from "@/features/assistant/types/assistant.ts"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"
import { GREY } from "@/utils/constants/colors-constants.ts"
import { AI_ASSISTANT_THREADS_ALL } from "@/utils/constants/routes.ts"
import { type ItemsArray } from "@/utils/helpers/time-grouping.ts"
import { elapsedTime } from "@/utils/helpers/time-utils.ts"
import { cn } from "@/utils/styles.ts"

interface Thread {
  id: string
  title: string
  updated_at: string
}

export const ThreadItemWrapper: React.FC<{
  item: ItemsArray[number]
  showDelete?: boolean
}> = ({ item, showDelete }) => {
  return (
    <ThreadItem
      thread={item as AssistantThreadSummary}
      showDelete={showDelete}
    />
  )
}

export const ThreadItem = memo(
  ({
    thread,
    showDelete = false,
  }: {
    thread: Thread
    showDelete?: boolean
  }) => {
    const navigate = useNavigate()
    const [deleteThread] = useDeleteThreadMutation()
    const { t } = useTranslation()
    const { openModal } = useModal()
    const navigateToThread = () => {
      navigate(`/assistant/${thread.id}`)
    }

    const handleThreadDeletion = (e: React.MouseEvent) => {
      e.stopPropagation()
      openModal(MODALS.BASE_MODAL, {
        title: t("removeThread"),
        content: t("deleteThreadConfirmation"),
        onActionClick: async () => {
          const promise = deleteThread(thread.id).unwrap()
          toast.promise(promise, {
            pending: t("removingThread"),
            success: t("threadRemoved"),
            error: t("errorRemovingThread"),
          })
        },
      })
    }

    return (
      <div
        onClick={navigateToThread}
        className="flex w-full items-center justify-start gap-1 rounded-sm border border-zinc-200 bg-zinc-100"
      >
        <div
          className={cn(
            "flex w-full flex-col items-center justify-start gap-1 rounded-sm bg-zinc-100 p-3",
            showDelete ? "pr-0" : "pr-3",
          )}
        >
          <div className="grid w-full grid-cols-[auto,auto] items-center justify-start gap-2 text-zinc-900">
            <ChatsIcon className="h-5 w-5 text-zinc-500" />
            <p className="truncate text-base">{thread.title}</p>
          </div>
          <p className="w-full text-zinc-500">
            {elapsedTime(thread.updated_at)}
          </p>
        </div>
        {showDelete && (
          <div className={"p-3"} onClick={handleThreadDeletion}>
            <DeleteIcon className={"text-red-400"} />
          </div>
        )}
      </div>
    )
  },
)

ThreadItem.displayName = "ThreadItem"

const ThreadsHeader = memo(() => {
  const { t } = useTranslation()

  return (
    <div className="flex w-full items-center justify-start gap-1">
      <ChatsIcon className="h-5 w-5 text-brown-500" />
      <p className="text-zinc-600">{t("recentChats")}</p>
    </div>
  )
})

ThreadsHeader.displayName = "ThreadsHeader"

const ViewAllFooter = memo(() => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <p
      onClick={() => navigate(AI_ASSISTANT_THREADS_ALL)}
      className="my-4 flex items-center gap-1 text-center text-zinc-600 underline"
    >
      {t("viewAllChats")} <ArrowRight className="h-3 w-3" stroke={GREY} />
    </p>
  )
})

ViewAllFooter.displayName = "ViewAllFooter"

const RecentThreads = memo(() => {
  const { data, isLoading } = useGetAllAssistantThreadsForUserQuery()
  const threads = data?.threads
  const last3threads = threads && threads.slice(0, 3)

  if (isLoading || !threads?.length) {
    return null
  }

  return (
    <div className="mt-6 flex w-full flex-col items-center gap-3">
      <ThreadsHeader />

      {last3threads?.map((thread) => (
        <ThreadItem key={thread.id} thread={thread} />
      ))}

      <ViewAllFooter />
    </div>
  )
})

RecentThreads.displayName = "RecentThreads"

export default RecentThreads
