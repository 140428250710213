import React, { useState, type MouseEvent } from "react"
import { useTranslation } from "react-i18next"
import styles from "./attach-image-field.module.css"
import ChangePhotoIcon from "@/assets/icons/bees/change-photo-icon.tsx"
import CloseCrossIcon from "@/assets/icons/misc/close-cross-icon.tsx"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item.tsx"
import { resizeImage } from "@/utils/helpers/compres-image-utils.ts"

interface SelectPictureProps {
  picture: string | null
  onChoosePicture?: (imgBase64: string | null) => void
}

export const AttachImageFieldBase64: React.FC<SelectPictureProps> = ({
  picture,
  onChoosePicture,
}) => {
  const { t } = useTranslation()
  const [pictureUrl, setPictureUrl] = useState(picture)
  const fileInputRef = React.useRef<HTMLInputElement>(null)

  const handleAddImage = (base64: string) => {
    if (onChoosePicture) {
      onChoosePicture(base64)
      setPictureUrl(base64)
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      resizeImage(file, 800, 800, handleAddImage)
    }
  }

  const clickInput = () => {
    onChoosePicture && fileInputRef.current?.click()
  }

  const ImagePresent = () => {
    return (
      <div className={styles.wrappingCircle}>
        {picture && (
          <img
            className={styles.image}
            src={pictureUrl ? pictureUrl : picture}
            alt="avatar"
          />
        )}
      </div>
    )
  }

  const getFieldInfo = () => {
    if (picture) {
      return t("changePhoto")
    } else {
      return t("choosePhoto")
    }
  }

  const removeImage = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    setPictureUrl(null)
    onChoosePicture && onChoosePicture(null)
  }

  return (
    <OverflowMenuItem>
      <div className={styles.imageContainer} onClick={clickInput}>
        {picture ? (
          <ImagePresent />
        ) : (
          <div
            className={
              "grid h-[30px] w-[30px] place-content-center rounded-[4px] bg-gray-600 text-white"
            }
          >
            <ChangePhotoIcon />
          </div>
        )}
        <p className={styles.info}>{getFieldInfo()}</p>
        {picture && (
          <div className={styles.closeIcon} onClick={removeImage}>
            <CloseCrossIcon />
          </div>
        )}
      </div>

      <input
        id="fileInput"
        type="file"
        accept=".jpg, .jpeg, .png, .webp"
        ref={fileInputRef}
        className={styles.imageInput}
        onChange={handleFileChange}
      />
    </OverflowMenuItem>
  )
}
