import React from "react"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import UserIcon from "@/assets/icons/account/user-icon.tsx"
import { ApiariesIcon } from "@/assets/icons/bees/apiaries-icon.tsx"
import DetailIcon from "@/assets/icons/farm/details-icon.tsx"
import NoteThumbnailIcon from "@/assets/icons/notes/note-thumbnail-icon.tsx"
import GenericDatePicker from "@/components/generic-date-picker/generic-date-picker.tsx"
import { SelectionRowDrawer } from "@/components/selection-row-drawer/selection-row-drawer.tsx"
import { DrawerTrigger } from "@/components/shadcn/drawer.tsx"
import Skeleton from "@/components/skeleton/skeleton.tsx"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item.tsx"
import InputFieldWrapperWithIcon from "@/components/text-card-wrapper-with-icon/input-field-wrapper-with-icon.tsx"
import TextAreaFieldWrapperWithIcon from "@/components/text-card-wrapper-with-icon/text-area-field-wrapper-with-icon.tsx"
import { useGetApiariesQuery } from "@/features/bees/api/apiaries-api.ts"
import EventsTypeDrawer from "@/features/events/animal-events/components/event-type-drawer/event-type-drawer.tsx"
import useMapEventTypesToParents from "@/features/events/animal-events/hooks/use-map-event-types-to-parents.ts"
import { type AnimalEventType } from "@/features/events/animal-events/types/animal-events.ts"
import { useGetAllApiaryEventTypesQuery } from "@/features/events/apiary-events/api/apiary-event-types-api.ts"
import { ApiaryMovementEventEditor } from "@/features/events/apiary-events/components/apiary-movement-event-editor/apiary-movement-event-editor.tsx"
import useCheckApiaryEventType from "@/features/events/apiary-events/hooks/use-check-apiary-event-type.ts"
import { APIARY_EVENT_TYPES_ENUM } from "@/features/events/apiary-events/types/apiary-events-constants.ts"
import { APIARY_QUERY_PARAMS } from "@/features/events/common/types/apiary-querry-params.ts"
import RequiredIndicator from "@/features/farm/components/required-indicator/required-indicator.tsx"
import usePrefetchImages from "@/hooks/use-prefetch-images.ts"
import { useAppDispatch } from "@/redux/hooks.ts"
import {
  setDraftApiaryEventApiary,
  setDraftApiaryEventDate,
  setDraftApiaryEventDescription,
  setDraftApiaryEventNotes,
  setDraftApiaryEventType,
  setDraftApiaryPersonName,
  setDraftEventSubDataIsPermanent,
  type ApiaryEventDraft,
} from "@/redux/slices/apiary-events-slice.ts"

interface Props {
  draftApiaryEvent: ApiaryEventDraft
  eventId?: string
}

export const ApiaryEventEditor: React.FC<Props> = ({
  draftApiaryEvent,
  eventId,
}) => {
  const { data: apiaries } = useGetApiariesQuery()
  const { data: apiariesEventTypes } = useGetAllApiaryEventTypesQuery()
  const apiariesEventTypesImages = apiariesEventTypes?.map(
    (eventType) => eventType.picture ?? "",
  )
  usePrefetchImages(apiariesEventTypesImages)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const apiaryId = searchParams.get(APIARY_QUERY_PARAMS.APIARY_ID)
  const apiariesOptions =
    (apiaries &&
      apiaries.map((apiary) => ({
        value: apiary.id,
        label: apiary.name,
      }))) ||
    []

  const getAssociatedApiariesInfo = () => {
    if (!apiaries) return ""
    const selectedApiary = apiaries.find(
      (a) => a.id === draftApiaryEvent.apiary,
    )
    return selectedApiary?.name ?? t("noApiarySelected")
  }

  const getAssociatedApiaryEventTypeDescription = () => {
    if (!apiariesEventTypes) return ""
    const selectedEventType = apiariesEventTypes.find(
      (a) => a.id === draftApiaryEvent.type,
    )
    return t(selectedEventType?.description ?? "")
  }

  const handleApiaryChange = (apiaryId: string) => {
    dispatch(setDraftApiaryEventApiary(apiaryId))
  }

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    dispatch(setDraftApiaryEventDescription(event.target.value))
  }

  const handleNotesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(setDraftApiaryEventNotes(event.target.value))
  }

  const handleEventTypeChange = (eventType: string | undefined) => {
    if (!eventType) return
    const isMovementEvent = checkEventType(
      eventType,
      APIARY_EVENT_TYPES_ENUM.ApiaryMovement,
    )
    const eventTypeObj = apiariesEventTypes?.find((e) => e.id === eventType)
    if (eventTypeObj?.name === "PastoralMovement" && isMovementEvent) {
      dispatch(setDraftEventSubDataIsPermanent(false))
    } else if (isMovementEvent) {
      dispatch(setDraftEventSubDataIsPermanent(true))
    }
    dispatch(setDraftApiaryEventType(eventType))
  }

  const handleDateChange = (date: string) => {
    dispatch(setDraftApiaryEventDate(date))
  }

  const handlePersonNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(setDraftApiaryPersonName(event.target.value))
  }

  const eventTypesMap = apiariesEventTypes?.reduce<
    Record<string, AnimalEventType>
  >((acc, eventType) => {
    return { ...acc, [eventType.id]: eventType as AnimalEventType }
  }, {})

  const parentEventTypes = useMapEventTypesToParents(eventTypesMap)
  const checkEventType = useCheckApiaryEventType()

  const isMovementEvent = checkEventType(
    draftApiaryEvent.type,
    APIARY_EVENT_TYPES_ENUM.ApiaryMovement,
  )

  return (
    <div className={"flex flex-col gap-[10px]"}>
      <div className={"mt-2 text-[14px] text-neutral-400"}>
        {t("generalEventDetails")}
      </div>
      <OverflowMenuItem isDisabled={!!apiaryId || !!eventId}>
        <SelectionRowDrawer
          multiple={false}
          renderTrigger={
            <DrawerTrigger>
              <div className={"grid grid-cols-[30px_auto] items-center gap-2"}>
                <div
                  className={
                    "grid h-[30px] w-[30px] place-content-center rounded-[4px] bg-blue-300 text-white"
                  }
                >
                  <ApiariesIcon />
                  {!draftApiaryEvent.apiary && (
                    <RequiredIndicator className={"absolute top-3.5"} />
                  )}
                </div>
                <div className={"flex flex-col items-start"}>
                  <div className={"text-[14px]"}>{t("assocApiary")}</div>
                  <p className={"text-brown-500"}>
                    {getAssociatedApiariesInfo()}
                  </p>
                </div>
              </div>
            </DrawerTrigger>
          }
          title={t("selectApiaries")}
          options={apiariesOptions}
          selectedValues={draftApiaryEvent.apiary}
          onSelectionChange={handleApiaryChange}
        />
      </OverflowMenuItem>
      {parentEventTypes ? (
        <OverflowMenuItem isDisabled={!!eventId}>
          <EventsTypeDrawer
            addEventEnabled={false}
            eventTypesMap={parentEventTypes}
            fallbackText={t("selectEventType")}
            onEventSelect={handleEventTypeChange}
            selectedValue={draftApiaryEvent.type}
          />
        </OverflowMenuItem>
      ) : (
        <Skeleton className={"h-[56px] w-full rounded-[8px]"} />
      )}
      {draftApiaryEvent.type && (
        <OverflowMenuItem className={"text-[14px] text-neutral-500"}>
          <i>{t("eventInfoDescription")}</i>
          {getAssociatedApiaryEventTypeDescription()}
        </OverflowMenuItem>
      )}
      {!isMovementEvent && (
        <GenericDatePicker
          isDisabled={!!eventId}
          value={draftApiaryEvent.date}
          onChange={handleDateChange}
        />
      )}
      <InputFieldWrapperWithIcon
        icon={<UserIcon size={30} />}
        align={"row"}
        name={t("personName")}
        isDisabled={false}
        value={draftApiaryEvent.person_name}
        onChange={handlePersonNameChange}
      />
      {isMovementEvent && (
        <ApiaryMovementEventEditor draftApiaryEvent={draftApiaryEvent} />
      )}
      <div className={"mt-2 text-[14px] text-neutral-400"}>
        {t("description")}
      </div>
      <TextAreaFieldWrapperWithIcon
        icon={<DetailIcon />}
        name={t("details")}
        isReadOnly={false}
        value={draftApiaryEvent.description}
        onChange={handleDescriptionChange}
      />
      <TextAreaFieldWrapperWithIcon
        icon={<NoteThumbnailIcon size={30} />}
        name={t("otherObservations")}
        isReadOnly={false}
        value={draftApiaryEvent.notes}
        onChange={handleNotesChange}
      />
    </div>
  )
}
