import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

export const AddEventIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      width="62"
      height="30"
      viewBox="0 0 62 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M47.6451 6H42.5703C42.4121 6 42.3329 6 42.2631 6.0241C42.2013 6.0454 42.145 6.08017 42.0983 6.1259C42.0456 6.1776 42.0102 6.24838 41.9394 6.38992L38.2362 13.7963C38.0672 14.1344 37.9827 14.3034 38.003 14.4408C38.0207 14.5607 38.087 14.6681 38.1864 14.7376C38.3002 14.8172 38.4892 14.8172 38.8671 14.8172H44.3386L41.6935 23.6344L52.4444 12.4853C52.8071 12.1092 52.9885 11.9211 52.9991 11.7602C53.0082 11.6205 52.9506 11.4847 52.8436 11.3944C52.7204 11.2903 52.4592 11.2903 51.9366 11.2903H45.6612L47.6451 6Z"
        stroke="#B5642D"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="0.75"
        y="0.75"
        width="60.5"
        height="28.5"
        rx="14.25"
        stroke="#B5642D"
        stroke-width="1.5"
      />
      <path
        d="M13 14.6667H26.3333M19.6667 8V21.3333"
        stroke="#B5642D"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
