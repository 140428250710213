import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import CircleTickIcon from "@/assets/icons/task/circle-tick-icon.tsx"
import PendingCircle from "@/components/pending-circle/pending-circle.tsx"
import { useNotesContext } from "@/context/notes-context.tsx"
import { type Note } from "@/features/notes/types/note.ts"
import { useAppSelector } from "@/redux/hooks.ts"
import { BROWN } from "@/utils/constants/colors-constants.ts"
import { NOTE_SCREEN_PATH } from "@/utils/constants/routes.ts"
import { extractNoteContentPlainText } from "@/utils/helpers/string-utils.ts"
import { cn } from "@/utils/styles.ts"

const NoteCard = ({ note }: { note: Note }) => {
  const { t } = useTranslation()
  const { isContextEnabled, setSelectedNotes, selectedNotes } =
    useNotesContext()

  const draftNotes = useAppSelector((state) => state.notesDraftSlice.drafts)
  const hasDraft = note.id in draftNotes

  const isSelected = selectedNotes?.includes(note.id)

  const handleNoteSelection = (e: React.MouseEvent) => {
    if (!isContextEnabled) return
    e.preventDefault()

    setSelectedNotes(
      isSelected
        ? selectedNotes?.filter((id) => id !== note.id) || []
        : [...(selectedNotes || []), note.id],
    )
  }

  return (
    <Link
      to={`${NOTE_SCREEN_PATH}/${note.id}`}
      className={cn("card-wrapper", "flex h-fit min-h-[60px] bg-white p-4")}
      onClick={handleNoteSelection}
    >
      {isContextEnabled && (
        <div className="pr-4">
          {isSelected ? (
            <CircleTickIcon size={24} fill={BROWN} />
          ) : (
            <PendingCircle />
          )}
        </div>
      )}

      <div className="w-full overflow-hidden">
        <div className="grid grid-cols-[auto_auto] justify-between gap-2">
          <div className="truncate text-[16px] font-semibold text-neutral-700">
            {note.title}
          </div>
        </div>

        <div
          className={cn(
            "w-full pt-2",
            hasDraft
              ? "grid grid-cols-[1fr_auto] items-center gap-6"
              : "overflow-hidden",
          )}
        >
          <div className="min-w-0 truncate text-neutral-700">
            {extractNoteContentPlainText(note, 150)}
          </div>
          {hasDraft && (
            <div className="whitespace-nowrap text-right text-gray-500">
              {t("unsavedChangesIndicator")}
            </div>
          )}
        </div>
      </div>
    </Link>
  )
}

export default NoteCard
