import { CalendarCog } from "lucide-react"
import React, { type ChangeEvent } from "react"
import { useTranslation } from "react-i18next"
import AinIcon from "@/assets/icons/farm/ain-icon.tsx"
import BreedIcon from "@/assets/icons/farm/breed-icon.tsx"
import DetailIcon from "@/assets/icons/farm/details-icon.tsx"
import ChooseSomethingIcon from "@/assets/icons/misc/choose-something-icon.tsx"
import BirdIcon from "@/assets/svg/bird-icon.svg?react"
import { AttachImageFieldBase64 } from "@/components/attach-image-field/attach-image-field-base-64.tsx"
import RenderFunctionDrawer from "@/components/render-function-drawer/render-function-drawer.tsx"
import { SelectionRowDrawer } from "@/components/selection-row-drawer/selection-row-drawer.tsx"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item.tsx"
import InputFieldWrapperWithIcon from "@/components/text-card-wrapper-with-icon/input-field-wrapper-with-icon.tsx"
import TextAreaFieldWrapperWithIcon from "@/components/text-card-wrapper-with-icon/text-area-field-wrapper-with-icon.tsx"
import { AgeGroupCard } from "@/features/birds-groups/components/age-group-card/age-group-card.tsx"
import { useGetBirdSpecies } from "@/features/birds-groups/hooks/use-get-bird-species.ts"
import {
  AGE_GROUPS_OPTIONS,
  type BirdsGroupInput,
} from "@/features/birds-groups/types/birds-groups.ts"
import RequiredIndicator from "@/features/farm/components/required-indicator/required-indicator.tsx"
import { InputType } from "@/utils/enums/input-type-enum.ts"
import { cn } from "@/utils/styles.ts"

interface BirdsGroupsEditorProps {
  birdsGroup: BirdsGroupInput
  onBirdsGroupChange: (birdsGroup: BirdsGroupInput) => void
}

const MIN_NR_OF_BIRDS = 0
const MAX_NR_OF_BIRDS = 9999

export const BirdsGroupsEditor: React.FC<BirdsGroupsEditorProps> = ({
  birdsGroup,
  onBirdsGroupChange,
}) => {
  const { t } = useTranslation()
  const birdSpecies = useGetBirdSpecies()
  const speciesOptions = birdSpecies?.map(
    (species) =>
      ({
        value: species.id,
        label: species.name,
      }) || [],
  )
  const breeds = birdSpecies?.find(
    (species) => species.id === birdsGroup.species,
  )?.breeds
  const breedOptions =
    breeds?.map((breed) => ({
      value: breed.id,
      label: breed.name,
    })) || []
  const handleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const description = event.target.value
    onBirdsGroupChange({ ...birdsGroup, description })
  }
  const handlePictureChange = (picture: string | null) => {
    onBirdsGroupChange({ ...birdsGroup, picture: picture ? picture : "" })
  }
  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value
    onBirdsGroupChange({ ...birdsGroup, name })
  }
  const handleAgeGroupChange = (age_group: string) => {
    onBirdsGroupChange({ ...birdsGroup, age_group })
  }
  const handleIncreaseCount = () => {
    onBirdsGroupChange({ ...birdsGroup, count: birdsGroup.count + 1 })
  }
  const handleDecreaseCount = () => {
    if (birdsGroup.count <= 0) return
    onBirdsGroupChange({ ...birdsGroup, count: birdsGroup.count - 1 })
  }
  const handleCountChange = (event: ChangeEvent<HTMLInputElement>) => {
    const count = parseInt(event.target.value)
    if (isNaN(count)) return
    if (count < MIN_NR_OF_BIRDS || count > MAX_NR_OF_BIRDS) return
    onBirdsGroupChange({ ...birdsGroup, count })
  }
  const handleSpeciesChange = (species: string) => {
    onBirdsGroupChange({ ...birdsGroup, species })
  }
  const handleBreedChange = (breed: string) => {
    onBirdsGroupChange({ ...birdsGroup, breed })
  }

  const selectedSpecies = birdSpecies?.find(
    (species) => species.id === birdsGroup.species,
  )
  const selectedBreed = selectedSpecies?.breeds.find(
    (breed) => breed.id === birdsGroup.breed,
  )

  return (
    <div className={"flex flex-col gap-[10px] pb-10"}>
      <InputFieldWrapperWithIcon
        icon={<AinIcon />}
        align={"row"}
        name={t("groupName")}
        isRequired={!birdsGroup.name}
        isDisabled={false}
        value={birdsGroup.name}
        onChange={handleNameChange}
      />
      <RenderFunctionDrawer
        renderTrigger={
          <OverflowMenuItem>
            <div
              className={
                "flex items-center gap-2 border-b border-b-gray-100 pb-2"
              }
            >
              <div
                className={
                  "grid h-[30px] w-[30px] place-content-center rounded bg-brown-500"
                }
              >
                <CalendarCog className={"h-[20px] w-[20px] text-white"} />
              </div>
              <p className={"text-neutral-600"}>{t("ageGroup")}</p>
            </div>
            <AgeGroupCard id={birdsGroup.age_group} isSelected />
          </OverflowMenuItem>
        }
        multiple={false}
        title={t("ageGroup")}
        options={AGE_GROUPS_OPTIONS}
        selectedValues={birdsGroup.age_group}
        onSelectionChange={handleAgeGroupChange}
        ItemComponent={AgeGroupCard}
      />
      <OverflowMenuItem>
        <SelectionRowDrawer
          renderTrigger={
            <div
              className={
                "grid h-[30px] w-full grid-cols-[30px_auto] items-center gap-2"
              }
            >
              <ChooseSomethingIcon />
              <p>{selectedSpecies ? t(selectedSpecies.name) : t("species")}</p>
            </div>
          }
          options={speciesOptions}
          title={t("species")}
          multiple={false}
          selectedValues={birdsGroup.species}
          onSelectionChange={handleSpeciesChange}
        />
        {!birdsGroup.species && <RequiredIndicator />}
      </OverflowMenuItem>
      <OverflowMenuItem isDisabled={!birdsGroup.species}>
        <SelectionRowDrawer
          renderTrigger={
            <div
              className={
                "grid h-[30px] w-full grid-cols-[30px_auto] items-center gap-2"
              }
            >
              <BreedIcon />
              <p>{selectedBreed ? t(selectedBreed.name) : t("breed")}</p>
            </div>
          }
          options={breedOptions}
          multiple={false}
          title={t("breed")}
          selectedValues={birdsGroup.breed}
          onSelectionChange={handleBreedChange}
        />
        {!birdsGroup.breed && <RequiredIndicator />}
      </OverflowMenuItem>
      <div
        className={
          "widget-border box-border flex w-full flex-col gap-3 bg-white px-3 py-4"
        }
      >
        <div
          className={"flex items-center gap-2 border-b border-b-gray-100 pb-2"}
        >
          <BirdIcon className="h-[30px] w-[30px] rounded bg-amber-500 p-1 text-white" />
          <p className={"text-neutral-600"}>{t("birdsCount")}</p>
        </div>
        <div className={"mx-8 grid h-16 grid-cols-[25%_50%_25%]"}>
          <button
            onClick={handleDecreaseCount}
            className={cn(
              "grid h-full w-full place-content-center rounded-[8px] border-[1.5px] border-amber-600 text-body-xl font-semibold text-amber-600",
              birdsGroup.count === 0 && "opacity-50 grayscale",
            )}
          >
            -
          </button>
          <input
            type={InputType.NUMBER}
            value={birdsGroup.count}
            min={0}
            max={9999}
            onChange={handleCountChange}
            className={cn(
              "border-b border-b-gray-100 pb-2 text-center text-body-xl text-neutral-700",
              birdsGroup.count === 0 && "text-red-500",
            )}
          />
          <button
            onClick={handleIncreaseCount}
            className={
              "grid h-full w-full place-content-center rounded-[8px] border-[1.5px] border-amber-600 text-body-xl font-semibold text-amber-600"
            }
          >
            +
          </button>
        </div>
      </div>
      <TextAreaFieldWrapperWithIcon
        icon={<DetailIcon />}
        name={t("details")}
        isReadOnly={false}
        value={birdsGroup.description}
        onChange={handleDescriptionChange}
      />
      <AttachImageFieldBase64
        picture={birdsGroup.picture}
        onChoosePicture={handlePictureChange}
      />
    </div>
  )
}
