import { skipToken } from "@reduxjs/toolkit/query"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { ApiariesIcon } from "@/assets/icons/bees/apiaries-icon.tsx"
import { HamburgerIcon } from "@/assets/icons/misc/hamburger-icon.tsx"
import { SelectionRowDrawer } from "@/components/selection-row-drawer/selection-row-drawer.tsx"
import { DrawerTrigger } from "@/components/shadcn/drawer.tsx"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item.tsx"
import { useGetApiariesQuery } from "@/features/bees/api/apiaries-api.ts"
import { useGetHiveByIdQuery } from "@/features/bees/api/hives-api.ts"
import { EventLocationTupleDrawer } from "@/features/events/common/components/location-drawer/event-location-tuple-drawer.tsx"
import useCheckHiveEventType from "@/features/events/hive-events/hooks/use-check-hive-event-type.ts"
import useGetHiveEventsLocation from "@/features/events/hive-events/hooks/use-get-hive-events-location.ts"
import { HIVE_MOVEMENT_EVENTS_ENUM } from "@/features/events/hive-events/types/hive-events-constants.ts"
import RequiredIndicator from "@/features/farm/components/required-indicator/required-indicator.tsx"
import { useAppDispatch } from "@/redux/hooks.ts"
import {
  setHiveDraftEventSubDataFromPosition,
  setHiveDraftEventSubDataToApiary,
  setHiveDraftEventSubDataToPosition,
  type HiveEventDraft,
} from "@/redux/slices/hive-events-draft-slice.ts"

interface Props {
  draftEvent: HiveEventDraft
}

export const HiveEventMovementEditor: React.FC<Props> = ({ draftEvent }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { data: eventHive, isLoading } = useGetHiveByIdQuery(
    draftEvent.hive || skipToken,
  )
  const allEventLocationOptions = useGetHiveEventsLocation()
  const checkEventType = useCheckHiveEventType()
  const { data: apiaries } = useGetApiariesQuery()
  const apiariesOptions =
    (apiaries &&
      apiaries
        .map((apiary) => ({
          value: apiary.id,
          label: apiary.name,
        }))
        .filter((a) => a.value !== eventHive?.apiary)) ||
    []

  const currentApiarySelected =
    apiaries && apiaries.find((a) => a.id === draftEvent.event_data?.to_apiary)

  const isTransferToApiary = checkEventType(
    draftEvent.type,
    HIVE_MOVEMENT_EVENTS_ENUM.TransferToOtherApiary,
  )

  const isTransferWithinApiary = checkEventType(
    draftEvent.type,
    HIVE_MOVEMENT_EVENTS_ENUM.TransferWithinApiary,
  )

  const handleFromPositionChange = (value: string) => {
    dispatch(setHiveDraftEventSubDataFromPosition(value))
  }

  const handleToPositionChange = (value: string) => {
    dispatch(setHiveDraftEventSubDataToPosition(value))
  }

  const handleApiaryChange = (apiaryId: string) => {
    dispatch(setHiveDraftEventSubDataToApiary(apiaryId))
  }

  const showBetweenApiaries =
    isTransferToApiary && apiaries && apiaries?.length > 1

  useEffect(() => {
    if (
      !isLoading &&
      eventHive?.current_position &&
      !draftEvent.event_data?.from_position &&
      isTransferWithinApiary
    ) {
      dispatch(setHiveDraftEventSubDataFromPosition(eventHive.current_position))
    }
  }, [
    dispatch,
    draftEvent.event_data?.from_position,
    eventHive,
    isLoading,
    isTransferWithinApiary,
  ])

  useEffect(() => {
    if (isTransferWithinApiary) {
      dispatch(setHiveDraftEventSubDataToApiary(""))
    }
  }, [dispatch, isTransferWithinApiary])

  useEffect(() => {
    if (isTransferToApiary) {
      dispatch(setHiveDraftEventSubDataFromPosition(""))
      dispatch(setHiveDraftEventSubDataToPosition(""))
    }
  }, [dispatch, isTransferToApiary])

  return (
    <>
      <div className="mt-2 text-[14px] text-neutral-400">
        {t("specificEventDetails")}
      </div>
      {isTransferWithinApiary && (
        <EventLocationTupleDrawer
          firstPlaceholder={t("fromPosition")}
          secondPlaceholder={t("toPosition")}
          firstValue={draftEvent.event_data?.from_position}
          secondValue={draftEvent.event_data?.to_position}
          onChangeFirstValue={handleFromPositionChange}
          onChangeSecondValue={handleToPositionChange}
          options={allEventLocationOptions}
        />
      )}
      {showBetweenApiaries && (
        <OverflowMenuItem isDisabled={!draftEvent.hive}>
          <SelectionRowDrawer
            multiple={false}
            title={t("apiary")}
            options={apiariesOptions}
            selectedValues={draftEvent.event_data?.to_apiary ?? ""}
            onSelectionChange={handleApiaryChange}
            renderTrigger={
              <DrawerTrigger>
                <div
                  className={"grid grid-cols-[30px_auto] items-center gap-2"}
                >
                  <div
                    className={
                      "grid h-[30px] w-[30px] place-content-center rounded-[4px] bg-blue-300 text-white"
                    }
                  >
                    <ApiariesIcon />
                    {!draftEvent.event_data?.to_apiary && (
                      <RequiredIndicator className={"top-0"} />
                    )}
                  </div>
                  <div className={"flex w-full justify-between"}>
                    {currentApiarySelected ? (
                      <div className={"text-[14px] text-neutral-700"}>
                        {currentApiarySelected.name}
                      </div>
                    ) : (
                      <div className={"text-[14px] text-[var(--grey)]"}>
                        {t("apiary")}
                      </div>
                    )}
                    <HamburgerIcon className={"scale-150"} />
                  </div>
                </div>
              </DrawerTrigger>
            }
          />
        </OverflowMenuItem>
      )}
    </>
  )
}
