import React from "react"
import { useTranslation } from "react-i18next"
import { FloraTypeIcon } from "@/assets/icons/bees/flora-type-icon.tsx"
import GenericDatePicker from "@/components/generic-date-picker/generic-date-picker.tsx"
import { SelectionRowDrawer } from "@/components/selection-row-drawer/selection-row-drawer.tsx"
import { DrawerTrigger } from "@/components/shadcn/drawer.tsx"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item.tsx"
import { useGetAllApiaryEventTypesQuery } from "@/features/events/apiary-events/api/apiary-event-types-api.ts"
import useGetApiaryEventsLocation from "@/features/events/apiary-events/hooks/use-get-apiary-events-location.ts"
import {
  floraTypesOptions,
  type FloraTypes,
} from "@/features/events/apiary-events/types/apiary-event.ts"
import { EventLocationTupleDrawer } from "@/features/events/common/components/location-drawer/event-location-tuple-drawer.tsx"
import RequiredIndicator from "@/features/farm/components/required-indicator/required-indicator.tsx"
import { useAppDispatch } from "@/redux/hooks.ts"
import {
  setDraftApiaryEventDate,
  setDraftEventSubDataDepartureLocation,
  setDraftEventSubDataFloraType,
  setDraftEventSubDataFromLocation,
  setDraftEventSubDataToLocation,
  type ApiaryEventDraft,
} from "@/redux/slices/apiary-events-slice.ts"

interface Props {
  draftApiaryEvent: ApiaryEventDraft
}

export const ApiaryMovementEventEditor: React.FC<Props> = ({
  draftApiaryEvent,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { data: apiariesEventTypes } = useGetAllApiaryEventTypesQuery()
  const allEventLocationOptions = useGetApiaryEventsLocation()

  const handleFromLocationChange = (fromLocation: string) => {
    dispatch(setDraftEventSubDataFromLocation(fromLocation))
  }

  const handleToLocationChange = (toLocation: string) => {
    dispatch(setDraftEventSubDataToLocation(toLocation))
  }

  const handleDepartureDateChange = (departure_date: string) => {
    dispatch(setDraftEventSubDataDepartureLocation(departure_date))
  }

  const handleFloraTypeChange = (flora_type: string) => {
    dispatch(setDraftEventSubDataFloraType(flora_type as FloraTypes))
  }

  const handleDateChange = (date: string) => {
    dispatch(setDraftApiaryEventDate(date))
  }

  const isPastoralMovement =
    apiariesEventTypes?.find((e) => e.id === draftApiaryEvent.type)?.name ===
    "PastoralMovement"

  const departureDateInterval = draftApiaryEvent.date
    ? {
        after: new Date(0),
        before: new Date(draftApiaryEvent.date),
      }
    : undefined

  const arrivalDateInterval = draftApiaryEvent.event_data?.departure_date
    ? {
        after: new Date(draftApiaryEvent.event_data.departure_date),
        before: new Date(8640000000000000),
      }
    : undefined

  return (
    <>
      <div className={"mt-2 text-[14px] text-neutral-400"}>
        {t("specificEventDetails")}
      </div>
      <EventLocationTupleDrawer
        firstPlaceholder={t("fromLocation")}
        secondPlaceholder={t("toLocation")}
        firstValue={draftApiaryEvent.event_data?.from_location}
        secondValue={draftApiaryEvent.event_data?.to_location}
        options={allEventLocationOptions}
        onChangeFirstValue={handleFromLocationChange}
        onChangeSecondValue={handleToLocationChange}
      />
      <GenericDatePicker
        placeholder={"departureDate"}
        value={draftApiaryEvent.event_data?.departure_date ?? ""}
        onChange={handleDepartureDateChange}
        withFooter={false}
        isRequired={!draftApiaryEvent.event_data?.departure_date}
        dateInterval={departureDateInterval}
      />
      <GenericDatePicker
        placeholder={"arrivalDate"}
        value={draftApiaryEvent.date}
        onChange={handleDateChange}
        withFooter={false}
        isRequired={!draftApiaryEvent.date}
        dateInterval={arrivalDateInterval}
      />
      {isPastoralMovement && (
        <OverflowMenuItem>
          <SelectionRowDrawer
            multiple={false}
            renderTrigger={
              <DrawerTrigger>
                <div
                  className={"grid grid-cols-[30px_auto] items-center gap-2"}
                >
                  <div
                    className={
                      "grid h-[30px] w-[30px] place-content-center rounded-[4px] bg-blue-300 text-white"
                    }
                  >
                    <FloraTypeIcon />
                  </div>
                  <div className={"flex justify-between"}>
                    <div className={"flex flex-col items-start"}>
                      <div className={"text-[14px]"}>
                        {draftApiaryEvent.event_data?.flora_type
                          ? t(draftApiaryEvent.event_data?.flora_type)
                          : t("selectFloraType")}
                      </div>
                    </div>
                    {!draftApiaryEvent.event_data?.flora_type && (
                      <RequiredIndicator className={"absolute top-3.5"} />
                    )}
                  </div>
                </div>
              </DrawerTrigger>
            }
            title={t("selectFloraType")}
            options={floraTypesOptions}
            selectedValues={draftApiaryEvent.apiary}
            onSelectionChange={handleFloraTypeChange}
          />
        </OverflowMenuItem>
      )}
    </>
  )
}
