import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import EditorTopBar from "@/components/top-bars/editor-top-bar/editor-top-bar.tsx"
import { useCreateApiaryEventMutation } from "@/features/events/apiary-events/api/apiary-events-api.ts"
import { ApiaryEventEditor } from "@/features/events/apiary-events/components/apiary-event-editor/apiary-event-editor.tsx"
import useCheckApiaryEventType from "@/features/events/apiary-events/hooks/use-check-apiary-event-type.ts"
import useInitializeApiaryEventDraft from "@/features/events/apiary-events/hooks/use-initialize-apiary-event-draft.ts"
import { validateApiaryEvent } from "@/features/events/apiary-events/types/apiary-event-schema.tsx"
import { APIARY_EVENT_TYPES_ENUM } from "@/features/events/apiary-events/types/apiary-events-constants.ts"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"
import { useAppDispatch } from "@/redux/hooks.ts"
import { resetApiaryEventDraft } from "@/redux/slices/apiary-events-slice.ts"

export const NewApiaryEventView = () => {
  const { t } = useTranslation()
  const { apiaryEventDraft, hasChanges } = useInitializeApiaryEventDraft()
  const { openModal } = useModal()
  const dispatch = useAppDispatch()
  const [createApiaryEvent] = useCreateApiaryEventMutation()

  const navigate = useNavigate()
  const checkEventType = useCheckApiaryEventType()

  const isMovementEvent = checkEventType(
    apiaryEventDraft.type,
    APIARY_EVENT_TYPES_ENUM.ApiaryMovement,
  )

  const isPastoralEvent = checkEventType(
    apiaryEventDraft.type,
    APIARY_EVENT_TYPES_ENUM.PastoralMovement,
  )

  const handleSave = async () => {
    if (
      !(await validateApiaryEvent(
        apiaryEventDraft,
        isMovementEvent,
        isPastoralEvent,
      ))
    ) {
      return
    }
    const promise = createApiaryEvent(apiaryEventDraft).unwrap()
    await toast
      .promise(promise, {
        pending: t("creatingApiaryEvent"),
        success: t("apiaryEventCreatedSuccessfully"),
        error: t("errorCreatingApiaryEvent"),
      })
      .then(() => {
        navigate(-1)

        dispatch(resetApiaryEventDraft({}))
      })
  }

  const onBackClick = () => {
    if (hasChanges) {
      openModal(MODALS.BASE_MODAL, {
        title: t("unsavedChanges"),
        content: t("youWillLoseChanges"),
        onActionClick: () => {
          dispatch(resetApiaryEventDraft({}))

          navigate(-1)
        },
      })
    } else {
      navigate(-1)
    }
  }

  return (
    <>
      <EditorTopBar
        text={t("addEvent")}
        isModified={hasChanges}
        onSave={handleSave}
        onBackClick={onBackClick}
      />
      <PageContentWrapperLarge>
        {!apiaryEventDraft ? (
          <LoadingAnimation />
        ) : (
          <ApiaryEventEditor draftApiaryEvent={apiaryEventDraft} />
        )}
      </PageContentWrapperLarge>
    </>
  )
}
