interface Props {
  title: string
  onClick: () => void
}

export const WidgetButton: React.FC<Props> = ({ title, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={
        "fp-border flex h-[60px] items-center justify-center gap-2 rounded-[15px]"
      }
    >
      <p className={"font-medium text-neutral-700"}>{title}</p>
    </div>
  )
}
