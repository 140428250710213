import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

export const AddTaskIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      width="62"
      height="30"
      viewBox="0 0 62 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51.6625 11.0188C51.6604 11.0042 51.6604 10.9895 51.6625 10.975C51.5383 10.71 51.3715 10.4672 51.1688 10.2563L47.8687 6.98125C47.6364 6.74916 47.3606 6.56513 47.0571 6.43966C46.7536 6.31419 46.4284 6.24974 46.1 6.25H40C39.5027 6.25 39.0258 6.44754 38.6742 6.79918C38.3225 7.15081 38.125 7.62772 38.125 8.125V21.875C38.125 22.3723 38.3225 22.8492 38.6742 23.2008C39.0258 23.5525 39.5027 23.75 40 23.75H50C50.4973 23.75 50.9742 23.5525 51.3258 23.2008C51.6775 22.8492 51.875 22.3723 51.875 21.875V12.025C51.8747 11.6785 51.8023 11.3358 51.6625 11.0188ZM47.5 8.38125L49.7437 10.625H48.125C47.9592 10.625 47.8003 10.5592 47.6831 10.4419C47.5658 10.3247 47.5 10.1658 47.5 10V8.38125ZM50.625 21.875C50.625 22.0408 50.5592 22.1997 50.4419 22.3169C50.3247 22.4342 50.1658 22.5 50 22.5H40C39.8342 22.5 39.6753 22.4342 39.5581 22.3169C39.4408 22.1997 39.375 22.0408 39.375 21.875V8.125C39.375 7.95924 39.4408 7.80027 39.5581 7.68306C39.6753 7.56585 39.8342 7.5 40 7.5H46.1H46.25V10C46.25 10.4973 46.4475 10.9742 46.7992 11.3258C47.1508 11.6775 47.6277 11.875 48.125 11.875H50.625C50.6281 11.925 50.6281 11.975 50.625 12.025V21.875Z"
        fill="#B5642D"
      />
      <path
        d="M42.6814 17.0564L41.8751 17.8689L41.6939 17.6814C41.6356 17.6231 41.5664 17.5769 41.4903 17.5453C41.4141 17.5138 41.3325 17.4976 41.2501 17.4976C41.1677 17.4976 41.0861 17.5138 41.01 17.5453C40.9338 17.5769 40.8646 17.6231 40.8064 17.6814C40.7481 17.7396 40.7019 17.8088 40.6703 17.885C40.6388 17.9611 40.6226 18.0427 40.6226 18.1251C40.6226 18.2075 40.6388 18.2891 40.6703 18.3653C40.7019 18.4414 40.7481 18.5106 40.8064 18.5689L41.4314 19.1939C41.4895 19.2524 41.5586 19.2989 41.6348 19.3307C41.7109 19.3624 41.7926 19.3787 41.8751 19.3787C41.9576 19.3787 42.0393 19.3624 42.1155 19.3307C42.1916 19.2989 42.2608 19.2524 42.3189 19.1939L43.5689 17.9439C43.6866 17.8262 43.7527 17.6666 43.7527 17.5001C43.7527 17.3337 43.6866 17.1741 43.5689 17.0564C43.4512 16.9387 43.2916 16.8726 43.1251 16.8726C42.9587 16.8726 42.7991 16.9387 42.6814 17.0564Z"
        fill="#B5642D"
      />
      <path
        d="M42.6814 13.3064L41.8751 14.1189L41.6939 13.9314C41.5762 13.8137 41.4166 13.7476 41.2501 13.7476C41.0837 13.7476 40.9241 13.8137 40.8064 13.9314C40.6887 14.0491 40.6226 14.2087 40.6226 14.3751C40.6226 14.5416 40.6887 14.7012 40.8064 14.8189L41.4314 15.4439C41.4895 15.5024 41.5586 15.5489 41.6348 15.5807C41.7109 15.6124 41.7926 15.6287 41.8751 15.6287C41.9576 15.6287 42.0393 15.6124 42.1155 15.5807C42.1916 15.5489 42.2608 15.5024 42.3189 15.4439L43.5689 14.1939C43.6866 14.0762 43.7527 13.9166 43.7527 13.7501C43.7527 13.5837 43.6866 13.4241 43.5689 13.3064C43.4512 13.1887 43.2916 13.1226 43.1251 13.1226C42.9587 13.1226 42.7991 13.1887 42.6814 13.3064Z"
        fill="#B5642D"
      />
      <path
        d="M48.75 13.75H45.625C45.4592 13.75 45.3003 13.8158 45.1831 13.9331C45.0658 14.0503 45 14.2092 45 14.375C45 14.5408 45.0658 14.6997 45.1831 14.8169C45.3003 14.9342 45.4592 15 45.625 15H48.75C48.9158 15 49.0747 14.9342 49.1919 14.8169C49.3092 14.6997 49.375 14.5408 49.375 14.375C49.375 14.2092 49.3092 14.0503 49.1919 13.9331C49.0747 13.8158 48.9158 13.75 48.75 13.75Z"
        fill="#B5642D"
      />
      <path
        d="M48.75 17.5H45.625C45.4592 17.5 45.3003 17.5658 45.1831 17.6831C45.0658 17.8003 45 17.9592 45 18.125C45 18.2908 45.0658 18.4497 45.1831 18.5669C45.3003 18.6842 45.4592 18.75 45.625 18.75H48.75C48.9158 18.75 49.0747 18.6842 49.1919 18.5669C49.3092 18.4497 49.375 18.2908 49.375 18.125C49.375 17.9592 49.3092 17.8003 49.1919 17.6831C49.0747 17.5658 48.9158 17.5 48.75 17.5Z"
        fill="#B5642D"
      />
      <rect
        x="0.75"
        y="0.75"
        width="60.5"
        height="28.5"
        rx="14.25"
        stroke="#B5642D"
        stroke-width="1.5"
      />
      <path
        d="M13 14.6667H26.3333M19.6667 8V21.3333"
        stroke="#B5642D"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
