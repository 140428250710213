import { skipToken } from "@reduxjs/toolkit/query"
import isEqual from "lodash.isequal"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import DeleteButton from "@/components/delete-button/delete-button.tsx"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import NoResultsFound from "@/components/not-found/no-results-found.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import EditorTopBar from "@/components/top-bars/editor-top-bar/editor-top-bar.tsx"
import {
  useDeleteBirdsGroupMutation,
  useGetBirdsGroupByIdQuery,
  useUpdateBirdsGroupMutation,
} from "@/features/birds-groups/api/birds-groups-api.ts"
import { BirdsGroupsEditor } from "@/features/birds-groups/components/birds-groups-editor/birds-groups-editor.tsx"
import { validateBirdsGroup } from "@/features/birds-groups/types/birds-groups-schema.tsx"
import {
  type BirdsGroupInput,
  type BirdsGroups,
} from "@/features/birds-groups/types/birds-groups.ts"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"
import { BIRDS_GROUPS_SCREEN_PATH } from "@/utils/constants/routes.ts"
import { getObjectDifference } from "@/utils/helpers/object-utils.ts"

const EditBirdsGroupView = () => {
  const { t } = useTranslation()
  const { birdGroupId } = useParams()
  const {
    data: birdGroup,
    isLoading,
    isError,
  } = useGetBirdsGroupByIdQuery(birdGroupId ?? skipToken)
  const [updateBirdGroup] = useUpdateBirdsGroupMutation()
  const [deleteBirdGroupMutation] = useDeleteBirdsGroupMutation()
  const title = birdGroup?.name || t("Loading")
  const [workingBirdGroup, setWorkingBirdGroup] = useState<
    BirdsGroups | undefined
  >(birdGroup)
  const { openModal } = useModal()
  const navigate = useNavigate()
  const hasChanges = !isEqual(birdGroup, workingBirdGroup)
  const handleBirdsGroupChange = (birdsGroup: BirdsGroupInput) => {
    if (!birdsGroup) return
    setWorkingBirdGroup(birdsGroup as BirdsGroups)
  }

  const deleteBirdsGroup = async () => {
    if (!workingBirdGroup?.id) return
    navigate(BIRDS_GROUPS_SCREEN_PATH, { replace: true })
    const promise = deleteBirdGroupMutation(workingBirdGroup.id).unwrap()
    toast.promise(promise, {
      pending: t("deletingBirdsGroup"),
      success: t("birdsGroupDeleted"),
      error: t("birdsGroupDeleteError"),
    })
  }

  const handleBirdsGroupDeletion = () => {
    openModal(MODALS.BASE_MODAL, {
      title: t("deleteBirdsGroupConfirmation"),
      content: t("birdsGroupWillBeDeleted"),
      onActionClick: deleteBirdsGroup,
    })
  }

  const handleUpdateBirdGroup = async () => {
    const updates =
      birdGroup &&
      workingBirdGroup &&
      getObjectDifference(birdGroup, workingBirdGroup)
    if (
      updates &&
      workingBirdGroup &&
      (await validateBirdsGroup(workingBirdGroup))
    ) {
      toast
        .promise(
          updateBirdGroup({
            birdsGroup: updates,
            id: birdGroup.id,
          }).unwrap(),
          {
            pending: t("updatingBirdsGroup"),
            success: t("birdsGroupUpdated"),
            error: t("errorUpdatingBirdsGroup"),
          },
        )
        .then(() => {
          navigate(BIRDS_GROUPS_SCREEN_PATH, { replace: true })
        })
    }
  }

  return (
    <>
      <EditorTopBar
        text={title}
        isModified={hasChanges}
        onSave={handleUpdateBirdGroup}
      />
      <PageContentWrapperLarge>
        {isLoading && !isError && <LoadingAnimation />}
        {isError && !isLoading && (
          <NoResultsFound className={"h-full"} message={t("anErrorOccurred")} />
        )}
        {!isLoading && !isError && workingBirdGroup && (
          <>
            <BirdsGroupsEditor
              birdsGroup={workingBirdGroup}
              onBirdsGroupChange={handleBirdsGroupChange}
            />
            <DeleteButton
              onClick={handleBirdsGroupDeletion}
              text={t("deleteBirdsGroup")}
            />
          </>
        )}
      </PageContentWrapperLarge>
    </>
  )
}

export default EditBirdsGroupView
