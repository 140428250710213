import style from "./loading-animation.module.css"
import LoadingCircle from "@/components/loading-animation/loading-circle.tsx"

interface Props {
  customStyle?: React.CSSProperties
}

export const LoadingAnimation: React.FC<Props> = ({ customStyle }) => {
  return (
    <div
      style={{ ...customStyle }}
      className={style["loading-animation-wrapper"]}
    >
      <LoadingCircle />
    </div>
  )
}
