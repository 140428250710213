import React from "react"
import { useTranslation } from "react-i18next"
import { BroodBoxIcon } from "@/assets/icons/bees/brood-box-icon.tsx"
import CalendarIcon from "@/assets/icons/bees/calendar-icon.tsx"
import DetailsIcon from "@/assets/icons/bees/details-icon.tsx"
import HiveFrameEmptyIcon from "@/assets/icons/bees/hive-frame-empty-icon.tsx"
import HiveFrameHoneyIcon from "@/assets/icons/bees/hive-frame-honey-icon.tsx"
import HiveFrameIcon from "@/assets/icons/bees/hive-frame-icon.tsx"
import HiveFramesBroodIcon from "@/assets/icons/bees/hive-frames-brood-icon.tsx"
import HiveIcon from "@/assets/icons/bees/hive-icon.tsx"
import IdentificationNumberIcon from "@/assets/icons/bees/identification-number-icon.tsx"
import NrBeesBeginningIcon from "@/assets/icons/bees/nr-bees-beginning-icon.tsx"
import QueenIcon from "@/assets/icons/bees/queen-icon.tsx"
import { SupperBoxIcon } from "@/assets/icons/bees/supper-box-icon.tsx"
import LocationIcon from "@/assets/icons/farm/location-icon.tsx"
import WeightIcon from "@/assets/icons/misc/weight-icon.tsx"
import HiveDetailsRow from "@/features/bees/components/hive-details/hive-details-row.tsx"
import { type HiveResponse } from "@/features/bees/types/hives.ts"
import { type QueenResponse } from "@/features/bees/types/queens.ts"

interface Props {
  hive: HiveResponse
  queen: QueenResponse | undefined
}

const HiveDetails: React.FC<Props> = ({ hive, queen }) => {
  const { t } = useTranslation()

  const totalFrames =
    hive.number_of_brood_frames +
    hive.number_of_empty_frames +
    hive.number_of_honey_frames

  return (
    <div
      className={"widget-border box-border flex flex-col gap-5 bg-white p-4"}
    >
      <div className={"text-[20px] font-semibold text-neutral-700"}>
        {t("hiveDetails")}
      </div>
      <div className={"flex flex-col gap-2"}>
        <HiveDetailsRow
          text={t("hiveCode")}
          value={hive.code}
          iconClassName={"bg-yellow-300"}
        >
          <IdentificationNumberIcon className={"scale-150"} />
        </HiveDetailsRow>
        <HiveDetailsRow
          text={t("currentPosition")}
          value={hive.current_position}
          iconClassName={"bg-transparent"}
        >
          <LocationIcon />
        </HiveDetailsRow>
        <HiveDetailsRow
          iconClassName={"bg-transparent"}
          text={t("currentWeight")}
          value={hive.last_weight}
        >
          <WeightIcon className={"w-[30px]"} />
        </HiveDetailsRow>
        <HiveDetailsRow
          iconClassName={"bg-transparent"}
          text={t("broodBoxCount")}
          value={hive.brood_box_count}
        >
          <BroodBoxIcon className={"w-[30px]"} />
        </HiveDetailsRow>
        <HiveDetailsRow
          text={t("superCount")}
          iconClassName={"bg-transparent"}
          value={hive.super_count}
        >
          <SupperBoxIcon className={"w-[30px]"} />
        </HiveDetailsRow>
        <HiveDetailsRow
          text={t("hiveType")}
          value={hive.hive_type}
          iconClassName={"bg-red-300"}
        >
          <HiveIcon className={"scale-150"} />
        </HiveDetailsRow>
        {queen?.year && (
          <HiveDetailsRow text={t("queenBirthYear")} value={queen.year}>
            <CalendarIcon className={"scale-150"} />
          </HiveDetailsRow>
        )}
        {queen?.origin && (
          <HiveDetailsRow text={t("queenOrigin")} value={queen.origin}>
            <QueenIcon />
          </HiveDetailsRow>
        )}
        <HiveDetailsRow
          text={t("framesNumber")}
          value={totalFrames}
          iconClassName={"bg-blue-600"}
        >
          <HiveFrameIcon className={"scale-150"} />
        </HiveDetailsRow>
        <HiveDetailsRow
          text={t("beeCount")}
          value={hive.bee_count}
          iconClassName={"bg-green-400"}
        >
          <NrBeesBeginningIcon className={"scale-150"} />
        </HiveDetailsRow>
        <HiveDetailsRow
          text={t("broodFramesNumber")}
          value={hive.number_of_brood_frames}
          iconClassName={"bg-yellow-300"}
        >
          <HiveFramesBroodIcon className={"scale-150"} />
        </HiveDetailsRow>
        <HiveDetailsRow
          text={t("honeyFramesNumber")}
          value={hive.number_of_honey_frames}
          iconClassName={"bg-orange-300"}
        >
          <HiveFrameHoneyIcon className={"scale-150"} />
        </HiveDetailsRow>
        <HiveDetailsRow
          text={t("emptyFramesNumber")}
          value={hive.number_of_empty_frames}
          iconClassName={"bg-purple-400"}
        >
          <HiveFrameEmptyIcon className={"scale-150"} />
        </HiveDetailsRow>
        {hive.description && (
          <HiveDetailsRow
            text={t("details")}
            value={hive.description}
            enableExpand={true}
            iconClassName={"bg-[var(--menu-financial-journal-color)]"}
          >
            <DetailsIcon className={"scale-150"} />
          </HiveDetailsRow>
        )}
      </div>
    </div>
  )
}

export default HiveDetails
