import isEqual from "lodash.isequal"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import EditorTopBar from "@/components/top-bars/editor-top-bar/editor-top-bar.tsx"
import { useCreateBirdsGroupMutation } from "@/features/birds-groups/api/birds-groups-api.ts"
import { BirdsGroupsEditor } from "@/features/birds-groups/components/birds-groups-editor/birds-groups-editor.tsx"
import { validateBirdsGroup } from "@/features/birds-groups/types/birds-groups-schema.tsx"
import {
  type BirdsGroupInput,
  DEFAULT_BIRDS_GROUP,
} from "@/features/birds-groups/types/birds-groups.ts"
import { BIRDS_GROUPS_SCREEN_PATH } from "@/utils/constants/routes.ts"

const NewBirdsGroupView = () => {
  const { t } = useTranslation()
  const [newBirdsGroup, setNewBirdsGroup] =
    useState<BirdsGroupInput>(DEFAULT_BIRDS_GROUP)
  const navigate = useNavigate()
  const [createBirdsGroup] = useCreateBirdsGroupMutation()
  const hasChanges = !isEqual(newBirdsGroup, DEFAULT_BIRDS_GROUP)
  const handleBirdsGroupSave = async () => {
    if (await validateBirdsGroup(newBirdsGroup)) {
      toast
        .promise(createBirdsGroup(newBirdsGroup).unwrap(), {
          pending: t("creatingBirdsGroup"),
          success: t("birdsGroupCreated"),
          error: t("errorCreatingBirdsGroup"),
        })
        .then(() => {
          navigate(BIRDS_GROUPS_SCREEN_PATH, { replace: true })
        })
    }
  }

  return (
    <>
      <EditorTopBar
        text={t("newBirdGroup")}
        isModified={hasChanges}
        onSave={handleBirdsGroupSave}
      />
      <PageContentWrapperLarge className={"flex flex-col gap-4 p-4"}>
        <BirdsGroupsEditor
          birdsGroup={newBirdsGroup}
          onBirdsGroupChange={setNewBirdsGroup}
        />
      </PageContentWrapperLarge>
    </>
  )
}

export default NewBirdsGroupView
