import { type ItemsArray } from "@/utils/helpers/time-grouping"

type ItemComponentProps = {
  item: ItemsArray[number]
}

interface GenericItemsSubGroupProps {
  items: ItemsArray
  title: string
  reverse?: boolean
  ItemComponent: React.ComponentType<ItemComponentProps>
}

const GenericItemsSubGroup: React.FC<GenericItemsSubGroupProps> = ({
  items,
  title,
  reverse = false,
  ItemComponent,
}) => {
  const reverseItems = reverse ? [...items].reverse() : items

  return (
    <div className="flex flex-col gap-4">
      <div className="text-sm text-neutral-700">{title}</div>
      {reverseItems.map((item, index) => (
        <ItemComponent key={index} item={item} />
      ))}
    </div>
  )
}

export default GenericItemsSubGroup
