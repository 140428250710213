import babyBird from "@/assets/birds-groups/baby-bird.png"
import juvenileBird from "@/assets/birds-groups/juvenile-bird.png"
import mixedAgeBirds from "@/assets/birds-groups/mixed-age-birds.png"
import adultBird from "@/assets/birds-groups/old-bird.png"
import { AGE_GROUPS } from "@/features/birds-groups/types/birds-groups.ts"

export const getBirdsGroupsDefaultImage = (ageGroup: AGE_GROUPS): string => {
  switch (ageGroup) {
    case AGE_GROUPS.ADULT:
      return adultBird
    case AGE_GROUPS.JUVENILE:
      return juvenileBird
    case AGE_GROUPS.HATCHING:
      return babyBird
    case AGE_GROUPS.MIXTURE:
      return mixedAgeBirds
    default:
      return mixedAgeBirds
  }
}
