import { format } from "date-fns"
import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import PlaceholderIcon from "@/assets/icons/misc/placeholder-icon.tsx"
import Skeleton from "@/components/skeleton/skeleton.tsx"
import { useGetApiaryByIdQuery } from "@/features/bees/api/apiaries-api.ts"
import { getIconBackgroundColor } from "@/features/events/animal-events/utils/animal-events-utils.ts"
import { useGetAllApiaryEventTypesQuery } from "@/features/events/apiary-events/api/apiary-event-types-api.ts"
import { type ApiaryEvent } from "@/features/events/apiary-events/types/apiary-event.ts"
import { APIARY_EVENTS_ROUTE } from "@/utils/constants/routes.ts"
import { type ItemsArray } from "@/utils/helpers/time-grouping.ts"

interface Props {
  event: ApiaryEvent
}

const ApiaryEventCardWrapper: React.FC<{ item: ItemsArray[number] }> = ({
  item,
}) => {
  return <ApiaryEventCard event={item as ApiaryEvent} />
}

ApiaryEventCardWrapper.displayName = "ApiaryEventCardWrapper"

const ApiaryEventCard: React.FC<Props> = ({ event }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    data: apiariesEventTypes,
    isLoading: isEventTypesLoading,
    isError: isEventTypesError,
  } = useGetAllApiaryEventTypesQuery()
  const {
    data: apiary,
    isLoading: isApiaryLoading,
    isError: isApiaryError,
  } = useGetApiaryByIdQuery(event.apiary)

  const isLoading = isEventTypesLoading || isApiaryLoading
  const isError = isEventTypesError || isApiaryError

  if (isLoading || isError) {
    return (
      <Skeleton
        className={"min-h-[60px] w-full rounded-[var(--fp-border-radius)]"}
      />
    )
  }

  const navigateToEvent = () => {
    navigate(APIARY_EVENTS_ROUTE + `/${event.id}`)
  }

  const selectedEventType =
    apiariesEventTypes &&
    apiariesEventTypes.find((type) => type.id === event.type)

  const backGroundColor = getIconBackgroundColor(selectedEventType?.name ?? "")

  return (
    <div
      onClick={navigateToEvent}
      className="box-border flex min-h-[60px] w-full items-center justify-between gap-2.5 rounded-[--fp-border-radius] border border-neutral-200 bg-white px-[15px] py-[7px]"
    >
      <div className={"grid place-content-center"}>
        {selectedEventType?.picture ? (
          <img
            src={selectedEventType.picture}
            alt={"eventPicture"}
            className={"h-[30px] w-[30px] rounded-[8px] p-1"}
            style={{ backgroundColor: backGroundColor }}
          />
        ) : (
          <PlaceholderIcon />
        )}
      </div>
      <div className="flex h-10 flex-1 flex-col justify-between">
        <div
          className={
            "grid grid-cols-[auto_auto] items-center justify-between gap-[10px]"
          }
        >
          <div className={"truncate text-body-sm"}>
            {selectedEventType && t(selectedEventType.name)}
          </div>
          <div>{format(new Date(event.date), "dd.MM.yyyy")}</div>
        </div>
        <div className="flex items-start justify-start gap-[5px] text-[--dark-grey] opacity-50">
          <p className={"font-semibold opacity-80"}>{t("apiary")}</p>
          <p>{apiary?.name}</p>
        </div>
      </div>
    </div>
  )
}

ApiaryEventCard.displayName = "ApiaryEventCard"

export { ApiaryEventCard, ApiaryEventCardWrapper }
