import { format } from "date-fns"
import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import PlaceholderIcon from "@/assets/icons/misc/placeholder-icon.tsx"
import Skeleton from "@/components/skeleton/skeleton.tsx"
import { useGetHiveByIdQuery } from "@/features/bees/api/hives-api.ts"
import { getIconBackgroundColor } from "@/features/events/animal-events/utils/animal-events-utils.ts"
import { useGetAllHiveEventTypesQuery } from "@/features/events/hive-events/api/hive-event-types-api.ts"
import { type HiveEvent } from "@/features/events/hive-events/types/hive-event.ts"
import { HIVE_EVENTS_ROUTE } from "@/utils/constants/routes.ts"
import { type ItemsArray } from "@/utils/helpers/time-grouping.ts"

interface Props {
  event: HiveEvent
}

const HiveEventCardWrapper: React.FC<{ item: ItemsArray[number] }> = ({
  item,
}) => {
  return <HiveEventCard event={item as HiveEvent} />
}

HiveEventCardWrapper.displayName = "HiveEventCardWrapper"

const HiveEventCard: React.FC<Props> = ({ event }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    data: hiveEventTypes,
    isLoading: isEventTypesLoading,
    isError: isEventTypesError,
  } = useGetAllHiveEventTypesQuery()
  const {
    data: hive,
    isLoading: isHiveLoading,
    isError: isHiveError,
  } = useGetHiveByIdQuery(event.hive)

  const isLoading = isEventTypesLoading || isHiveLoading
  const isError = isEventTypesError || isHiveError

  if (isLoading || isError) {
    return (
      <Skeleton
        className={"min-h-[60px] w-full rounded-[var(--fp-border-radius)]"}
      />
    )
  }

  const navigateToEvent = () => {
    navigate(HIVE_EVENTS_ROUTE + `/${event.id}`)
  }

  const selectedEventType =
    hiveEventTypes && hiveEventTypes.find((type) => type.id === event.type)

  const backGroundColor = getIconBackgroundColor(selectedEventType?.name ?? "")

  return (
    <div
      onClick={navigateToEvent}
      className="box-border flex min-h-[60px] w-full items-center justify-between gap-2.5 rounded-[--fp-border-radius] border border-neutral-200 bg-white px-[15px] py-[7px]"
    >
      <div className={"grid place-content-center"}>
        {selectedEventType?.picture ? (
          <img
            src={selectedEventType.picture}
            alt={"eventPicture"}
            className={"h-[30px] w-[30px] rounded-[8px] p-1"}
            style={{ backgroundColor: backGroundColor }}
          />
        ) : (
          <PlaceholderIcon />
        )}
      </div>
      <div className="flex h-10 flex-1 flex-col justify-between">
        <div
          className={
            "grid grid-cols-[auto_auto] items-center justify-between gap-[10px]"
          }
        >
          <div className={"truncate text-body-sm"}>
            {selectedEventType && t(selectedEventType.name)}
          </div>
          <div>{format(new Date(event.date), "dd.MM.yyyy")}</div>
        </div>
        <div className="flex items-start justify-start gap-[5px] text-[--dark-grey] opacity-50">
          <p className={"font-semibold opacity-80"}>{t("hive")}</p>
          <p>{hive?.code}</p>
        </div>
      </div>
    </div>
  )
}

HiveEventCard.displayName = "HiveEventCard"

export { HiveEventCard, HiveEventCardWrapper }
