import { BIRDS_SPECIES_ENUM } from "@/features/birds-groups/types/birds-groups.ts"
import { useGetSpeciesQuery } from "@/features/farm/api/species-api.ts"

const BIRDS_SPECIES = Object.values(BIRDS_SPECIES_ENUM)

export const useGetBirdSpecies = () => {
  const { data: species_breeds } = useGetSpeciesQuery()
  const speciesBreedsArray =
    (species_breeds && Object.values(species_breeds)) || []

  return speciesBreedsArray?.filter((species) =>
    BIRDS_SPECIES.includes(species.name),
  )
}
