import React, { forwardRef, useImperativeHandle, useRef } from "react"
import { useScrollRestoration } from "@/hooks/use-scroll-restoration.ts"
import { cn } from "@/utils/styles.ts"

interface DrawerContentWrapperProps {
  children: React.ReactNode
  className?: string
}

const PageContentWrapperLarge = forwardRef<
  HTMLDivElement,
  DrawerContentWrapperProps
>(({ children, className }, ref) => {
  const internalRef = useRef<HTMLDivElement>(null)

  useScrollRestoration(internalRef)

  useImperativeHandle(ref, () => internalRef.current!)

  return (
    <div
      ref={internalRef}
      className={cn(
        "box-border h-[calc(var(--scrollable-content-large))] gap-4 overflow-y-auto overflow-x-hidden p-4 pb-12",
        className,
      )}
    >
      {children}
    </div>
  )
})

export default PageContentWrapperLarge
