import { useTranslation } from "react-i18next"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import NoResultsFound from "@/components/not-found/no-results-found.tsx"
import PageContentWrapperSmall from "@/components/page-content-wrappers/page-content-wrapper-small.tsx"
import MobileTopBar from "@/components/top-bars/mobile-top-bar/mobile-top-bar.tsx"
import { useGetApiariesQuery } from "@/features/bees/api/apiaries-api.ts"
import { ApiaryWidget } from "@/features/bees/components/apiary-widget/apiary-widget.tsx"
import { NEW_APIARY_SCREEN_PATH } from "@/utils/constants/routes.ts"

const AllApiariesTab = () => {
  const { t } = useTranslation()
  const { data: apiaries, isLoading } = useGetApiariesQuery()

  return (
    <>
      <MobileTopBar
        title={t("myApiaries")}
        navigateTo={NEW_APIARY_SCREEN_PATH}
      />
      <PageContentWrapperSmall className={"flex flex-col gap-3"}>
        {!isLoading &&
          apiaries?.map((apiary, index) => (
            <ApiaryWidget key={index} apiary={apiary} />
          ))}
        {isLoading && <LoadingAnimation />}
        {!isLoading && apiaries?.length === 0 && (
          <NoResultsFound
            image={"apiary"}
            message={t("noApiaryFound")}
            className={"h-full"}
          />
        )}
      </PageContentWrapperSmall>
    </>
  )
}

export default AllApiariesTab
