import { skipToken } from "@reduxjs/toolkit/query"
import isEqual from "lodash.isequal"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useParams, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import DeleteButton from "@/components/delete-button/delete-button.tsx"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import EditorTopBar from "@/components/top-bars/editor-top-bar/editor-top-bar.tsx"
import {
  useDeleteQueenMutation,
  useGetQueenByIdQuery,
  useUpdateQueenMutation,
} from "@/features/bees/api/queens-api.ts"
import { QueenEditor } from "@/features/bees/components/queen-editor/queen-editor.tsx"
import { useGetQueenDraft } from "@/features/bees/hooks/use-get-queen-draft.ts"
import { validateQueen } from "@/features/bees/types/queen-schema.tsx"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"
import { deleteQueenDraft } from "@/redux/slices/queen-draft-slice.ts"
import {
  ALL_HIVES_PATH,
  ALL_QUEENS_ROUTE,
  APIARIES_SCREEN_PATH,
} from "@/utils/constants/routes.ts"
import { DELETE_ENTRY_TIMEOUT } from "@/utils/constants/time-constants.ts"
import { getObjectDifference } from "@/utils/helpers/object-utils.ts"

export const EditQueenView = () => {
  const { t } = useTranslation()
  const { queenId } = useParams()
  const { openModal } = useModal()
  const dispatch = useDispatch()
  const [updateQueenMutation] = useUpdateQueenMutation()
  const [deleteQueenMutation] = useDeleteQueenMutation()

  const navigate = useNavigate()
  const { data: queenData } = useGetQueenByIdQuery(queenId || skipToken)
  const { draftQueen, data, isLoading } = useGetQueenDraft(queenId)
  const title = draftQueen?.name || t("loading")
  const hasChanges = !!draftQueen && !!data && !isEqual(data, draftQueen)

  const deleteDraft = useCallback(() => {
    setTimeout(() => {
      if (!queenId) return
      dispatch(deleteQueenDraft(queenId))
    }, DELETE_ENTRY_TIMEOUT)
  }, [queenId, dispatch])

  const handleBackClick = () => {
    if (hasChanges) {
      openModal(MODALS.CONFIRM_NAVIGATION_MODAL, {
        title: t("unsavedChanges"),
        content: t("youWillLoseChanges"),
        onBackText: t("leave"),
        onActionText: t("stay"),
        onBackClick: () => {
          navigate(-1)
          deleteDraft()
        },
      })
    } else {
      navigate(-1)
      deleteDraft()
    }
  }

  const deleteQueen = () => {
    if (queenData && queenId) {
      toast
        .promise(
          deleteQueenMutation({
            apiaryId: queenData.apiary,
            hiveId: queenData.hive,
            queenId,
          }).unwrap(),
          {
            pending: t("deletingQueen"),
            success: t("queenDeleted"),
            error: t("queenDeleteError"),
          },
        )
        .then(() => {
          navigate(
            queenData.apiary
              ? `${APIARIES_SCREEN_PATH}/${queenData.apiary}${ALL_HIVES_PATH}/${queenData.hive}/`
              : ALL_QUEENS_ROUTE,
            { replace: true },
          )
          deleteDraft()
        })
    }
  }

  const handleQueenDeletion = () => {
    openModal(MODALS.BASE_MODAL, {
      title: t("deleteQueenConfirmation"),
      content: t("queenWillBeDeleted"),
      onActionClick: deleteQueen,
    })
  }

  const handleQueenUpdate = async () => {
    if (!(await validateQueen(draftQueen))) {
      return
    }

    if (queenData && queenId && data) {
      const diffData = getObjectDifference(data, draftQueen)
      const promise = updateQueenMutation({
        hiveId: queenData.hive,
        queenId,
        queen: diffData,
      }).unwrap()
      toast
        .promise(promise, {
          pending: t("queenUpdating"),
          success: t("queenUpdatedSuccessfully"),
          error: t("errorUpdatingQueen"),
        })
        .then(() => {
          navigate(-1)
          deleteDraft()
        })
    }
  }

  return (
    <>
      <EditorTopBar
        text={title}
        isModified={hasChanges}
        onBackClick={handleBackClick}
        onSave={handleQueenUpdate}
      />
      {isLoading || !queenData || !queenId || !draftQueen ? (
        <LoadingAnimation />
      ) : (
        <PageContentWrapperLarge>
          <QueenEditor queenId={queenId} draftQueen={draftQueen} />
          <DeleteButton text={t("deleteQueen")} onClick={handleQueenDeletion} />
        </PageContentWrapperLarge>
      )}
    </>
  )
}
