export enum TEST_IDS {
  QUEENS_WIDGET = "queens-widget",
  FARM_SUMMARY_WIDGET = "farm-summary-widget",
  MOBILE_HEADER = "mobile-header",
  COWS_STATUSES_WIDGET = "cows-statuses-widget",
  APIARIES_WIDGET = "apiaries-widget",
  WEATHER_WIDGET = "weather-widget",
  NOTIFICATIONS_WIDGET = "notifications-widget",
  NOTES_WIDGET = "notes-widget",
  TASKS_WIDGET = "tasks-widget",
  HIVES_WIDGET = "hives-widget",
  ASSISTANT_WIDGET = "assistant-widget",
  FINANCE_WIDGET = "finance-widget",
  GESTATION_PERIOD_CALENDAR_WIDGET = "gestation-period-calendar-widget",
}
